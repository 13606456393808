import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html'
})
export class FeedbackComponent implements OnInit {

  @Input() page: string;

  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  /* istanbul ignore next */
  openDialog(): void {
    const dialogRef = this.dialog.open(FeedbackDialogComponent, {
      width: '30%',
      data: {page: this.page}
    });
  }
}
