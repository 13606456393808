<mat-card>
  <mat-card-header>
    <mat-card-title>{{title}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="container">
      <div class="row">
        <div class="col text-end">
          {{lastPeriodValue}}
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h3 class="ai-h3-dark">
            <div [ngClass]="{'text-green': upliftRate > 0, 'text-red': upliftRate < 0}" class="text-center">
              {{upliftRate}}%
            </div>
          </h3>
        </div>
      </div>
      <div class="row">
        <div [ngClass]="{'text-green': upliftRate > 0, 'text-red': upliftRate < 0}" class="col text-end">
          {{thisPeriodValue}}
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
