<div class="horizontal-bar-container">
    <div class="label">
        <p>{{ label }}</p>
        <img [src]="icons[label]" alt="">
    </div>
    <div>
        <div class="audience" [style]="'width: ' + audience*2 + '%;'">
            <p>{{ audience }}%</p>
        </div>
        <div class="datapool" [style]="'width: ' + datapool*2 + '%;'">
            <p>{{ datapool }}%</p>
        </div>
    </div>
</div>
