<section class="document">
  <div class="content">
    <embed *ngIf="examplePdfSource" type="application/pdf" width="580px" height="654px" [src]="examplePdfSource">
  </div>
</section>
<section class="form-container">
  <div class="content">
    <h2 class="dark">Sign Joint Controller</h2>
    <p>
      Bitte nehme Dir einen Moment Zeit, um den Joint-Controller Vertrag,
      zwecks Nutzung unserer Dienste inm Zusammenhang mit den EUn Datenschutzrichtlininge, zu lesen.
    </p>
    <form [formGroup]="jointControllerFormGroup">
      <div class="form-input-container">
        <mat-form-field>
          <input placeholder="Name" matInput formControlName="name" required/>
        </mat-form-field>
      </div>

      <div class="form-input-container">
        <mat-form-field>
          <input formControlName="company" matInput placeholder="Firma" required/>
        </mat-form-field>
      </div>

      <div class="form-input-container">
        <mat-form-field>
          <input formControlName="telephone" matInput placeholder="Telefon" required/>
        </mat-form-field>
      </div>

      <div class="form-input-container">
        <mat-form-field>
          <input formControlName="street" matInput placeholder="Straße, Hausnummer" required/>
        </mat-form-field>
      </div>

      <div class="form-input-container city-postcode">
        <mat-form-field class="postcode">
          <input formControlName="postcode" matInput placeholder="PLZ" required/>
        </mat-form-field>
        <mat-form-field class="city">
          <input formControlName="city" matInput placeholder="Ort" required/>
        </mat-form-field>
      </div>

      <div class="form-input-container">
        <mat-form-field>
          <input formControlName="country" matInput placeholder="Land" required/>
        </mat-form-field>
      </div>
    </form>
    <div class="submit-button">
      <button (click)="signJointController()"
              [disabled]="!jointControllerFormGroup.valid"
              class="submit"
              color="primary"
              mat-flat-button
              type="submit">
        Zustimmen
      </button>
    </div>
  </div>
</section>
