<article [ngClass]="classNames" [class.inactive]="inactive">
  <header>
    <div class="bulletpoint">
      {{number}}
    </div>
    <div class="headline">
      {{headline}}
    </div>
    <ng-content select=".bulletpoint-extra"></ng-content>
  </header>
  <section>
    <ng-content>

    </ng-content>
  </section>
</article>
