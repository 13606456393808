<app-form-container headline="Passwort ändern" icon="person_add">
  <form id="changePasswordForm" (submit)="onSubmit()" [formGroup]="changePasswordFormGroup" novalidate>
    <article class="centered">
      <section>
        <div class="centered-content">
          <div class="form-input-container">
            <mat-form-field>
              <input name="oldPassword"
                     placeholder="Aktuelles Passwort"
                     matInput
                     type="Passwort"
                     formControlName="oldPassword">
              <mat-error *ngIf="changePasswordFormGroup.get('oldPassword').touched &&
                              changePasswordFormGroup.get('oldPassword').hasError('required')">
                <span>Du musst dein aktuell gültiges Passwort angeben</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div formGroupName="passwords">
            <div class="form-input-container">
              <app-password-strength
                [control]="changePasswordFormGroup.get('passwords').get('password1')"></app-password-strength>
              <mat-form-field>
                <input name="password"
                       placeholder="Passwort"
                       matInput
                       type="password"
                       formControlName="password1">
              </mat-form-field>
            </div>
            <div class="form-input-container">
              <mat-form-field>
                <input name="passwordConfirm"
                       placeholder="Passwort wiederholen"
                       matInput
                       type="password"
                       formControlName="password2"
                       [errorStateMatcher]="parentErrorStateMatcher">
                <mat-error *ngIf="changePasswordFormGroup.get('passwords').get('password1')
                                  && changePasswordFormGroup.get('passwords').hasError('matchOther')">
                  <span>Deine Passwörter stimmen nicht überein.</span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <button mat-flat-button
                  [disabled]="changePasswordFormGroup.invalid"
                  type="submit"
                  name="confirm"
                  color="primary">
            Passwort ändern
          </button>
        </div>
      </section>
    </article>
  </form>
</app-form-container>
