import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventSourceClient } from './event-source-client.service';

@NgModule({
  declarations: [],
  providers: [
    EventSourceClient
  ],
  imports: [
    CommonModule
  ]
})
export class EventSourceClientModule {
}
