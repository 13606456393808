import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.checkAuthenticationStatus()
      .then((isValid) => {
        if (!isValid) {
          this.router.navigate(['/login']);
        }
        return isValid;
      })
      .catch(() => {
        this.router.navigate(['/login']);
        return false;
      });
  }
}

@Injectable()
export class ConfirmGuard implements CanActivate {

  constructor(private router: Router,
              private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const allowed = this.authService.isConfirmAllowed();
    if (!allowed) {
      this.router.navigate(['/']);
    }
    return allowed;
  }
}

@Injectable()
export class UserManagerGuard implements CanActivate {
  constructor(private router: Router,
              private authService: AuthService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const allowed = this.authService.isInUserAdminGroup();
    if (!allowed) {
      this.router.navigate(['/']);
    }
    return allowed;
  }
}

@Injectable()
export class JointControllerGuard implements CanActivate {
  constructor(private router: Router,
              private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private userService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.isJointControllerSigned().pipe(
      tap((isSigned) => {
        if (!isSigned) {
          this.router.navigate(['/joint-controller/sign']);
        }
      })
    );
  }
}

