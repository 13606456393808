export interface BannerMessage {
  icon?: string;
  major?: string;
  minor?: string;
}

export interface BannerAction {
  id?: string;
  displayName: string;
  actionFn?: () => void;
  close?: boolean;
}

export interface BannerNotification {
  message: BannerMessage;
  level?: BannerLevel;
  actions?: BannerAction[];
  flexDirection?: 'row' | 'column';
  closeable?: boolean;
}

export enum BannerLevel {
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}
