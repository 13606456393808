/**
 * Type of the report (:p javascript).
 * If all reports will be fetched from backend, the items are marked with a type.
 */
export enum ReportType {
  SegmentEvaluation = 'SegmentEvaluation',
  TrackingEvent = 'TrackingEvent',
  UrlEvent = 'UrlEvent'
}

export enum ReportDisplayType {
  Tracking = 'tracking',
  Evaluation = 'evaluation'
}

/** */
export enum ReportStatus {
  Future = 'Future',
  Collecting = 'Collecting',
  Done = 'Done',
  Tracking = 'Tracking' // HINT: Tracking is not a real status. It is a (class) name hack for reusing "app-report-icon"
}

/**
 * Basic Report API
 */
export interface BasicReport {
  /** every report that is persistent in the backend must have an id*/
  id?: string;
  created?: Date | string;
  modified?: Date | string;
  label: string;
  status?: ReportStatus;
  type?: ReportType;
  uniqueProfileCount?: number;
  dataAvailable?: boolean;
  lastDataChanged?: Date | string;
  /** User can not change the report. */
  immutable?: boolean;
}

/**
 * TrackingEventReport
 */
export interface TrackingEventReport extends BasicReport {
  eventId?: string;
  pixelHtml?: string;
}

/**
 * A topic with set of segmentIds.
 */
export interface Topic {
  id: string;
  segments: string[];
}

/**
 * Evaluation report DTO.
 */
export interface SegmentEvaluation extends BasicReport {
  evaluationGroups?: EvaluationSegmentGroup[];
}

export interface Pixel {
  htmlElement: string;
  eventId: string;
}

/** common error container */
export interface DetailedError {
  error: any;
  /** business error code from server. */
  errorCode?: number;
}

// ============================================================================
// Charts
// ============================================================================

/** Starts a new report generation. */
export interface StartChartsRequest {
  reportId: string;
  start: Date;
  end: Date;
}

/**
 * Data of a report / customers data stream.
 *
 * A `report chart` contains counts of profiles of segments in a specific data range.
 */
export interface ReportCharts {

  /** The id of the corresponding report. */
  reportId?: string;

  /** Start date inclusive. */
  start?: Date;

  /** End date inclusive. */
  end?: Date;

  /** Contains all charts. A chart is a group of segments including counts.*/
  charts: { [key: string]: ChartData };

  /** Number of all unique profiles in the data range. */
  uniqueProfiles?: number;

  personasOverview?: PersonaOverview;

  /** Indicates that the report can not be changed by user. */
  immutable?: boolean;
}

/**
 * Every list may contain one or more values.
 * If one value: distinct/dominates. If multiple values: mixed.
 */
export interface PersonaOverview {
  ages: SegmentInfo[];
  genders: SegmentInfo[];
  incomes: SegmentInfo[];
}

/**
 * Represents a data point.
 */
export interface SegmentInfo {
  /** The e-id of the segment (e.g `e1` for male). */
  segment: string;

  /** A simple label or display name. */
  label: string;

  /** The percentage of users in the current group (e.g. age) in the data stream of the report. */
  userProfilesPercent: number;

  /** the relation between userProfilesCount/dataPoolProfilesCount. */
  uplift: number;

  /** The percentage of users in the current group (e.g. age) in the data pool for the corresponding segment. */
  dataPoolProfilesPercent: number;

  /** the number of profiles */
  userProfilesSize: number;

  /** the number of profiles */
  dataPoolProfilesSize: number;

  /** description of a segment */
  description?: string;

}

export interface ReportChartsSnapshot {
  report: BasicReport;
  charts: ReportCharts;
}

export interface ChartData {
  type: string;
  dataProvider: string;
  displayName?: string; // TODO 09.07.21: shipped by backend
  data: SegmentInfo[];
}

// ============================================================================
// Segment Evaluation
// ============================================================================

export interface SegmentsTopic {
  name: string;
  nameEn?: string; // // TODO 10.01.22: workaround DPF-2390
  segments: Segment[];
}

export interface Segment {
  label?: string;
  text?: string;
  icon?: string;
  eId?: string;
  selected?: boolean;
  matIcon?: string;
  description?: string;
  reach?: number;
}

/**
 * Defines a group of segments with logical operators.
 */
export interface EvaluationSegmentGroup {
  /**
   * Contains all segments within a group.
   */
  segments: Segment[];

  /**
   * The operator for segments within the group.
   */
  segmentOperator: EvaluationSegmentOperator;

  /**
   * The left-hand side operator of this group.
   *
   * A single group also must have an operator!
   */
  groupOperator: EvaluationSegmentGroupOperator;
}

export declare type EvaluationSegmentOperator = 'AND' | 'OR';
export declare type EvaluationSegmentGroupOperator = 'AND' | 'AND_NOT';
