<section>
  <div>
    <h2>Shipping Address</h2>
    <form [formGroup]="invoiceAddressFormGroup">
      <mat-form-field>
        <input formControlName="firstName" matInput placeholder="Vorname" required>
      </mat-form-field>
      <mat-form-field>
        <input formControlName="lastName" matInput placeholder="Nachname" required>
      </mat-form-field>
      <mat-form-field>
        <input aria-label="Firma" formControlName="companyName" matInput placeholder="Firma" required>
      </mat-form-field>
      <mat-form-field>
        <input formControlName="streetAndNumber" matInput placeholder="Straße" required>
      </mat-form-field>
      <mat-form-field>
        <input formControlName="postcode" matInput placeholder="PLZ" required>
      </mat-form-field>
      <mat-form-field>
        <input formControlName="city" matInput placeholder="Ort" required>
      </mat-form-field>
      <app-country-select id="country" [control]="country"></app-country-select>
      <mat-form-field>
        <input formControlName="vatId" matInput placeholder="Steuer-IdNr." required>
      </mat-form-field>
    </form>
    <section class="buttons">
      <button (click)="cancel()" class="cancel" mat-flat-button>Abbrechen</button>
      <button [disabled]="!invoiceAddressFormGroup.valid" class="save" color="primary" mat-flat-button
              type="submit" (click)="save()">
        Speichern
      </button>
    </section>
  </div>
</section>
