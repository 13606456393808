/**
 * development deployment.
 */
export const environment = {
  production: true, // dev runs as production ready system
  stage: 'dev',
  hmr: false,
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_4M0tSe8pg',
  clientId: '1u059v7nrn1co6827v2nba4b3t',
  apiBasePath: 'https://dev.api.insights.emetriq.de',
  trackingBasePath: 'https://tracking.insights.emetriq.de/tracking/insights',
  integrationBasePath: 'https://0leky6tdjc.execute-api.eu-west-1.amazonaws.com/dev',
  cognitoPolicy: {
    MinimumLength: 8,
    RequireNumbers: false,
    RequireSymbols: false,
    RequireUppercase: false,
    RequireLowercase: false
  },
  enableServiceWorker: false,
  animation: true,
  version: '',
  report: {
    warnNoDataAfterHours: 3
  },
  asyncCharts: {pollingInterval: 5000, maxPollingTime: 1800000},
  useAuthServiceMock: false,
  useLocalApi: false
};
