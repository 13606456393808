import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../auth/auth.service';
import { NavigationComponent } from '../../../layout/navigation/navigation.component';
import { LiveEventsService } from '../../../live-events/live-events.service';
import { NotificationService } from '../../../notification/notification.service';
import { TrackingService } from '../../../tracking/tracking.service';
import { PlanService, UserPlan } from '../../plan/plan.service';
import {
  BasicReport,
  ChartData,
  EvaluationSegmentGroup,
  ReportCharts,
  ReportChartsSnapshot,
  ReportType,
  SegmentEvaluation
} from '../../reports.interface';
import { ReportsService } from '../../reports.service';
import { UrlEventReport } from '../../url-event/url-event.interface';
import { ChartTopicComponent } from '../chart-topic/chart-topic.component';
import { ChartsService } from '../charts.service';
import { ScreenshotService } from '../../../screenshot/screenshot.service';

@Component({
  selector: 'app-charts-view',
  templateUrl: './charts-view.component.html',
  styleUrls: ['./charts-view.component.scss']
})
export class ChartsViewComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(NavigationComponent) navigation: NavigationComponent;

  @ViewChildren(ChartTopicComponent, {
    read: ElementRef,
    emitDistinctChangesOnly: true
  }) chartComponents: QueryList<ElementRef>;

  // used as a constant in template
  readonly typeEvaluation = ReportType.SegmentEvaluation;
  readonly typePixel = ReportType.TrackingEvent;
  readonly typeUrl = ReportType.UrlEvent;

  report: UrlEventReport | BasicReport | SegmentEvaluation | undefined;

  reportsCharts: ReportCharts | undefined;

  // flag for indicating - if this view is a public shared view
  isSnapshotView = false;

  isLoading = false;

  subscriptions = new Subscription();

  plan: UserPlan;

  liveEventsCount: number;

  readonly liveEventsReportHint = 'Your report will usually be available within 24 hours.';

  constructor(private route: ActivatedRoute,
              private service: ReportsService,
              private chartsService: ChartsService,
              private notify: NotificationService,
              private trackingService: TrackingService,
              private planService: PlanService,
              private activatedRoute: ActivatedRoute,
              public auth: AuthService,
              private liveEventsService: LiveEventsService,
              private screenshotService: ScreenshotService) {
  }

  public get sourceUrlList(): string | void {
    if (this.report && 'urls' in this.report) {
      return this.report.urls.map((urlIntegration) => urlIntegration.url).join(' ');
    }
  }

  get evaluationGroups(): EvaluationSegmentGroup[] | undefined {
    return (this.report as SegmentEvaluation)?.evaluationGroups;
  }

  public get liveEventsDescription(): string {
    const created = new Date(this.report?.created);
    if (this.report && created.getDay() === new Date().getDay()) {
      return this.liveEventsReportHint;
    }
    return '';
  }

  static chartsHaveData(reportsCharts: ReportCharts): boolean {
    if (reportsCharts === null || reportsCharts === undefined) {
      return false;
    }
    if (reportsCharts.charts === null || reportsCharts.charts === undefined) {
      return false;
    }
    return Object.keys(reportsCharts.charts).length > 0;
  }

  ngOnInit(): void {
    this.fetchPlan();

    this.subscriptions.add(
      this.chartsService.onReportCharts().subscribe((reportCharts) => {
        this.reportsCharts = reportCharts;
      }));

    this.subscriptions.add(
      this.chartsService.isLoadingCharts().subscribe((loading) => this.isLoading = loading)
    );

    if (this.activatedRoute.snapshot.paramMap.has('id')) {
      const id = this.route.snapshot.params['id'];
      this.service.getReport(id).subscribe({
        next: (report) => {
          this.handleReportLoaded(report);
        },
        error: (_) => {
          this.notify.sendError('Ops. Es ist ein Fehler aufgetreten ¯\\_(ツ)_/¯');
        }
      });

    }

    if (this.activatedRoute.snapshot.paramMap.has('snapshotId')) {
      const snapshotId = this.activatedRoute.snapshot.paramMap.get('snapshotId');
      this.isSnapshotView = true;
      this.loadSnapshot(snapshotId);
    }

  }

  handleReportLoaded(report: BasicReport) {
    this.report = report;
    // initially request chart data, this will trigger the timeframe to set the dates
    if (report.type === ReportType.SegmentEvaluation) {
      this.chartsService.getCharts(report.id);
    } else {
      this.chartsService.getChartsWithPolling(report.id).then();
    }
    this.fetchLiveEvents(report);
  }

  ngAfterViewInit() {
    this.collectChartsForNavigation();
  }

  collectChartsForNavigation(): void {
    const subscription = this.chartComponents
      .changes
      .subscribe((elements: QueryList<ElementRef>) => {
          let categories = elements.map((el) => el.nativeElement.getAttribute('category'));
          categories = [...new Set(categories)];
          categories = categories.map(category => {
            const children = elements.filter(e => e.nativeElement.getAttribute('category') === category);

            let viewRef;
            let scrollRef;

            const headLines = children.map(el => {
              viewRef = el.nativeElement.querySelector('h3');
              scrollRef = el.nativeElement;

              return {
                viewRef,
                scrollRef,
                label: scrollRef.getAttribute('label'),
                active: false
              };
            });

            viewRef = children[0].nativeElement.querySelector('h3');
            scrollRef = children[0].nativeElement;

            return {
              viewRef,
              scrollRef,
              active: false,
              label: scrollRef.getAttribute('category'),
              children: headLines
            };
          });
          this.navigation.applyElements(categories);
        }
      );
    this.subscriptions.add(subscription);
  }

  reportName(): string {
    return this.report?.label || 'Report...';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  download() {
    if (this.report && this.reportsCharts) {
      const optionalStart = this.reportsCharts.start ? new Date(this.reportsCharts.start) : undefined;
      const optionalEnd = this.reportsCharts.end ? new Date(this.reportsCharts.end) : undefined;
      this.service.downloadReport(this.report.id, optionalStart, optionalEnd).subscribe();
    }
  }

  chartsHaveData() {
    return ChartsViewComponent.chartsHaveData(this.reportsCharts);
  }

  loadSnapshot(snapshotId) {
    const subscription = this.chartsService.requestSnapshot(snapshotId).subscribe(
      (snapshot: ReportChartsSnapshot) => this.report = snapshot.report,
      (error) => {
        console.log('Error processing snapshot', error);
      });
    this.subscriptions.add(subscription);
  }

  /* istanbul ignore next */
  screenshot() {
    /* istanbul ignore next */
    this.trackingService.event('takeScreenShot');
    const filename = `${this.report.label.replace(' ', '_')}`;
    this.screenshotService.screenshot('#charts', filename);
  }

  findChartDataBy(key: string): ChartData | undefined {
    return this.reportsCharts.charts[key];
  }

  fetchLiveEvents(report: BasicReport) {
    if (report.type !== ReportType.SegmentEvaluation) {
      this.subscriptions.add(
        this.liveEventsService.eventCountForReport(this.report.id).subscribe(value => this.liveEventsCount = value)
      );
    }
  }

  private fetchPlan() {
    this.planService.getPlanForUser().subscribe((plan) => {
      this.plan = plan;
    });
  }
}
