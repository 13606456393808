import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import * as FileSaver from 'file-saver';
import { LoadingIndicatorService } from '../loading-indicator/loading-indicator.service';

@Injectable({
  providedIn: 'root'
})
export class ScreenshotService {

  constructor(private loadingIndicator: LoadingIndicatorService) {
  }

  /* istanbul ignore next */
  screenshot(selector: string, fileName: string): void {
    const element: HTMLElement = document.querySelector(selector);
    if (!element) {
      return;
    }

    const loading = this.loadingIndicator.displayLoading('Erstelle Screenshot...');

    html2canvas(element, {})

      .then((canvas) => {
        canvas.toBlob((blob) => FileSaver.saveAs(blob, fileName));
      })

      .catch((error) => {
        console.log('Failed screenshot', error);
      })

      .finally(() => {
        loading.close();
      });
  }

}
