import { Component, Input, OnInit } from '@angular/core';
import { SegmentInfo } from '../../reports.interface';
import { chartsText } from '../charts.texts';

@Component({
    selector: 'app-distribution-chart',
    templateUrl: './distribution-chart.component.html',
    styleUrls: ['./distribution-chart.component.scss']
})
export class DistributionChartComponent implements OnInit {
    readonly frequentBuyerLabels = [
        'Frühstücksprodukte',
        'Säfte',
        'Milchprodukte',
        'Käseprodukte',
        'Zahnpflege',
        'Fleisch & Wurst',
        'Grundnahrungsmittel',
        'Trinkwasser',
        'Waschmittel',
        'Soft-Drinks',
        'Fischprodukte',
        'Chips & Sticks',
        'Süßigkeiten',
        'Suppen',
        'Küchenwaren',
        'Badezimmerbedarf',
        'Wein & Sekt',
        'Marmelade',
        'Spirituosen',
        'Reinigungsmittel',
        'Tierfutter',
        'Bierprodukte',
        'Körperpflegeprodukt',
        'Haarpflege',
        'heiße Getränke',
        'Bonbons'
    ];

    readonly text = chartsText;

    frequentBuyer = false;

    @Input() horizontal = true;

    // internal data set for usage in html template
    data: SegmentInfo[] = [];


    @Input() set chartData(data: SegmentInfo[]) {
        this.data = data;
        //console.log("data in distribution/ Verteilung: ", this.data);
        this.sortByUserProfiles();
    }

    sortByUserProfiles(): void {
        const copy = [...this.data];
        this.data = copy.sort((a, b) => b.userProfilesPercent - a.userProfilesPercent);
    }

    /**
     * Calculate percent value 0 - 100.
     *
     * @param p 0.0 - 1.0
     */
    getPercentage(p: number): number {
        return Math.round(Math.min(Math.max(p * 100, 0), 100));
    }

    ngOnInit(): void {
        this.frequentBuyer = this.frequentBuyerLabels.filter(e => e === this.data[0].label).length > 0;
    }

}
