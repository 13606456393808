<mat-expansion-panel #banner [class.banner-expanded]="banner.expanded" [class.banner-hidden]="!banner.expanded"
                     class="mat-elevation-z5" expanded="false">

  <button (click)="banner.close()" *ngIf="closable" class="close-button" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>

  <div [class.banner-content-closable]="closable" [style.flex-direction]="flexDirection" class="banner-content">

    <div class="message-box">
      <mat-icon
        [class.ai-text-error]="level === 'error'"
        [class.ai-text-warning]="level === 'warn'"
        class="message-icon">
        {{message?.icon}}
      </mat-icon>
      <span [innerHTML]="message?.major" class="message-major"></span>
    </div>

    <div class="message-box">
      <span [innerHTML]="message?.minor" class="message-minor"></span>
    </div>

    <div [class.button-box-col]="flexDirection === 'column'" class="buttons">
      <button *ngFor="let action of actions" color="primary" mat-flat-button
              (click)="actionClicked(action)">{{action.displayName}}</button>
    </div>

    <ng-content></ng-content>
  </div>

</mat-expansion-panel>
