import { Component, OnInit } from '@angular/core';
import { TrackingService } from '../../tracking/tracking.service';
import { BasicReport, ReportDisplayType, ReportType } from '../reports.interface';
import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { PlanService, UserPlan } from '../plan/plan.service';
import { ReportsService } from '../reports.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderByModifiedPipe } from '../pipe/order-by-modified.pipe';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.scss']
})
export class ReportsPageComponent implements OnInit {

  createReportButtonRipple = false;

  showReportCategory: ReportDisplayType;

  plan: Observable<UserPlan> | undefined;

  reports: Observable<BasicReport[]> | undefined;

  reportFilterValue: string;

  loadingData = false;

  jumpedToEvaluationOnce = false;

  constructor(private planService: PlanService,
              private reportService: ReportsService,
              private route: ActivatedRoute,
              private router: Router,
              private trackingService: TrackingService) {
  }

  ngOnInit(): void {
    this.plan = this.planService.getPlanForUser();

    this.route.queryParamMap.subscribe((it) => {
      if (it.get('type') === ReportDisplayType.Evaluation) {
        this.showReportCategory = ReportDisplayType.Evaluation;
      } else if (it.get('type') === ReportDisplayType.Tracking) {
        this.showReportCategory = ReportDisplayType.Tracking;
      } else {
        this.router.navigateByUrl('reports?type=' + ReportDisplayType.Tracking);
      }
    });

    this.loadingData = true;

    this.reports = this.reportService.getReports().pipe(
      map((basicReports: BasicReport[]) => {
          const filtered = basicReports.filter((r) => {
            if (this.showReportCategory === ReportDisplayType.Evaluation) {
              return r.type === ReportType.SegmentEvaluation;
            } else {
              return r.type === ReportType.TrackingEvent || r.type === ReportType.UrlEvent;
            }
          });
          return OrderByModifiedPipe.sort(filtered);
        }
      ),
      this.applyCreateButtonRipple(),
      finalize(() => this.loadingData = false)
    );
  }

  pageName(): string {
    switch (this.showReportCategory) {
      case ReportDisplayType.Tracking:
        return 'Audience Reporte';
      case ReportDisplayType.Evaluation:
        return 'Audience Evaluation';
      default:
        return 'Reporte';
    }
  }

  isEvaluation(): boolean {
    return this.showReportCategory === ReportDisplayType.Evaluation;
  }

  isTracking() {
    return this.showReportCategory === ReportDisplayType.Tracking;
  }

  createReport(): void {
    this.trackingService.event('openCreateReport');
    if (this.showReportCategory === ReportDisplayType.Tracking) {
      this.router.navigateByUrl('reports/create?type=' + this.showReportCategory);
    } else /* istanbul ignore else */ if (this.showReportCategory === ReportDisplayType.Evaluation) {
      this.router.navigateByUrl('reports/evaluation');
    }
  }

  private applyCreateButtonRipple(): MonoTypeOperatorFunction<BasicReport[]> {
    return tap((reports) => {
      this.createReportButtonRipple = (reports.length === 1 && reports[0].immutable === true) || reports.length === 0;

      // TODO 23.08.21: remove next week
      /* istanbul ignore next */
      if (environment.stage === 'prod' && this.createReportButtonRipple && !this.jumpedToEvaluationOnce) {
        const key = 'jumpedToEvaluationOnce';
        const exists = localStorage.getItem(key);
        if (exists == null) {
          this.router.navigateByUrl('reports?type=' + ReportDisplayType.Evaluation);
          localStorage.setItem(key, new Date().toISOString());
        }
      }

    });
  }
}

