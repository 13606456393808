<div class="filter-container d-flex flex-column">

  <app-note *ngIf="edit" closeableId="filterEditor" [closeable]="true" class="mb-0 mr-3">
    <span
      class="hint">
      Auch die erste Gruppe hat einen logischen (Und, Und Nicht) Operator. Damit hat man die Möglichkeit, nur ausschließend zu evaluieren!
      Segment in eine Gruppe ziehen (drag + drop)!
      Segment in letzte Gruppe legen: Doppelklick!
      Segment aus einer Gruppe entfernen: Doppelklick! Segment zwischen Gruppen ziehen!
      Alterssegmente können sich überschneiden und können auf Wunsch z.B. in einer separaten "Und Nicht"-Gruppe explizit ausgeschlossen werden!
    </span>
  </app-note>

  <div class="d-flex flex-row flex-wrap align-items-center">

    <div class="segment-group-container d-flex flex-row align-items-center mt-2 mb-2"
         *ngFor="let group of evaluationGroups; index as groupIndex"
         [ngClass]="{
         'combined-add': group.groupOperator === 'AND',
         'combined-not': group.groupOperator === 'AND_NOT'
       }">

      <button class="operator operator-group mr-2 ml-2" data-id="switch-group-operator" [disabled]="!edit"
              (click)="switchGroupOperator(groupIndex)">{{group.groupOperator === 'AND' ? 'UND' : 'UND NICHT'}}</button>

      <div class="group-container d-flex flex-row align-items-center">
        <div [id]="'segment-drop-list-' + groupIndex" class="segment-list-container d-flex flex-row flex-wrap pl-2"
             cdkDropList
             cdkDropListOrientation="horizontal"
             [cdkDropListEnterPredicate]="enterPredicate"
             [cdkDropListData]="group.segments"
             (cdkDropListDropped)="drop($event)">
          <div class="segment-container d-flex flex-row align-items-center ml-0 mr-2 mt-2 mb-2"
               cdkDrag
               [cdkDragDisabled]="!edit"
               [ngClass]="{
              'combined-add': group.segmentOperator === 'AND',
              'combined-or': group.segmentOperator === 'OR'
             }"
               *ngFor="let segment of group.segments; index as segmentIndex">

            <button class="operator operator-segments mr-2 ml-0" data-id="switch-segment-operator" [disabled]="!edit"
                    (click)="switchSegmentOperator(groupIndex)">{{group.segmentOperator === 'AND' ? 'UND' : 'ODER'}}</button>

            <button mat-stroked-button (dblclick)="removeSegment(groupIndex, segment)" class="button-segment"
                    [disabled]="!edit"
                    [id]="'group-segment-'+segment.eId">
              <div>{{segment.text}}</div>
            </button>
          </div>
        </div>

        <div class="button-close" *ngIf="edit" (click)="removeGroup(groupIndex)" data-id="remove-group">&times;
        </div>

      </div>
    </div>
    <a *ngIf="edit"
       mat-flat-button
       color="primary"
       class="button-add-group ml-2 mt-2 mb-2"
       cdkDropList
       id="addGroupButton"
       (click)="addDefaultGroup()"
       (cdkDropListDropped)="drop($event)">
      <mat-icon>add</mat-icon>
      Neue Gruppe
    </a>
  </div>

</div>
