import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {

  /** input for closeable mode. */
  @Input() closeable = false;

  /** local storage id. set if #closeable is true. */
  @Input() closeableId: string;

  /** the default icon if the box is closed. */
  @Input() closedIcon = 'help';

  /** is-closed-flag. */
  @Input() closed = false;

  @Input() cssInfoButtonPosition = '';
  @Input() cssInfoButtonTop = '0';
  @Input() cssInfoButtonRight = '0';

  @Input() header: string;

  constructor() {
  }

  ngOnInit() {
    if (this.closeable) {
      this.failIfCloseableIdIsMissing();
      this.closed = localStorage.getItem(this.closeableId) !== null;
    }
  }

  close() {
    this.closed = true;
    localStorage.setItem(this.closeableId, new Date().toString());
  }

  open() {
    this.closed = false;
    const item = localStorage.getItem(this.closeableId);
    localStorage.removeItem(this.closeableId);
  }

  private failIfCloseableIdIsMissing() {
    if (!this.closeableId) {
      throw new Error('Closeable but no id given');
    }
  }
}
