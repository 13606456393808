<article class="centered">
  <section>
    <div class="centered-content">
      <p class="ai-text-error">{{errorMessage}}</p>
      <form (submit)="onLogin()">
        <div class="form-input-container" >
          <mat-form-field>
            <input name="username"
                   placeholder="E-Mail"
                   matInput
                   required
                   [(ngModel)]="username"
                   [ngModelOptions]="{standalone: true}"
                   autocomplete="off">
            <span matSuffix>@</span>
          </mat-form-field>
        </div>
        <div class="form-input-container">
          <mat-form-field>
            <input name="password"
                   placeholder="Passwort"
                   matInput
                   required
                   type="password"
                   [(ngModel)]="password"
                   [ngModelOptions]="{standalone: true}">
          </mat-form-field>
        </div>
        <a routerLink="/forgot">Passwort vergessen?</a>
        <button color="primary" id="login-button" mat-flat-button type="submit">
          Anmelden
        </button>
      </form>
    </div>
  </section>
</article>
