<div class="container-fluid">
  <div class="row">
    <div class="col-10 d-flex flex-column">

      <app-chart-segment *ngFor="let segmentInfo of chartData"
                         [primaryPercentage]="getPercentage(segmentInfo.dataPoolProfilesSize)"
                         [primaryPercentageLabel]="segmentInfo.dataPoolProfilesSize | number"
                         [label]="segmentInfo.label"
                         [description]="segmentInfo.description"
                         [highlight]="true"></app-chart-segment>

    </div>

    <div class="col-2">
      <!-- Start Legend -->
      <app-chart-legend-item [description]="text.legend.reach" color="brand" label="Reichweite">
      </app-chart-legend-item>
      <!-- End Legend -->
    </div>

  </div>
</div>


