<div class="charts-topic-container container-fluid">

  <div class="row">

    <div class="col">

      <div class="row d-flex flex-row align-items-center chart-header chart-header-sticky">
        <div class="col-10">
          <h3 class="ai-h3-dark charts-bar-label">{{label}}</h3>
        </div>
        <div class="col-2 align-items-center">
          <div [ngClass]="getProviderClass()" class="provider-image"></div>
        </div>
      </div>

      <div class="row">
        <mat-tab-group class="ai-tab-group" disableRipple="true">
          <mat-tab class="col-2" label="Verteilung">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <p>Vergleiche die Verteilung Deiner Benutzermerkmale innerhalb der Gruppe: {{label}}</p>
                </div>
              </div>
            </div>
            <ng-content select="[role=distribution-chart]"></ng-content>
          </mat-tab>
          <mat-tab class="col-2" label="Index">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <p>Der Index repräsentiert den emetriq Datenpool mit mehr als 100 Millionen aktiven Profilen und wird durch einen Wert von 100 repräsentiert.</p>
                </div>
              </div>
            </div>
            <ng-content select="[role=index-chart]"></ng-content>
          </mat-tab>
          <mat-tab class="col-2" label="Segmentgröße">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <p>Identifiziere die Reichweite von Segmenten für deine programmatische Kampagne mit emetriq
                    Segmenten.</p>
                </div>
              </div>
            </div>
            <ng-content select="[role=reach-chart]"></ng-content>
          </mat-tab>
        </mat-tab-group>

      </div>

    </div>

  </div>

  <div class="row">
    <div class="col">
      <hr/>
    </div>
  </div>

</div>
