import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BasicReport, SegmentEvaluation } from '../../reports.interface';
import { Observable } from 'rxjs';
import { PlanType } from '../../../user/user-administration.service';
import { UserPlan } from '../../plan/plan.service';
import { MatSort } from '@angular/material/sort';
import { TrackingService } from '../../../tracking/tracking.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { finalize } from 'rxjs/operators';
import { ReportsService } from '../../reports.service';

const topicsList = require('../../../api/segments.json');

@Component({
  selector: 'app-evaluation-reports-table',
  templateUrl: './reports-evaluation-table.component.html',
  styleUrls: ['./reports-evaluation-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush // only check once, because "getIcons" returns new objects
})
export class ReportsEvaluationTableComponent implements OnInit {
  @Input() reports: Observable<BasicReport[]>;

  @Input() userPlan: UserPlan;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  readonly TOPIC_SEGMENTS_ICONS: any[] = topicsList.map(topic => topic.segments).flat();

  readonly pageSizeAndDisplayPaginator = 50;

  dataSource: MatTableDataSource<BasicReport>;

  displayedColumns: string[] = ['id', 'label', 'created', 'characteristics', 'actions'];

  downloading = false;

  constructor(private trackingService: TrackingService, private router: Router, private reportService: ReportsService) {
  }

  _filterValue: string;

  @Input()
  get filterValue(): string {
    return this._filterValue;
  }

  set filterValue(v: string) {
    this._filterValue = v;
    /* istanbul ignore else */
    if (this.dataSource) {
      this.dataSource.filter = v?.trim().toLowerCase();
    }
  }

  get chartsUrl(): string {
    return '/reports/view/';
  }

  ngOnInit(): void {
    this.reports.subscribe((reports) => {
      this.dataSource = new MatTableDataSource<BasicReport>(reports);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.jumpToCreateReportIfEmpty(reports);
    });
  }

  getEvaluationIcons(report: SegmentEvaluation): EvaluationGroupIcon[] {
    const groups = report.evaluationGroups || [];

    // map segments of groups into "EvaluationGroupIcon"
    const icons = groups.map((group) => group.segments.map((s) => {
      const icon: EvaluationGroupIcon = {
        not: group.groupOperator === 'AND_NOT',
        eId: s.eId,
        text: s.text
      };
      return icon;
    })).flat();

    // enrich with icon or matIcon and delete text when an icon was found
    icons.map(icon => {

      // lookup for an entry in segments.json file
      const found = this.TOPIC_SEGMENTS_ICONS.find(ts => ts.eId === icon.eId);

      if (found) {
        icon.icon = found.icon;
        icon.matIcon = found.matIcon;
      }

      return icon;
    });

    return icons;

  }

  displayEditButton(): boolean {
    return (this.userPlan ?? false) && this.userPlan.planConfig.planType !== PlanType.Free;
  }

  trackClick(report: BasicReport) {
    this.trackingService.event('openEditSegmentEvaluationReport', {id: report.id});
  }

  trackClickView(report) {
    this.trackingService.event('openEvaluationReportView', {id: report.id});
  }

  jumpToCreateReportIfEmpty(reports: BasicReport[]): void {
    /* istanbul ignore next */
    if (environment.stage === 'prod' && reports.length === 0) {
      this.router.navigateByUrl('/reports/evaluation');
    }
  }

  download(report: BasicReport): void {
    this.trackingService.event('downloadReport');
    this.downloading = true;

    this.reportService.downloadReport(report.id)
      .pipe(
        finalize(() => this.downloading = false)
      ).subscribe();
  }

}

export interface EvaluationGroupIcon {
  eId: string;
  icon?: string;
  matIcon?: string;
  text?: string;
  not: boolean;
}
