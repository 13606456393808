import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '../../api/app.api';
import { AuthService } from '../../auth/auth.service';
import { NotificationService } from '../../notification/notification.service';
import { TrackingService } from '../../tracking/tracking.service';
import { BasicReport, SegmentEvaluation, SegmentsTopic } from '../reports.interface';

const segments = require('../../api/segments.json');

@Injectable({
  providedIn: 'root'
})
export class EvaluationService {

  constructor(private http: HttpClient,
              private notification: NotificationService,
              private auth: AuthService) {
  }

  /**
   * @return "segments.json" data
   * @deprecated will be removed see {@link getSegmentsFromApi}
   */
  getSegments(): SegmentsTopic[] {
    return (segments as SegmentsTopic[]).filter(it => {
      if (this.auth.isInSpecialEvaluationSegmentsGroup()) {
        return true;
      } else {
        return it.name !== 'Spezielle Evaluation Segmente';
      }
    });
  }

  getSegmentsFromApi(): Observable<SegmentsTopic[]> {
    const url = `${environment.apiBasePath}/segment-evaluation/segments`;

    return this.http
      .get<ApiResponse<SegmentsTopic[]>>(url)
      .pipe(
        map((response) => response.data),
        map((data: SegmentsTopic[]) => this.auth.isInSpecialEvaluationSegmentsGroup() ?
          data : data.filter((t) => t.name !== 'Spezielle Evaluation Segmente')));
  }

  convertReport<T extends BasicReport>(raw: T): T {
    if (typeof raw.modified === 'string') {
      raw.modified = new Date(raw.modified);
    }
    if (typeof raw.created === 'string') {
      raw.created = new Date(raw.created);
    }
    return raw;
  }

  /**
   * Creates a new segment evaluation with a put request.
   *
   * @param newOne new object without id
   */
  createSegmentEvaluation(newOne: SegmentEvaluation): Observable<BasicReport> {
    return this.http.put<ApiResponse<SegmentEvaluation>>(`${environment.apiBasePath}/segment-evaluation`, newOne)
      .pipe(
        map((success: ApiResponse<BasicReport>) => {
          this.notification.sendSuccess('Evaluation Report erstellt!');
          TrackingService.track('segmentEvaluationCreated', success.data);
          return this.convertReport(success.data);
        }),
        /* TODO: update to NG7 **/
        catchError((error: HttpErrorResponse) => {
          TrackingService.track('segmentEvaluationCreatedError', error.message);
          throw error;
        })
      );
  }

  /**
   * @param id of the report
   * @return object in a Promise
   */
  getSegmentEvaluation(id: string): Observable<SegmentEvaluation> {
    return this.http.get<ApiResponse<SegmentEvaluation>>(`${environment.apiBasePath}/segment-evaluation/${id}`)
      .pipe(
        map((success: ApiResponse<SegmentEvaluation>) => {
          TrackingService.track('getSegmentEvaluation');
          return this.convertReport(success.data);
        }),
        catchError((error: HttpErrorResponse) => {
          this.notification.sendError('Leider ist ein Fehler aufgetreten!');
          TrackingService.track('getSegmentEvaluationError', error.message);
          throw error;
        })
      );
  }

  /**
   * Updates an existing report.
   *
   * @param report report with id
   */
  updateSegmentEvaluation(report: SegmentEvaluation): Observable<BasicReport> {
    return this.http.post<ApiResponse<SegmentEvaluation>>(`${environment.apiBasePath}/segment-evaluation`, report)
      .pipe(
        map((success: ApiResponse<BasicReport>) => {
          this.notification.sendSuccess('Evaluation Report geändert!');
          TrackingService.track('segmentEvaluationUpdated', success.data);
          return this.convertReport(success.data);
        }),
        catchError((error: HttpErrorResponse) => {
          this.notification.sendError('Leider ist ein Fehler aufgetreten!');
          TrackingService.track('segmentEvaluationUpdateError', error.message);
          throw error;
        })
      );
  }

  handleInvalidSubmission(message: string) {
    this.notification.sendError(message);
  }
}
