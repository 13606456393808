import { animate, group, query, state, style, transition, trigger } from '@angular/animations';

export const slideInLeftAnimation =
  trigger('slideInLeftAnimation', [
    state(':enter', style({
      position: 'absolute'
    })),
    state(':leave', style({
      position: 'relative'
    })),
    transition(':enter', [
      style({
        opacity: 0,
        transform: 'translateX(-10%)'
      }),
      animate('.5s ease-in-out', style({
        opacity: 1,
        transform: 'translateX(0)'
      }))
    ]),
    transition(':leave', [
      animate('.5s ease-in-out', style({
        opacity: 0,
        transform: 'translateX(-100%)'
      }))
    ])
  ]);

export const slideInUpAnimation =
  trigger('slideInUpAnimation', [
    state(':enter', style({
      position: 'absolute'
    })),
    state(':leave', style({
      position: 'relative'
    })),
    transition(':enter', [
      style({
        transform: 'translateY(100%)'
      }),
      animate('.5s ease-in-out', style({
        transform: 'translateY(0)'
      }))
    ]),
    transition(':leave', [
      animate('.5s ease-in-out', style({
        transform: 'translateY(100%)'
      }))
    ])
  ]);

export const routerAnimation = trigger('routerAnimation', [
  transition('* <=> *', [
    query(':enter, :leave', style({position: 'absolute', width: '100%'}), {optional: true}),
    group([
      query(':enter', [
        style({opacity: 0}),
        animate('0.5s ease-in-out', style({opacity: 1}))
      ], {optional: true}),
      query(':leave', [
        style({opacity: 1}),
        animate('0.5s ease-in-out', style({opacity: 0}))
      ], {optional: true}),
      query(':leave router-outlet ~ *', [
        style({opacity: 1}),
        animate('0.5s ease-in-out', style({opacity: 0, transform: 'translateX(-10%)'}))
      ], {optional: true})
    ])
  ])
]);

export const toolbarIcon =
  trigger('toggle', [
    transition(':enter', [
      style({opacity: 0}),
      animate('200ms',
        style({
          opacity: 1
        }))])
  ]);
