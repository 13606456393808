<ul id="password-requirement-list">
  <li *ngIf="charStrength.RequireUppercase" id="uppercase">
    <mat-icon class="icon-check" *ngIf="!uppercase()">check</mat-icon>
    <mat-icon class="icon-clear" *ngIf="uppercase()">clear</mat-icon>
    Großbuchstaben
  </li>
  <li *ngIf="charStrength.RequireLowercase" id="lowercase">
    <mat-icon class="icon-check" *ngIf="!lowercase()">check</mat-icon>
    <mat-icon class="icon-clear" *ngIf="lowercase()">clear</mat-icon>
    Kleinbuchstaben
  </li>
  <li *ngIf="charStrength.RequireNumbers" id="numbers">
    <mat-icon class="icon-check" *ngIf="!numbers()">check</mat-icon>
    <mat-icon class="icon-clear" *ngIf="numbers()">clear</mat-icon>
    Zahlen
  </li>
  <li *ngIf="charStrength.RequireSymbols" id="symbols">
    <mat-icon class="icon-check" *ngIf="!symbols()">check</mat-icon>
    <mat-icon class="icon-clear" *ngIf="symbols()">clear</mat-icon>
    Sonderzeichen
  </li>
  <li id="required">
    <mat-icon class="icon-check" *ngIf="!length() && !required()">check</mat-icon>
    <mat-icon class="icon-clear" *ngIf="length() || required()">clear</mat-icon>
    mindestens {{charStrength.MinimumLength}} Zeichen
  </li>
</ul>
