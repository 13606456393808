import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BannerAction, BannerLevel, BannerMessage, BannerNotification } from './banner.interface';
import { BannerService } from './banner.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  @ViewChild(MatExpansionPanel, {static: true})
  expansionPanel: MatExpansionPanel;

  @Input() message: BannerMessage;

  @Input() actions: BannerAction[];

  @Input() level: BannerLevel;

  @Input() closable = true;

  @Input() flexDirection: 'row' | 'column' = 'row';

  constructor(private service: BannerService, private router: Router) {
    router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.expansionPanel.close();
    });
  }

  ngOnInit() {
    this.level = BannerLevel.INFO;
    this.service.listen().subscribe((bn: BannerNotification) => {
      this.closable = bn.closeable !== undefined ? bn.closeable : true;
      this.flexDirection = bn.flexDirection || 'row';
      this.expansionPanel.open();
      this.message = bn.message;
      this.actions = bn.actions;
      this.level = bn.level ? bn.level : BannerLevel.INFO;
    });
  }

  actionClicked(action: BannerAction) {
    /* istanbul ignore else */
    if (action && typeof action.actionFn === 'function') {
      action.actionFn();
    }
    /* istanbul ignore else */
    if (action && action.close === true) {
      this.expansionPanel.close();
    }
  }

  /* istanbul ignore next */
  toggle() {
    this.expansionPanel.toggle();
  }
}
