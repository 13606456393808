import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InvoiceAddress, ShoppingCartService } from '../../shopping-cart.service';
import { UserService } from '../../../../user/user.service';
import { User } from '../../../../user/user.interface';

@Component({
  selector: 'app-edit-invoice-address',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditInvoiceAddressComponent implements OnInit {

  @Input() invoiceAddress?: InvoiceAddress;
  @Input() user?: User;
  @Output() showEditUserDataFormEvent = new EventEmitter<boolean>();
  @Output() newAddressEvent = new EventEmitter<InvoiceAddress>();

  country: FormControl;
  invoiceAddressFormGroup: FormGroup;

  constructor(private shoppingCartService: ShoppingCartService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    this.country = new FormControl(this.invoiceAddress?.country, Validators.required);
    this.invoiceAddressFormGroup = new FormGroup({
      firstName: new FormControl(this.invoiceAddress?.firstName, Validators.required),
      lastName: new FormControl(this.invoiceAddress?.lastName, Validators.required),
      companyName: new FormControl(this.invoiceAddress?.companyName, Validators.required),
      streetAndNumber: new FormControl(this.invoiceAddress?.streetAndNumber, Validators.required),
      postcode: new FormControl(this.invoiceAddress?.postcode, [Validators.required, Validators.minLength(5), Validators.pattern('[0-9]*')]),
      city: new FormControl(this.invoiceAddress?.city, Validators.required),
      country: this.country,
      vatId: new FormControl(this.invoiceAddress?.vatId, Validators.required)
    });
  }

  save() {
    const invoiceAddress = this.invoiceAddressFormGroup.value as InvoiceAddress;

    this.userService.updateUserData({
      address: {
        city: invoiceAddress.city,
        street: invoiceAddress.streetAndNumber,
        company: invoiceAddress.companyName,
        postcode: invoiceAddress.postcode,
        country: invoiceAddress.country,
        vatid: invoiceAddress.vatId
      },
      info: {
        firstName: invoiceAddress.firstName,
        lastName: invoiceAddress.lastName,
        telephone: this.user.info.telephone
      }
    })
      .then(() => {
        this.shoppingCartService.updateInvoiceAddress(invoiceAddress);
        this.showEditUserDataFormEvent.emit(false);
        this.newAddressEvent.emit(invoiceAddress);
      });
  }

  cancel() {
    this.showEditUserDataFormEvent.emit(false);
  }
}
