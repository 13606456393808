<section>
  <div>
    <app-form-bulletpoint [inactive]="isLinkActive('sign')" color="primary" number="1"
                          headline="Joint Controller"></app-form-bulletpoint>
  </div>
  <div>
    <app-form-bulletpoint [inactive]="isLinkActive('finish')" color="primary" number="2"
                          headline="Abschließen"></app-form-bulletpoint>
  </div>
</section>
<router-outlet></router-outlet>
