import { Component, OnInit } from '@angular/core';
import { JointControllerService } from '../joint-controller.service';
import { UserService } from '../../user/user.service';
import { Router } from '@angular/router';
import { TrackingService } from '../../tracking/tracking.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss']
})
export class JointControllerFinishComponent implements OnInit {

  constructor(private jointControllerService: JointControllerService,
              private userService: UserService,
              private authService: AuthService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.userService.getUser().subscribe((user) => {
      if (user.signedJointController === null || user.signedJointController === undefined) {
        this.router.navigate(['/joint-controller/sign']);
      }
    });

    TrackingService.track('signed-joint-controller', {newUser: this.authService.isConfirmAllowed()});
  }

  downloadPdf() {
    this.jointControllerService.downloadJointController();
  }
}
