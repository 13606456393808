import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRippleModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HighlightModule } from 'ngx-highlightjs';
import { AuthModule } from '../auth/auth.module';
import { CountrySelectModule } from '../country-select/country-select.module';
import { FilterModule } from '../filter/filter.module';
import { LayoutFormModule } from '../layout/form/form.module';
import { SharedModule } from '../modules/shared.module';
import { ChartsModule } from './charts/charts.module';
import { ReportCreateModule } from './create-tracking-report-entry/report-create.module';
import { ReportsEvaluationTableComponent } from './evaluation/table/reports-evaluation-table.component';
import { ReportsEvaluationComponent } from './evaluation/evaluation.component';
import { EvaluationSearchPipe } from './pipe/evaluation-search.pipe';
import { OrderByModifiedPipe } from './pipe/order-by-modified.pipe';
import { ReportsPixelCreateComponent } from './pixel/create/create.component';
import { PixelIntegrationComponent } from './pixel/create/integration/pixel-integration.component';
import { ReportsPixelEditComponent } from './pixel/edit/edit.component';
import { PlanBannerComponent } from './plan/plan-banner/plan-banner.component';
import { QualityIndicatorModule } from './quality-indicator/quality-indicator.module';
import { ReportsPageComponent } from './reports-page/reports-page.component';
import { ReportsComponent } from './reports.component';
import { ReportsRoutingModule } from './reports.routes';
import { ConfirmOrderComponent } from './shopping-cart/confirm-order/confirm-order.component';
import { EditInvoiceAddressComponent } from './shopping-cart/confirm-order/invoice-address/edit.component';
import { CartItemListComponent } from './shopping-cart/item-list/item-list.component';
import { ItemCounterComponent } from './shopping-cart/item-list/item/item-counter/item-counter.component';
import { CartItemComponent } from './shopping-cart/item-list/item/item.component';
import { CartSuccessComponent } from './shopping-cart/success/success.component';
import { TrackingReportsTableComponent } from './tracking-reports-table/tracking-reports-table.component';
import { UrlEventModule } from './url-event/url-event.module';
import { ReportsEvaluationFilterModule } from './evaluation/filter/reports-evaluation-filter.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgApexchartsModule } from 'ng-apexcharts';

@NgModule({
  imports: [
    SharedModule,
    ChartsModule,
    ReportsRoutingModule,
    AuthModule,
    LayoutFormModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatIconModule,
    MatGridListModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    ReportCreateModule,
    UrlEventModule,
    QualityIndicatorModule,
    ClipboardModule,
    FilterModule,
    CountrySelectModule,
    HighlightModule,
    DragDropModule,
    ReportsEvaluationFilterModule,
    NgApexchartsModule
  ],
  declarations: [
    ReportsComponent,
    ReportsPixelCreateComponent,
    ReportsPixelEditComponent,
    OrderByModifiedPipe,
    EvaluationSearchPipe,
    ReportsEvaluationComponent,
    PlanBannerComponent,
    CartItemListComponent,
    ConfirmOrderComponent,
    CartItemComponent,
    ItemCounterComponent,
    CartSuccessComponent,
    PixelIntegrationComponent,
    EditInvoiceAddressComponent,
    ReportsEvaluationTableComponent,
    TrackingReportsTableComponent,
    ReportsPageComponent
  ]
})
export class ReportsModule {
}
