import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { CountrySelectModule } from '../country-select/country-select.module';
import { EditableTableCellModule } from '../layout/editable-table-cell/editable-table-cell.module';
import { LayoutFormModule } from '../layout/form/form.module';
import { SharedModule } from '../modules/shared.module';
import { AccountComponent } from './account/account.component';
import { UserAccountMenuComponent } from './account/menu/menu.component';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { EditComponent } from './management/edit/edit.component';
import { UserManagementComponent } from './management/management.component';
import { UserComponent } from './user.component';
import { UserRoutingModule } from './user.routes';

@NgModule({
  imports: [
    SharedModule,
    LayoutFormModule,
    UserRoutingModule,
    MatMenuModule,
    MatTableModule,
    MatAutocompleteModule,
    CountrySelectModule,
    EditableTableCellModule
  ],
  declarations: [
    AccountComponent,
    UserManagementComponent,
    UserComponent,
    InviteUserComponent,
    UserAccountMenuComponent,
    EditComponent
  ],
  exports: [
    UserAccountMenuComponent
  ]
})
export class UserModule {
}
