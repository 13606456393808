import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { slideInUpAnimation } from '../../../app.animation';
import { NotificationService } from '../../../notification/notification.service';
import { TrackingService } from '../../../tracking/tracking.service';
import { Pixel, ReportDisplayType } from '../../reports.interface';
import { ReportsService } from '../../reports.service';

@Component({
  selector: 'app-report-form-create',
  templateUrl: './create.component.html',
  styleUrls: [
    '../../../form/form.component.scss',
    './create.component.scss'
  ],
  animations: [slideInUpAnimation]
})
export class ReportsPixelCreateComponent implements OnInit {
  readonly maxLengthName = 100;

  reportPixel: Pixel;

  submitReportForm: FormGroup;

  buttonDisabled = false;

  constructor(private service: ReportsService,
              private router: Router,
              private formBuilder: FormBuilder,
              private trackingService: TrackingService,
              private notification: NotificationService) {
  }

  @HostBinding('@slideInUpAnimation') get slideInUpAnimation() {
    return true;
  }

  @HostListener('window:keyup', ['$event'])
  /* istanbul ignore next  */
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this.close();
    }
  }

  ngOnInit() {
    this.submitReportForm = this.formBuilder.group({
      label: ['', [Validators.required]],
      eventId: [null, [Validators.required]]
    });
    this.getPixel();
  }

  getPixel() {
    this.service.getPixel().subscribe((pixel: Pixel) => {
      this.reportPixel = pixel;
      if (pixel) {
        this.submitReportForm.patchValue({eventId: pixel.eventId});
      }
    });
  }

  close() {
    this.trackingService.event('closeReportsForm');
    this.router.navigateByUrl('reports?type=' + ReportDisplayType.Tracking);
  }

  onSubmit() {
    this.markFormControlsAsDirty();

    if (this.isFormValid()) {
      this.buttonDisabled = true;
      this.service.createReport(this.submitReportForm.value)
        .subscribe(() => {
          this.trackingService.event('createReport');
          this.close();
        });
    } else {
      this.dispatchFormInvalidErrorMessage();
    }
  }

  dispatchFormInvalidErrorMessage() {
    const fields = [];

    /* istanbul ignore else */
    if (this.submitReportForm.controls['label'].enabled && this.submitReportForm.controls['label'].invalid) {
      fields.push('"Report Name"');
    }

    /* istanbul ignore else */
    if (fields.length > 0) {
      this.notification.sendError(`${fields.join(' and ')} eintragen.`);
    }
  }


  isFormValid() {
    return this.submitReportForm.valid;
  }

  private markFormControlsAsDirty() {
    Object.keys(this.submitReportForm.controls).forEach(field => this.submitReportForm.get(field).markAsDirty());
  }
}
