import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class FormContainerComponent {
  @Input() icon: string;
  @Input() svgIcon: string;
  @Input() headline: string;
}
