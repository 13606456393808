import { Component, OnInit } from '@angular/core';
import { Kpis, KpiService } from '../kpi.service';

@Component({
  selector: 'app-kpi-view',
  templateUrl: './kpi-view.component.html',
  styleUrls: ['./kpi-view.component.scss', '../../reports/charts/charts.scss']
})
export class KpiViewComponent implements OnInit {

  kpis: Kpis | undefined;

  constructor(private kpiService: KpiService) {
  }

  /* istanbul ignore next */
  ngOnInit(): void {
    this.kpiService.getKpis().subscribe((kpis => {
        this.kpis = kpis;
      })
    );
  }

  /* istanbul ignore next */
  getPercentage(countOfUniqueLogins: number): number {
    const countLogins = this.kpis?.uniqueUserLogins.map(login => login.countOfUniqueLogins);
    const max = Math.max(...countLogins);
    return Math.abs(100 * countOfUniqueLogins / max);
  }
}
