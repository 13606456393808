import { Component, Input, OnInit, HostBinding, HostListener } from '@angular/core';

@Component({
  selector: 'app-svg-chart',
  templateUrl: './svg-chart.component.html',
  styleUrls: ['./svg-chart.component.scss']
})
export class SvgChartComponent implements OnInit {
  @Input() label = '';

  @Input() width = 720;
  @Input() radius = 360;
  @Input() value = 0.5;
  @Input() arcWidth = 50;

  overlayDimensions = [650, 650];
  overlayRadii = [310, 310];

  sweepAngle = 2*Math.PI - 0.0001;

  percentage = '100%';

  sizeFactor = this.arcWidth.toString();

  ngOnInit() {
    this.overlayDimensions = [this.width - this.arcWidth, this.width - this.arcWidth];
    this.overlayRadii = [this.radius - this.arcWidth, this.radius - this.arcWidth];

    this.sweepAngle = this.sweepAngle * this.value;

    this.percentage = (this.value * 100).toFixed(2) + '%';

    this.sizeFactor = this.value > 0.5 ? (100 - 100*this.value).toString() + '%' : '55%';
  }

  @HostBinding('class.highlight') @Input() highlight = false;
  @HostBinding('class.with-description') @Input() description = '';

  @HostListener('click', ['$event']) onClick(event: any) {
    event.currentTarget?.classList.toggle('open');
  }
}
