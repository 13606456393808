import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ParentErrorStateMatcher, ValidationUtils } from '../../helpers/validation.util';
import { NotificationService } from '../../notification/notification.service';
import { TrackingService } from '../../tracking/tracking.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset.component.html'
})
export class ResetComponent implements OnInit, OnDestroy {
  public parentErrorStateMatcher = new ParentErrorStateMatcher();
  public resetPasswordForm: FormGroup;
  public passwordFormGroup: FormGroup;
  public subscription: Subscription;
  private username: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,
              private notification: NotificationService) {
  }

  ngOnInit() {

    this.authService.checkAuthenticationStatus()
      .then((isSessionValid: boolean) => {
        if (isSessionValid) {
          this.router.navigate(['/']);
        }
      })
      .catch((error: Error) => {
        TrackingService.track('loginCheckAuthStatusError', error);
      });

    this.subscription = this.route
      .queryParams
      .subscribe(params => {
        this.username = params['username'];
      });

    this.passwordFormGroup = new FormGroup({
      password1: new FormControl('', ValidationUtils.configPasswordValidation()),
      password2: new FormControl('', Validators.required)
    }, ValidationUtils.passwordConfirmEqual);

    this.resetPasswordForm = new FormGroup({
      confirmationCode: new FormControl('', Validators.required),
      passwords: this.passwordFormGroup
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSubmit() {
    const confirmationCode = this.resetPasswordForm.controls['confirmationCode'].value;
    const password = (this.resetPasswordForm.controls['passwords'] as FormGroup).controls['password1'].value;
    return this.authService.changeForgottenPassword(this.username, confirmationCode, password)
      .then(() => {
        this.notification.sendSuccess('Neues Passwort gespeichert.');
        this.router.navigateByUrl('/login');
      })
      .catch((error) => {
        this.notification.sendError('Neues Passwort konnte nicht gespeichert werden!');
        TrackingService.track('resetPasswordError', error.message);
      });
  }
}
