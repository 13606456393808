<mat-card>
  <mat-card-header *ngIf="headline">
    <mat-card-title>
      {{headline}}
    </mat-card-title>
    <ng-content select=".toolbar">

    </ng-content>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="headline" class="subheadline">
      <div class="icon-container">
        <mat-icon *ngIf="svgIcon" svgIcon="{{svgIcon}}" color="primary"></mat-icon>
        <mat-icon *ngIf="icon" color="primary">{{icon}}</mat-icon>
      </div>
      <div class="subheadline-content">
        <ng-content select=".subheadline-container"></ng-content>
      </div>
    </div>
    <ng-content>
    </ng-content>
  </mat-card-content>
</mat-card>
