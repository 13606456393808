import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgChartsModule } from 'ng2-charts';
import { BannerModule } from '../../banner/banner.module';
import { FeedbackModule } from '../../feedback/feedback.module';
import { LayoutFormModule } from '../../layout/form/form.module';
import { NavigationModule } from '../../layout/navigation/navigation.module';
import { SharedModule } from '../../modules/shared.module';
import { ReportsIconComponent } from '../icon/icon.component';
import { QualityIndicatorModule } from '../quality-indicator/quality-indicator.module';
import { ReportsRoutingModule } from '../reports.routes';
import { ChartSegmentBarModule } from './chart-segment-bar/chart-segment-bar.module';
import { DistributionChartComponent } from './distribution-chart/distribution-chart.component';
import { AudienceOverviewComponent } from './audience-overview/audience-overview.component';
import { ReportSourceModule } from './reports-source/report-source.module';
import { TimeframeComponent } from './timeframe/timeframe.component';
import { ChartsViewComponent } from './charts-view/charts-view.component';
import { ShareButtonComponent } from './charts-view/share-button/share-button.component';
import { EditButtonComponent } from './charts-view/edit-button/edit-button.component';
import { IndexChartComponent } from './index-chart/index-chart.component';
import { ChartTopicComponent } from './chart-topic/chart-topic.component';
import { ChartLegendItemComponent } from './chart-legend-item/chart-legend-item.component';
import { ReachChartComponent } from './reach-chart/reach-chart.component';
import { LiveEventsModule } from '../../live-events/live-events.module';
import { ReportsEvaluationFilterModule } from '../evaluation/filter/reports-evaluation-filter.module';
import { RadialChartComponent } from './radial-chart/radial-chart.component';
import { SvgChartModule } from './svg-chart/svg-chart.module';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { ApexChartsWrapperModule } from './apex-charts/apex-charts.module';
import { StackedBarModule } from './stacked-bar/stacked-bar.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import {MatExpansionModule} from '@angular/material/expansion';


const CHART_COMPONENTS = [
  ChartTopicComponent,
  DistributionChartComponent,
  ChartLegendItemComponent,
  AudienceOverviewComponent,
  IndexChartComponent,
  ReachChartComponent,
  ReportsIconComponent,
  TimeframeComponent,
  ChartsViewComponent,
  ShareButtonComponent,
  EditButtonComponent,
  RadialChartComponent,
  BarChartComponent
];

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    LayoutFormModule,
    ReportsRoutingModule,
    QualityIndicatorModule,
    ReportSourceModule,
    SharedModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FeedbackModule,
    BannerModule,
    ChartSegmentBarModule,
    LiveEventsModule,
    NavigationModule,
    ReportsEvaluationFilterModule,
    NgChartsModule,
    SvgChartModule,
    ApexChartsWrapperModule,
    StackedBarModule,
    NgApexchartsModule,
    MatExpansionModule
  ],
  declarations: CHART_COMPONENTS,
  exports: CHART_COMPONENTS
})
export class ChartsModule {
}
