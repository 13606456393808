import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ReportDisplayType } from '../../reports.interface';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class CartSuccessComponent implements OnInit {

  reportDisplayType: string;
  isUpgradePlanOrder: boolean;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.reportDisplayType = this.route.snapshot.queryParamMap.get('reportDisplayType');
    this.isUpgradePlanOrder = this.route.snapshot.queryParamMap.get('isUpgradePlanOrder') === 'true';
  }
}
