import { NgModule } from '@angular/core';
import { SharedModule } from '../modules/shared.module';
import { JointControllerFinishComponent } from './finish/finish.component';
import { MatButtonModule } from '@angular/material/button';
import { JointControllerSignComponent } from './sign/sign.component';

@NgModule({
  imports: [
    SharedModule,
    MatButtonModule
  ],
  declarations: [
    JointControllerFinishComponent,
    JointControllerSignComponent,
  ]
})
export class JointControllerModule {
}
