import { Injectable } from '@angular/core';
import { Segment, Topic } from '../reports/reports.interface';

const topicsList = require('../api/segments.json');

@Injectable({
  providedIn: 'root'
})
export class SegmentsService {

  readonly TOPIC_SEGMENTS_ICONS: any[] = this.flatRecursive(topicsList.map(topic => topic.segments));

  constructor() {
  }

  public getIconsForTopics(topics: Topic[]): Segment[] {
    return this.flatRecursive(
      this.flatRecursive(topics.map(topic => topic.segments))
        .map(eId => this.TOPIC_SEGMENTS_ICONS.filter(ts => ts.eId === eId)));
  }

  private flatRecursive(arrayWithSubArrays) {
    return arrayWithSubArrays.reduce((acc, val) => Array.isArray(val) ? acc.concat(this.flatRecursive(val)) : acc.concat(val), []);
  }
}

