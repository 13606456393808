import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {

  text = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: string) {
    this.text = data;
  }

  ngOnInit(): void {
  }

}
