import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TrackingService } from '../../tracking/tracking.service';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit {

  form = new FormGroup({
    text: new FormControl('')
  });

  constructor(private dialogRef: MatDialogRef<FeedbackDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: DialogData) {
  }

  ngOnInit() {
  }

  /* istanbul ignore next */
  close() {
    this.dialogRef.close();
  }

  /* istanbul ignore next */
  send() {
    if (this.form.value.text) {
      TrackingService.track('feedback', {
        feedback: this.form.value,
        page: this.data.page
      });
    }
    this.dialogRef.close();
  }
}

export interface DialogData {
  page: string;
}
