<div class="container-fluid">
  <div id="plan-banner-row" class="row d-flex align-items-center">
    <div class="col-5 pl-4">
      <a (click)="createReport.emit()"
         [ngClass]="{'ripple': createButtonRipple}"
         class="ai-button-mat-icon"
         id="create-report-button">
        <mat-icon class="ai-mat-icon-left-adjust" color="primary">add_circle</mat-icon>
        <strong>Neuen Report hinzufügen</strong>
      </a>
    </div>
    <div class="col-3">
      <section>
        <strong>Account Typ: <span id="accountType">{{userPlan?.planConfig?.planType}}</span></strong>
      </section>
    </div>
    <div class="col-2">
      <section *ngIf="config?.planType !== planTypeEnum.Enterprise" [ngSwitch]="reportDisplayType">
        <ng-container *ngSwitchCase="ReportDisplayType.Tracking">
          <strong>Tracking Reporte: <span
            id="availableTrackingReports">{{getReportAvailabilityTrackingText()}}</span></strong>
        </ng-container>
        <ng-container *ngSwitchCase="ReportDisplayType.Evaluation">
          <strong>Evaluation Reporte: <span
            id="availableEvaluationReports">{{getReportAvailabilityEvaluationText()}}</span></strong>
        </ng-container>
      </section>
    </div>

    <!-- #hack: pt-1 to align button-name with other text -->
    <div class="col-2 d-flex justify-content-end pt-1">
      <section [ngSwitch]="buttonType">
        <ng-container *ngSwitchCase="enumBannerButton.BuyReport">
          <div class="ai-button" (click)="buyAdditionalReport()">
            <strong>Report kaufen</strong>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="enumBannerButton.UpgradePlan">
          <div class="ai-button" (click)="buyBusinessPlanUpgrade()">
            <strong>Upgrade</strong>
          </div>
        </ng-container>
      </section>
    </div>
  </div>
</div>
