import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HistoryService } from '../../helpers/history.service';
import { ParentErrorStateMatcher, ValidationUtils } from '../../helpers/validation.util';
import { TrackingService } from '../../tracking/tracking.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['../auth.component.scss']
})
export class ConfirmComponent implements OnInit, OnDestroy {
  parentErrorStateMatcher = new ParentErrorStateMatcher();
  errorMessage: string;
  requiredAttributes: any;
  sub: Subscription;
  confirmForm: FormGroup;

  constructor(public history: HistoryService,
              private activeRoute: ActivatedRoute,
              private router: Router,
              public authService: AuthService) {

    this.createForm();
  }

  ngOnInit() {
    this.sub = this.activeRoute
      .queryParams
      .subscribe((params) => {
        this.requiredAttributes = params['requiredAttributes'];
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  createForm() {
    this.confirmForm = new FormGroup({
      username: new FormControl('', Validators.required),
      passwords: new FormGroup({
        password1: new FormControl('', ValidationUtils.configPasswordValidation()),
        password2: new FormControl('', Validators.required)
      }, ValidationUtils.passwordConfirmEqual)
    });
  }

  onSuccess() {
    TrackingService.track('confirmPassword');
    this.router.navigate(['/']);
  }

  onError(err) {
    TrackingService.track('confirmPasswordFail', err);
    this.errorMessage = err.message;
  }

  onSubmit() {
    if (this.confirmForm.valid) {
      this.authService
        .completeNewPasswordChallenge(
          this.confirmForm.get('username').value,
          (this.confirmForm.controls['passwords'] as FormGroup).controls['password1'].value,
          this.requiredAttributes
        )
        .then(() => this.onSuccess())
        .catch((err) => this.onError(err));
    }
  }

}
