import { Component, HostListener } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { TrackingService } from '../../tracking/tracking.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

  readonly headerHeightFallback = 112;

  categories: Category[] = [];

  expanded = true;

  constructor(public auth: AuthService) {
  }

  @HostListener('window:scroll', [])
  isScrolledIntoView() {
    const winHeight = (window.innerHeight / 2);
    const headerHeight = this.calculateHaderHeight();
    this.categories.forEach((category) => {
      let rect;
      let topShown;
      let bottomShown;
      let active;

      category.children.forEach(headline => {
        rect = headline.viewRef.getBoundingClientRect();
        topShown = rect.top >= headerHeight;
        bottomShown = rect.bottom <= winHeight;
        headline.active = topShown && bottomShown;
        active = active ? true : headline.active;
      });

      category.active = active;
    });
  }

  calculateHaderHeight(): number {
    const headerElement = document.querySelector('#header');
    return headerElement ? headerElement.getBoundingClientRect().height : this.headerHeightFallback;
  }

  applyElements(chartComponents: Category[]): void {
    this.categories.push(...chartComponents);
  }

  scrollTo(elm: HTMLElement): void {
    elm.scrollIntoView({behavior: 'smooth', block: 'start'});
    TrackingService.track('chartsNavigationClick', {expanded: this.expanded});
  }

  showElement(elm: HTMLElement): void {
    this.categories.forEach(category => {
      category.children.forEach(headline => {
        headline.scrollRef.classList.remove('open');
      });
    });

    elm.classList.add('open');
  }

  toggleView(): void {
    this.expanded = !this.expanded;
    TrackingService.track('chartsNavigationToggle');
  }

}

export interface Category {
  viewRef: HTMLElement;
  scrollRef: HTMLElement;
  label: string;
  children: Array<Headline>;
  active: boolean;
}

export interface Headline {
  viewRef: HTMLElement;
  scrollRef: HTMLElement;
  label: string;
  active: boolean;
}
