<div class="pl-3 pr-3">

  <table [dataSource]="dataSource" class="ai-reports-table ai-table-hover" mat-table matSort>

    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td *matCellDef="let element" [queryParams]="{type: 'tracking'}"
          [routerLink]="[chartsUrl, element.id]" class="ai-pointer" mat-cell
      > {{element.id}} </td>
    </ng-container>

    <!-- Name Column-->
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td *matCellDef="let element" [queryParams]="{type: 'tracking'}"
          [routerLink]="[chartsUrl, element.id]" class="ai-pointer" mat-cell>
        {{element.label}} </td>
    </ng-container>

    <!-- Type-->
    <ng-container matColumnDef="type">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Typ</th>
      <td *matCellDef="let element" [queryParams]="{type: 'tracking'}"
          [routerLink]="[chartsUrl, element.id]" class="ai-pointer" mat-cell>
        <div class="report-list-item-type" [ngSwitch]="element.type">
          <span *ngSwitchCase="TrackingEventType">Pixel</span>
          <span *ngSwitchCase="UrlEventType">URL</span>
          <span *ngSwitchDefault>Report</span>
        </div>
      </td>
    </ng-container>

    <!-- Created Column-->
    <ng-container matColumnDef="created">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Erstellt</th>
      <td *matCellDef="let element" [queryParams]="{type: 'tracking'}"
          [routerLink]="[chartsUrl, element.id]" class="ai-pointer" mat-cell
      > {{element.created | datePipe}} </td>
    </ng-container>

    <!-- Quality Column-->
    <ng-container matColumnDef="quality">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Qualität</th>
      <td *matCellDef="let element" [queryParams]="{type: 'tracking'}"
          [routerLink]="[chartsUrl, element.id]" class="ai-pointer" mat-cell
      >
        <app-quality-indicator-samll [profiles]="element.uniqueProfileCount"></app-quality-indicator-samll>
      </td>
    </ng-container>

    <!-- Profiles Column-->
    <ng-container matColumnDef="profiles">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Profile</th>
      <td *matCellDef="let element" [queryParams]="{type: 'tracking'}"
          [routerLink]="[chartsUrl, element.id]" class="ai-pointer" mat-cell
      >{{element.uniqueProfileCount | toLocaleNumber}}</td>
    </ng-container>

    <!-- URLs Column-->
    <ng-container matColumnDef="urls">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>URLs</th>
      <td *matCellDef="let element" [queryParams]="{type: 'tracking'}"
          [routerLink]="[chartsUrl, element.id]" class="ai-pointer" mat-cell
      >{{element.urls?.length}}</td>
    </ng-container>

    <!-- Status Column-->
    <ng-container matColumnDef="status">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Status</th>
      <td *matCellDef="let element" [matTooltip]="reportStatusText(element)" [ngClass]="reportStatus(element)" mat-cell>
        <div class="d-flex">
          <mat-icon *ngIf="reportStatus(element) ==='available'">check_circle</mat-icon>
          <mat-icon *ngIf="reportStatus(element) ==='paused'">warning</mat-icon>
          <mat-icon *ngIf="reportStatus(element) ==='zero'">error</mat-icon>
          <mat-icon *ngIf="element.classicDeprecated === true"
                    matTooltip="Dieser Report basiert auf Daten, die mit einem älteren Skript verarbeitet werden.
                    Das alte Skript kann nicht mehr für neue Reporte benutzt werden."
          >elderly
          </mat-icon>
        </div>
      </td>
    </ng-container>

    <!-- Actions Column-->
    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell>Funktionen</th>
      <td *matCellDef="let element" mat-cell>
        <div class="d-flex">
          <button
            mat-icon-button
            matTooltip="Report editieren" matTooltipPosition="left"
            class="ai-action-button"
            name="edit-report"
            routerLink="/reports/{{element.type === TrackingEventType ? 'pixel' : 'url-event'}}/edit/{{element.id}}"
            (click)="trackClick(element)">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="showDownloadButton(element)"
                  mat-icon-button
                  matTooltip="Download Report" matTooltipPosition="left"
                  class="ai-action-button"
                  name="download-button"
                  (click)="download(element)">
            <mat-icon>cloud_download</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr (click)="trackClickView(element)" *matRowDef="let element; columns: displayedColumns;" mat-row></tr>
  </table>

  <mat-paginator [class.ai-display-none]="dataSource?.data.length <= pageSizeAndDisplayPaginator"
                 [pageSize]="pageSizeAndDisplayPaginator"
                 [pageSizeOptions]="[pageSizeAndDisplayPaginator, 100, 200]"
                 showFirstLastButtons></mat-paginator>

</div>
