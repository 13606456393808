import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '../modules/shared.module';
import { ChartSegmentBarModule } from '../reports/charts/chart-segment-bar/chart-segment-bar.module';
import { KpiRateComponent } from './kpi-rate/kpi-rate.component';
import { KpiTableComponent } from './kpi-table/kpi-table.component';
import { KpiViewComponent } from './kpi-view/kpi-view.component';
import { KpiRoutingModule } from './kpi.routes';

@NgModule({
  declarations: [
    KpiViewComponent,
    KpiRateComponent,
    KpiTableComponent
  ],
  imports: [
    KpiRoutingModule,
    SharedModule,
    MatTableModule,
    ChartSegmentBarModule
  ]
})
export class KpiModule {
}
