import { Component, OnInit } from '@angular/core';
import { ReportDisplayType } from '../../reports.interface';

@Component({
  selector: 'app-url-event-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class UrlEventSuccessComponent implements OnInit {

  readonly reportDisplayType = ReportDisplayType.Tracking;

  constructor() {
  }

  ngOnInit(): void {
  }

}
