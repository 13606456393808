import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-vertical-bar',
  templateUrl: './chart-vertical-bar.component.html',
  styleUrls: ['./chart-vertical-bar.component.scss']
})
export class ChartVerticalBarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
