<table [dataSource]="retentionStats.registeredCohorts" class="ai-table-hover" mat-table>

  <ng-container matColumnDef="date">
    <th *matHeaderCellDef mat-header-cell> Cohort</th>
    <th *matCellDef="let cohort" mat-header-cell>
      {{ cohort.registeredInPeriod.start | date:'dd. MMM' }} -
      {{ cohort.registeredInPeriod.end | date:'dd. MMM' }}
    </th>
  </ng-container>

  <ng-container matColumnDef="users">
    <th *matHeaderCellDef mat-header-cell> New Users</th>
    <td *matCellDef="let cohort" mat-cell>
      {{cohort.registeredCount}}
    </td>
  </ng-container>

  <ng-container *ngFor="let week of weeks; index as i" [matColumnDef]="'week'+(i+1)">
    <th *matHeaderCellDef mat-header-cell class="text-center"> Week {{i + 1}}</th>
    <td *matCellDef="let cohort" [class.white-font]="cohort.reuseRates[i]?.rate >= 70"
        [style.backgroundColor]="getBackgroundColor(cohort.reuseRates[i]?.rate)"
        class="text-center"
        mat-cell>
      <span *ngIf="cohort.reuseRates[i]">{{cohort.reuseRates[i].rate}}%</span>
      <div *ngIf="cohort.reuseRates[i]" class="period">
        {{cohort.reuseRates[i].period.start|date:'dd.MM'}} - {{cohort.reuseRates[i].period.end|date:'dd.MM'}}
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
