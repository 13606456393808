<a #menuButton id="account-menu-button"
   aria-label="Account menu"
   #accountMenuTrigger="matMenuTrigger"
   [matMenuTriggerFor]="menu"
   (menuOpened)="menuOpened()"
   (menuClosed)="menuClosed()"
   [ngClass]="{'selected': activeRoute, 'open': menuOpen}">
  <div class="icon"></div>
  <div class="caption">Benutzer</div>
  <div class="border"></div>
</a>
<mat-menu #menu [overlapTrigger]="false">
  <a mat-menu-item routerLink="account" routerLinkActive="selected">
    Account
  </a>
  <a mat-menu-item *ngIf="isUserAdmin()" routerLink="user-management" routerLinkActive="selected">
    Benutzer
  </a>
  <a *ngIf="isUserAdmin()" mat-menu-item routerLink="kpi" routerLinkActive="selected">
    Kpi
  </a>
  <a mat-menu-item routerLink="integration" routerLinkActive="selected">
    Integration
  </a>
  <div disabled mat-menu-item><!--empty--></div>
  <a mat-menu-item routerLink="/logout">Abmelden</a>
  <div disabled mat-menu-item><code>v: {{version}}</code></div>
</mat-menu>
