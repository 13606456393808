import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { toError } from '../app.error';
import { UpdateUserDataRequest } from './user-administration.service';
import { NotificationService } from '../notification/notification.service';
import { TrackingService } from '../tracking/tracking.service';
import { User } from './user.interface';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient,
              private notification: NotificationService,
              private trackingService: TrackingService) {
  }

  updateUserData(updateUserRequest: UpdateUserDataRequest): Promise<any> {
    return this.httpClient.put(`${environment.apiBasePath}/user`, updateUserRequest).pipe(
      tap(
        () => {
          this.notification.sendSuccess('Benutzer angepasst!');
          this.trackingService.event(`Update User with email '${updateUserRequest.info.email}'`, updateUserRequest);
        },
        (httpErrorResponse: HttpErrorResponse) => {
          const detailedError = toError(httpErrorResponse);
          this.notification.sendError(`Der Benutzer konnte nicht geändert werden: ${detailedError.error}`);
        })
    ).toPromise();
  }

  /**
   * @param forceHttp do not use the cache and load a new user
   */
  getUser(forceHttp: boolean = false): Observable<User> {
    const emptyUser = {};

    return this.httpClient.get<User>(`${environment.apiBasePath}/user`)
      .pipe(
        tap((user) => user),
        catchError((_) => of(emptyUser))
      );
  }

  isJointControllerSigned(): Observable<boolean> {
    return this.getUser().pipe(map(user => !this.isNullOrEmpty(user.signedJointController)));
  }

  private isNullOrEmpty(property: any): boolean {
    return property === null || property === undefined;
  }
}
