<div class="content-wrapper">
  <header class="header">
    <h1>Bestellbestätigung</h1>
  </header>

  <section class="invoice-details" *ngIf="!isEditUserData">
    <div class="address-container">
      <div class="address" (click)="showEditUserData(true)">
        <div class="headline-shipping-address">
          <h2>Rechnungsadresse
            <mat-icon class="material-icons mat-icon-s">create</mat-icon>
          </h2>
        </div>
        <span>{{invoiceAddress?.firstName}} {{invoiceAddress?.lastName}} | {{user?.info?.email}}</span>
        <span>{{invoiceAddress?.companyName}} | {{invoiceAddress?.vatId}}</span>
        <span>{{invoiceAddress?.streetAndNumber}}</span>
        <span>{{invoiceAddress?.postcode}} {{invoiceAddress?.city}}</span>
        <span>{{invoiceAddress?.country}}</span>
      </div>
      <div *ngIf="!isInvoiceDataValid()">
        <app-note>Du musst vor dem Kauf alle Rechnungsinformationen ausfüllen.</app-note>
      </div>
    </div>
    <div class="payment">
      <h2>Zahlungsmethode</h2>
      <span>Rechnung</span>
    </div>
  </section>
  <section>
    <div class="edit-invoice-address">
      <app-edit-invoice-address *ngIf="isEditUserData"
                                [invoiceAddress]="invoiceAddress"
                                [user]="user"
                                (showEditUserDataFormEvent)="showEditUserData($event)"
                                (newAddressEvent)="setNewAddress($event)"
      ></app-edit-invoice-address>
    </div>
  </section>
  <section class="overview">
    <div class="wrapper">
      <h2>Deine Bestellung</h2>
      <div class="line">
        <span><strong>Produkt</strong></span>
        <span><strong>Gesamt</strong></span></div>
      <div class="line">
        <span *ngIf="!isUpgradePlan()">Audience Insights Report <strong>{{order?.quantity}}x</strong></span>
        <span *ngIf="isUpgradePlan()">Audience Insights Abonnement</span>
        <span>{{order?.displayTotals?.subtotal}} €</span>
      </div>
      <div class="line">
        <span>Zwischensumme</span>
        <span>{{order?.displayTotals?.subtotal}} €</span>
      </div>
      <div class="line">
        <span>MwSt. 19%</span>
        <span>{{order?.displayTotals?.tax}} €</span>
      </div>
      <div class="line">
        <span><strong>Gesamt</strong></span>
        <span><strong>{{order?.displayTotals?.total}} €</strong></span>
      </div>
    </div>
  </section>
  <section class="buttons">
    <a (click)="back()" color="primary" mat-stroked-button>Zurück</a>
    <button (click)="buyNow()" [disabled]="!isInvoiceDataValid()" color="primary" mat-flat-button>Jetzt kaufen</button>
  </section>
</div>
