/* istanbul ignore file */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserManagerGuard } from '../auth/guards';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { EditComponent } from './management/edit/edit.component';
import { UserComponent } from './user.component';

export const userRoutes: Routes = [
  {path: '', component: UserComponent, canActivate: [UserManagerGuard]},
  {path: 'invite', component: InviteUserComponent, canActivate: [UserManagerGuard]},
  {path: 'edit/:id', component: EditComponent}
];

@NgModule({
  imports: [RouterModule.forChild(userRoutes)],
  exports: [RouterModule]
})
export class UserRoutingModule {
}
