import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { toError } from '../app.error';
import { NotificationService } from '../notification/notification.service';
import { TrackingService } from '../tracking/tracking.service';
import { User } from './user.interface';

@Injectable({
  providedIn: 'root'
})
export class UserAdministrationService {

  constructor(private httpClient: HttpClient,
              private notification: NotificationService,
              private trackingService: TrackingService) {
  }

  getUsers(): Observable<User[]> {
    this.trackingService.event('Get user list');
    return this.httpClient.get<User[]>(`${environment.apiBasePath}/users`)
      .pipe(
        catchError((error) => {
          this.notification.sendError('Die Benutzer konnten nicht geladen werden!');
          throw error;
        })
      );
  }

  getUser(userId): Observable<User> {
    return this.httpClient.get(`${environment.apiBasePath}/users/${userId}`);
  }

  inviteUser(signUpRequest: SignUpRequest): Promise<any> {
    return this.httpClient.post(`${environment.apiBasePath}/users/invite`, signUpRequest).pipe(
      tap(
        () => {
          this.notification.sendSuccess('Benutzer erfolgreich eingeladen!');
          this.trackingService.event('Invite User', signUpRequest);
        },
        (httpErrorResponse: HttpErrorResponse) => {
          const error = toError(httpErrorResponse);
          this.notification.sendError(`Benutzer konnte nicht eingeladen werden: ${error.error || error}`);
        })
    ).toPromise();
  }

  updateUser(updateUserRequest: UpdateUserRequest, userId: string): Promise<any> {
    return this.httpClient.put(`${environment.apiBasePath}/users/${userId}`, updateUserRequest).pipe(
      tap(
        () => {
          this.notification.sendSuccess('Benutzer erfolgreich geändert!');
          this.trackingService.event(`Update User ${userId}`, updateUserRequest);
        },
        (httpErrorResponse: HttpErrorResponse) => {
          const detailedError = toError(httpErrorResponse);
          this.notification.sendError(`Der Benutzer konnte nicht geändert werden: ${detailedError.error}`);
        })
    ).toPromise();
  }

  updatePrivatePool(userId: string, privatePoolEnabled: boolean): Observable<any> {
    const body = {privatePool: privatePoolEnabled};
    return this.httpClient.put(`${environment.apiBasePath}/users/${userId}/private-pool`, body);
  }

  updateSids(userId: string, sids: string[]): Observable<any> {
    const body = {sids};
    return this.httpClient.put(`${environment.apiBasePath}/users/${userId}/sids`, body);
  }

}

export enum PlanType {
  Free = 'Free',
  Business = 'Business',
  Enterprise = 'Enterprise'
}

export interface SignUpRequest {
  type: PlanType;
  address: AddressDto;
  info: PersonalInfoDto;
  contractEndDay?: Date;
}

/**
 * BE: PUT /users/{userId}
 */
export interface UpdateUserRequest {
  type?: PlanType;
  address?: AddressDto;
  info?: PersonalInfoDto;
  contractEndDay?: Date;
}

export interface UpdateUserDataRequest {
  address: AddressDto;
  info: PersonalInfoDto;
}

export interface AddressDto {
  company: string;
  street: string;
  postcode: string;
  city: string;
  country: string;
  vatid: string;
}

export interface PersonalInfoDto {
  firstName?: string;
  lastName?: string;
  email?: string;
  telephone?: string;
}

