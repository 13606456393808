import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class Log {

  /* istanbul ignore next */
  /**
   * Only logs on not production.
   *
   * @param message
   * @param optionalParams
   */
  public log(message: any, ...optionalParams: any[]): void {
    /* istanbul ignore next */
    if (environment.stage !== 'prod') {
      console.log(message, ...optionalParams);
    }
  }

}
