import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/auth.service';
import { LayoutService } from '../../../layout/layout.service';

@Component({
  selector: 'app-user-account-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class UserAccountMenuComponent implements AfterViewInit {

  @ViewChild('accountMenuTrigger', {static: false}) accountMenuTrigger;

  @ViewChild('menu', {static: false}) menu;

  version = environment.version;

  menuOpen = false;

  activeRoute = false;

  constructor(private authService: AuthService, private layoutService: LayoutService) {
  }

  ngAfterViewInit(): void {
    this.layoutService.onRoutingData().subscribe((data) => {
      if (data.activateUserMenu === true) {
        this.activeRoute = true;
      } else {
        this.activeRoute = false;
      }
    });
  }

  isUserAdmin() {
    return this.authService.isInUserAdminGroup();
  }

  isAdmin(): boolean {
    return this.authService.isInAdminGroup();
  }

  menuOpened() {
    this.accountMenuTrigger.openMenu();
    this.menuOpen = true;
  }

  menuClosed() {
    this.accountMenuTrigger.closeMenu();
    this.menuOpen = false;
  }
}
