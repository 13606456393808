<svg  xmlns="http://www.w3.org/2000/svg" version="1.1"
  [attr.viewBox]="viewBox"
  [attr.width]="width" [attr.height]="height"
>
  <path
    [attr.d]="dAttribute"
    [attr.fill]="fill"
    [attr.fill-opacity]="fillOpacity"
    [attr.stroke-opacity]="strokeOpacity"
    [attr.stroke-width]="strokeWidth"
    [attr.stroke]="strokeColor"
    [attr.stroke-dasharray]="dasharray"
    [attr.stroke-linejoin]="strokeLineJoin"
    [attr.stroke-linecap]="strokeLineCap"
  />
  <text
    *ngIf="displayValue"
    [attr.x]="strokeWidth*2"
    [attr.y]="strokeWidth*2"
    fill="white"
  >{{ displayValue }}</text>
  <text
    [attr.style]="sweepAngle > 0.7853982 && 'display: none;'"
    *ngIf="displayValue"
    [attr.x]="strokeWidth*0.5"
    [attr.y]="strokeWidth*5"
    fill="white"
  >{{ displayValue }}</text>
</svg>