import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-legend-item',
  templateUrl: './chart-legend-item.component.html',
  styleUrls: ['./chart-legend-item.component.scss']
})
export class ChartLegendItemComponent implements OnInit {

  @Input() label = 'Awesome';

  @Input() description: string;

  @Input() color: 'brand' | 'dark' = 'brand';

  constructor() {
  }

  ngOnInit(): void {
  }

}
