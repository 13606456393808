import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-bar',
  templateUrl: './chart-bar.component.html',
  styleUrls: ['./chart-bar.component.scss']
})
export class ChartBarComponent {
  @Input() percentage: number;
  @Input() percentageLabel: string;
  @Input() color: string;
  @Input() align: string;
}
