import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BannerNotification } from './banner.interface';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  private notifications: Subject<BannerNotification> = new Subject<BannerNotification>();

  constructor() {
  }

  listen(): Observable<BannerNotification> {
    return this.notifications;
  }

  public notify(bn: BannerNotification): void {
    this.notifications.next(bn);
  }

}
