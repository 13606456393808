export enum IntegrationStatus {
  Ok = 'Ok',
  Checking = 'Checking',
  Failed = 'Failed'
}

export interface UrlIntegration {
  url: string;
  status: IntegrationStatus;
}

export interface UrlIntegrationResponse {
  url: string;
  integrated: IntegrationResult;
}

export interface IntegrationResult {
  success: boolean;
  message: string;
  integrations: string[];
}

