<app-form-container headline="URL basierter Report" svgIcon="emq:report_url">

  <div class="check-icon-container">
    <mat-icon class="mat-icon-xxl success" color="primary">check_circle</mat-icon>
    <div>Dein Report wurde erfolgreich angelegt.</div>
  </div>

  <div class="button-container">
    <a name="view-reports" mat-flat-button color="primary" routerLink="/reports"
       [queryParams]="{type: reportDisplayType}">
      Alle Reporte anzeigen
    </a>

    <a name="create-report" mat-flat-button color="primary" routerLink="/reports/create"
       [queryParams]="{type: reportDisplayType}">
      Neuen Report anlegen
    </a>
  </div>

</app-form-container>
