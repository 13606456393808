import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { environment } from '../../environments/environment';
import { NotificationService } from '../notification/notification.service';

@Injectable()
export class AuthService {

  private static _POOL_DATA: any = {
    UserPoolId: environment.userPoolId,
    ClientId: environment.clientId
  };
  userPool: CognitoUserPool;
  cognitoUser: CognitoUser;
  userAuthenticated: boolean;
  confirmAllowed: boolean;
  public authEvent = new EventEmitter<boolean>();
  private readonly QS_GROUP = 'QS';
  private readonly USER_ADMIN_GROUP = 'USER_ADMIN';
  private readonly ADMIN_GROUP = 'ADMIN';
  private readonly SPECIAL_EVALUATION_SEGMENTS_GROUP = 'SPECIAL_EVALUATION_SEGMENTS';

  constructor(private router: Router,
              public notification: NotificationService) {
    this.userPool = new CognitoUserPool(AuthService._POOL_DATA);
    this.cognitoUser = this.userPool.getCurrentUser();
    this.initSession();
  }

  initSession() {
    return this.checkAuthenticationStatus()
      .catch(() => {
      });
  }

  getCognitoUser(): CognitoUser {
    return this.cognitoUser;
  }

  isConfirmAllowed(): boolean {
    return this.confirmAllowed;
  }

  isUserAuthenticated(): boolean {
    return this.userAuthenticated;
  }

  isInQSGroup(): boolean {
    return this.getGroups().includes(this.QS_GROUP);
  }

  isInSpecialEvaluationSegmentsGroup(): boolean {
    return this.getGroups().includes(this.SPECIAL_EVALUATION_SEGMENTS_GROUP);
  }

  getGroups(): string[] {
    let result = [];

    if (this.getCognitoUser() && this.getCognitoUser().getSignInUserSession()) {
      result = this.getCognitoUser().getSignInUserSession().getIdToken().decodePayload()['cognito:groups'];
    }
    return result || [];
  }

  logout() {
    if (this.cognitoUser) {
      this.cognitoUser.signOut();
      this.userAuthenticated = false;
      this.authEvent.emit(false);
    }
  }

  checkAuthenticationStatus(): Promise<boolean> {
    const user = this.cognitoUser;
    if (user) {
      return new Promise((resolve, reject) => {
        user.getSession((error: Error, session: CognitoUserSession) => {
          if (error) {
            reject(error);
            this.userAuthenticated = false;
          } else {
            this.userAuthenticated = session.isValid();
            resolve(session.isValid());
          }
        });
      });
    } else {
      this.userAuthenticated = false;
      return Promise.resolve(false);
    }
  }

  completeNewPasswordChallenge(username: string, password: string, requiredAttributes: any = []): Promise<void | string> {
    return new Promise((resolve, reject) => {
      this.cognitoUser.completeNewPasswordChallenge(password, requiredAttributes, {
        onSuccess: () => resolve(),
        onFailure: (err) => reject(err)
      });
    });
  }

  authenticate(username: string, password: string): Promise<void | string> {
    // cache cognito user instance
    this.cognitoUser = this.createUserWith(username);

    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({Username: username, Password: password});
      this.cognitoUser.authenticateUser(authenticationDetails, {
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          this.confirmAllowed = true;
          this.router.navigate(['/confirm'], {
            skipLocationChange: true,
            queryParams: {requiredAttributes}
          });
        },
        onSuccess: () => {
          this.confirmAllowed = false;
          this.userAuthenticated = true;
          this.authEvent.emit(true);
          resolve();
        },
        onFailure: (err) => {
          this.confirmAllowed = false;
          this.userAuthenticated = false;
          this.authEvent.emit(false);
          reject(err);
        }
      });
    });
  }

  createUserWith(username: string): CognitoUser {
    return new CognitoUser({Username: username, Pool: this.userPool});
  }

  /**
   * Change the current password.
   *
   * @param oldPassword current active password
   * @param newPassword new password.
   */
  changePassword(oldPassword: string, newPassword: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getCognitoUser().changePassword(oldPassword, newPassword, (error: any) => {
        if (error === null) {
          this.notification.sendSuccess('Änderung des Passworts erfolgreich.');
          resolve();
        } else {
          if (error.code === 'NotAuthorizedException') {
            this.notification.sendError('Ungültiges Passwort');
          } else if (error.code === 'InvalidParameterException') {
            this.notification.sendError('Ungültiges Passwort');
          } else if (error.code === 'LimitExceededException') {
            this.notification.sendError('Die maximale Anzahl an Versuchen wurde erreicht. Bitte versuche es zu einem späteren Zeitpunkt erneut.');
          } else {
            this.notification.sendError('Es ist ein unbekannter Fehler aufgetreten. Das Passwort konnte nicht geändert werden.');
          }
          reject(new Error(error.message));
        }
      });
    });

  }

  isInUserAdminGroup() {
    return this.getGroups().includes(this.USER_ADMIN_GROUP);
  }

  isInAdminGroup(): boolean {
    return this.getGroups().includes(this.ADMIN_GROUP);
  }

  requestForgottenPasswordChange(username: string): Promise<any> {
    const cognitoUser: CognitoUser = this.createUserWith(username);
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  }

  changeForgottenPassword(username: string, code: string, newPassword: string): Promise<void> {
    const cognitoUser: CognitoUser = this.createUserWith(username);
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  }

  refreshSession() {
    this.cognitoUser.getSession((getSessionError: Error, cognitoUserSession: CognitoUserSession) => {
      const refreshToken = cognitoUserSession.getRefreshToken();
      this.cognitoUser.refreshSession(refreshToken, (refreshError: Error) => {
        if (refreshError) {
          console.log('refresh session failed', refreshError);
        }
      });
    });
  }
}
