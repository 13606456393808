import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss']
})
export class CountrySelectComponent implements OnInit {
  @Input() control = new FormControl();

  countries: string[] = ['Deutschland', 'Germany', 'France', 'Frankreich'];
  filteredCountries: Observable<string[]>;

  ngOnInit() {
    this.filteredCountries = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    );
  }

  filter(value: string): string[] {
    const filterValue = this.normalizeValue(value);
    return this.countries.filter(country => this.normalizeValue(country).includes(filterValue));
  }

  private normalizeValue(value: string): string {
    return value?.toLowerCase().replace(/\s/g, '');
  }

}
