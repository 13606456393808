import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable()
export class ScrollService {

  @Output() scrolled: EventEmitter<any> = new EventEmitter();

  scrollAction() {
    this.scrolled.emit();
  }

}
