import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { PlanService, UserPlan } from '../../reports/plan/plan.service';
import { User } from '../user.interface';
import { PlanType } from '../user-administration.service';
import { JointControllerService } from '../../joint-controller/joint-controller.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  @ViewChild('planSelect', {static: false}) planSelect: MatSelect;

  readonly planTypes = Object.values(PlanType);

  user: User;

  plan: UserPlan;

  contractEndDate: Date;

  constructor(private userService: UserService,
              private planService: PlanService,
              private jointControllerService: JointControllerService) {
  }

  ngOnInit() {
    this.userService.getUser().subscribe((user) => {
      this.user = user;
    });

    this.planService.getPlanForUser().subscribe((plan) => {
      /* istanbul ignore else */
      if (typeof plan.enterpriseContractEndDay === 'string') {
        this.contractEndDate = new Date(plan.enterpriseContractEndDay);
      }
      this.plan = plan;
    });
  }

  downloadJointControllerPdf() {
    this.jointControllerService.downloadJointController();
  }

  hasUserDocuments(): boolean {
    return this.hasUserJointControllerDocument();
  }

  hasUserJointControllerDocument(): boolean {
    return this.user.signedJointController != null;
  }
}
