import { Component } from '@angular/core';

@Component({
  selector: 'app-reports',
  template: '<router-outlet></router-outlet>',
  styleUrls: [
    'reports.component.scss'
  ]
})
export class ReportsComponent {
}
