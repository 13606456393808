import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { ProfilingScript } from '../../../../help/resource/profiling-script';
import { NotificationService } from '../../../../notification/notification.service';
import { User } from '../../../../user/user.interface';
import { UserService } from '../../../../user/user.service';

@Component({
  selector: 'app-pixel-integration',
  templateUrl: './pixel-integration.component.html',
  styleUrls: ['./pixel-integration.component.scss']
})
export class PixelIntegrationComponent implements OnInit {

  @Input() eventId;

  readonly typeScript = 'script';
  readonly typePixel = 'pixel';
  readonly typeAllow = 'allow';

  readonly tooltipCopyButton = 'Code kopieren';

  selectedIntegrationType: 'script' | 'pixel' | 'allow' = this.typePixel;

  private audienceInsightSid = 50870;

  private user: User;

  constructor(private notification: NotificationService,
              private clipboard: Clipboard,
              private userService: UserService) {
  }

  get scriptIntegrationCode() {
    return ProfilingScript
      .sid(this.audienceInsightSid)
      .withEventId(this.eventId)
      .withPrivatePool(this.user?.privatePool === true)
      .toPixelAsLoaderScript();
  }

  get pixelIntegrationCode() {
    return ProfilingScript
      .sid(this.audienceInsightSid)
      .withEventId(this.eventId)
      .withPrivatePool(this.user?.privatePool === true).toDataScriptString();
  }

  get imagePixelIntegrationCode() {
    return ProfilingScript
      .sid(this.audienceInsightSid)
      .withEventId(this.eventId)
      .withPrivatePool(this.user?.privatePool === true)
      .toPixelAsImagePixel();
  }


  ngOnInit(): void {
    this.userService.getUser().subscribe((user) => {
      this.user = user;
    });
  }

  setSelectedIntegrationType(value) {
    this.selectedIntegrationType = value;
  }

  copyPixelToClipboard(text: string) {
    const copySelectSuccess = this.clipboard.copy(text);
    if (copySelectSuccess) {
      this.notification.sendSuccess('Code in die Zwischenablage kopiert.');
    } else {
      this.notification.sendError('Oops!, Das Kopieren hat nicht geklappt... \\(o_o)/');
    }
  }
}
