<div id="chart">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [plotOptions]="chartOptions.plotOptions"
      [labels]="chartOptions.labels"
      [legend]="chartOptions.legend"
      [colors]="chartOptions.colors"
      [responsive]="chartOptions.responsive"
    ></apx-chart>
  </div>