import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  NodeCallback
} from 'amazon-cognito-identity-js';
import { environment } from '../../environments/environment';

export class CognitoIdTokenStub extends CognitoIdToken {

  override decodePayload() {
    const payload = {};
    payload['cognito:groups'] = ['QS', 'USER_ADMIN'];

    return payload;
  }
}

export class CognitoUserSessionStub extends CognitoUserSession {
  override getIdToken(): CognitoIdToken {
    return new CognitoIdTokenStub({IdToken: 'fakeIdToken'});
  }
}

export class CognitoUserStub extends CognitoUser {

  constructor() {
    super({
      Username: 'MockUser',
      Pool: new CognitoUserPool({
        UserPoolId: environment.userPoolId,
        ClientId: environment.clientId
      })
    });
  }

  override getUserAttributes(callback: NodeCallback<Error, CognitoUserAttribute[]>): void {
    callback(null, [new CognitoUserAttribute({Name: 'email', Value: 'test@test.com'})]);
  }

  override getUsername(): string {
    return 'MockUser';
  }

  override getSignInUserSession(): CognitoUserSession {
    return new CognitoUserSessionStub({
      IdToken: new CognitoIdToken({IdToken: 'fakeIdToken'}),
      AccessToken: new CognitoAccessToken({AccessToken: 'fakeAccessToken'})
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  override getSession(callback: Function): any {
    callback(null, new CognitoUserSession({
      IdToken: new CognitoIdToken({IdToken: new Date().getTime().toString()}),
      AccessToken: new CognitoAccessToken({AccessToken: 'addfgdsfadsfa'})
    }));
  }
}
