<!-- <div class="container-fluid">
  <div class="row">
    <div class="col-10">
 -->
      <div id="chart">
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [stroke]="chartOptions.stroke"
          [labels]="chartOptions.labels"
          [fill]="chartOptions.fill"
          [responsive]="chartOptions.responsive"
        ></apx-chart>
      </div>

      <!-- <canvas baseChart
        [width]="dimensions.width"
        [height]="dimensions.height"
        [datasets]="radarChartDatasets"
        [options]="radarChartOptions"
        [labels]="radarChartLabels"
        [type]="'polarArea'"
        [legend]="false">
      </canvas> -->
<!--
    </div>
  </div>
</div> -->

<!-- radar -->
<!-- [legend]="chartOptions.legend"
[colors]="chartOptions.colors" -->




