import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../notification/notification.service';
import { TrackingService } from '../../tracking/tracking.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot.component.html'
})
export class ForgotComponent implements OnInit {
  public forgotPasswordForm: FormGroup;
  public errorMessage: string;
  resetPasswordUsername: string;

  constructor(private router: Router,
              private authService: AuthService,
              private notification: NotificationService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.resetPasswordUsername = (this.activatedRoute.snapshot.queryParams['resetPasswordUsername']) ?
      this.activatedRoute.snapshot.queryParams['resetPasswordUsername'] : '';
    this.forgotPasswordForm = new FormGroup({
      username: new FormControl(this.resetPasswordUsername, Validators.required)
    });
  }

  requestPasswordReset(): Promise<any> {
    const username = this.forgotPasswordForm.controls['username'];
    if (username.valid) {
      return this.authService.requestForgottenPasswordChange(username.value)
        .then(() => {
          this.router.navigate(['/reset'], {queryParams: {username: username.value}});
        })
        .catch((error) => {
          if (error.code && error.code === 'UserNotFoundException') {
            this.notification.sendError('E-Mail-Adresse existiert nicht.');
          } else {
            this.notification.sendError('Das Passwort kann vom Benutzer nicht zurückgesetzt werden. ' + error.message);
          }
          TrackingService.track('forgotPasswordError', error.message);
        });
    }
    return Promise.resolve();
  }
}
