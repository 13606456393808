<h1 class="ai-h1-light ai-page-header pl-4">{{pageName()}}</h1>

<section [matRippleAnimation]="{enterDuration: 50, exitDuration: 500}"
         class="search-bar pl-4 pr-4"
         matRipple>
  <app-reports-list-filter (changed)="reportFilterValue = $event" *ngIf="!loadingData"></app-reports-list-filter>
</section>

<div *ngIf="plan" class="plan-banner">
  <app-plan-banner [createButtonRipple]="createReportButtonRipple" [userPlan]="plan | async" [reportDisplayType]="showReportCategory" (createReport)="createReport()"></app-plan-banner>
</div>

<div *ngIf="isEvaluation()">
  <app-evaluation-reports-table
    [reports]="reports"
    [userPlan]="plan | async"
    [filterValue]="reportFilterValue"
  ></app-evaluation-reports-table>
</div>

<div *ngIf="isTracking()">
  <app-tracking-reports-table
    [reports]="reports"
    [filterValue]="reportFilterValue"
  ></app-tracking-reports-table>
</div>

<div *ngIf="loadingData" class="d-flex justify-content-center pt-3">
  <mat-spinner diameter="40" color="primary"></mat-spinner>
</div>
