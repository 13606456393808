<h1 class="ai-h1-light ai-page-header pl-4">Script Integration</h1>

<article class="centered">
  <section>
    <div *ngIf="user?.hasClassicIntegration" class="centered-content">
      <p>Wenn du das beste Ergebnis mit unseren Audience Insight Tools erziehlen willst, empfehlen wir dir die
        Integration
        unseres <code>profiling scripts</code> auf deiner Website (bzw. der Website deines Kunden). Das ermöglicht es
        uns Ihnen eine
        noch detailiertere Sicht auf deine Kunden zu gewähren. Informationen wie du das Script auf deiner Seite einbauen
        musst findest
        du weiter unten im Text. Du solltest hierfür das aktuellste Script einbinden, welches wir dir über einen
        High Performance CDN zur Verfügung stellen.</p>

      <h2>Wie integriere ich das Profiling Script?</h2>

      <p>Um den Script in deiner Seite zu integrieren musst du einfach folgenden Script Tag am Ende des Quelltextes
        deiner
        Seite einbinden (vor dem schließenden <i>&lt;/body&gt;</i> tag)</p>

      <p>Weiter Informationen über die Funktionen und die Nutzung unseres Scripts findst du auch unter folgendem Link
        <a href="https://doc.emetriq.de/#/profiling/adp/integration" target="_blank">http://docs.emetriq.de</a></p>


      <app-note *ngIf="showNotification">
        Bitte beachte den <strong>&lt;your sId&gt;</strong> Platzhalter in der Script URL, welche hier im Quelltext
        Beispiel angezeigt wird. Du musst diesen Teil mit deiner persönlichen <strong>sId</strong> ersetzen, damit das
        Script fehlerfrei funktionieren kann. Momentan ist deinem Account keine <strong>sId</strong> zugewiesen, wenn du
        eine
        <strong>sId</strong> für deinen Account zugewiesen haben willst, oder eine bestehende <strong>sId</strong> nicht
        korrekt mit deinem Account verknüpft wurde, bitte kontaktiere unser
        <a href="mailto:advertisersolutions@emetriq.com">Segment Management</a>
      </app-note>

      <div *ngFor="let tag of scriptTags; let i = index" class="copy-script p-1">
        <div class="copy-script-tag script">
          <pre><code [highlight]="tag"></code></pre>
        </div>
        <div class="copy-script-container">
          <a (click)="copyScriptToClipboard(i)" class="copy-script-button" mat-flat-button>
            <mat-icon>content_copy</mat-icon>
            In die Zwischenablage kopieren
          </a>
        </div>
      </div>

      <p>Der Script sollte wie folgt in deiner Website integriert werden:</p>
      <div class="script">
        <pre><code [highlight]="codeBlock"></code></pre>
      </div>
    </div>

    <div *ngIf="!user?.hasClassicIntegration" class="centered-content">

      <p>
        Wenn du Daten in deinem URL basierten Report erhalten willst, ohne dir Gedanken über CMP machen zu müssen,
        sollst
        du den Script wie folgt in deiner Webseite (bzw. der Webseite deines Kunden) einbauen:
      </p>
      <p>
        Der Script wird nur dann aufgerufen, wenn du die Zustimmung des Users gemäß der <a routerLink="/legal/privacy">GDPR</a>
        eingeholt hast.
      </p>
      <p>
        Kopiere den Script Tag in den <strong>&lt;head&gt;</strong> bzw. <strong>&lt;body&gt;</strong> Bereich deiner
        Website
      </p>

      <div class=" p-3 container-fluid ">
        <div class="row script">
          <div class="col-10">
            <pre><code [highlight]="scriptTags[0]"></code></pre>
          </div>
          <div class="col-2 d-flex justify-content-end align-items-start">
            <a (click)="copyScriptToClipboard(0)" class="copy-script-button" mat-flat-button>
              <mat-icon>content_copy</mat-icon>
            </a>
          </div>
        </div>

        <p>Der Script sollte wie folgt in deiner Webseite eingebaut werden:</p>

        <div class="row script">
          <div class="col-12">
            <pre><code [highlight]="codeBlock"></code></pre>
          </div>
        </div>

      </div>

    </div>
  </section>
  <section>
    <div class="centered-content">
      <app-live-events-bubble [small]="true"></app-live-events-bubble>
    </div>
  </section>
</article>


