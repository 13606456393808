import { CdkDrag, CdkDragDrop, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EvaluationSegmentGroup, Segment } from '../../reports.interface';

@Component({
  selector: 'app-reports-evaluation-filter',
  templateUrl: './reports-evaluation-filter.component.html',
  styleUrls: ['./reports-evaluation-filter.component.scss']
})
export class ReportsEvaluationFilterComponent {

  @Output() toggleSegment = new EventEmitter();

  @Input() evaluationGroups: EvaluationSegmentGroup[] = [];

  @Input() edit = true;

  constructor() {
  }

  /**
   * The dropped segment must not be included in any of the existing groups.
   *
   * @param drag item to drag drop
   */
  enterPredicate = (drag: CdkDrag<Segment>) => {
    // TODO 15.03.22: drag.data is undefined when dragging within the groups
    const segments = this.evaluationGroups.map((g) => g.segments).flat();
    return !segments.includes(drag.data);
  };

  /**
   * @return the new size of groups
   */
  addDefaultGroup(): number {
    return this.evaluationGroups.push({groupOperator: 'AND', segmentOperator: 'OR', segments: []});
  }

  switchGroupOperator(groupIndex) {
    const currentGroup = this.evaluationGroups[groupIndex];
    if (currentGroup !== undefined) {
      currentGroup.groupOperator = currentGroup.groupOperator === 'AND' ? 'AND_NOT' : 'AND';
    }
  }

  switchSegmentOperator(groupIndex) {
    const currentGroup = this.evaluationGroups[groupIndex];
    if (currentGroup !== undefined) {
      currentGroup.segmentOperator = currentGroup.segmentOperator === 'AND' ? 'OR' : 'AND';
    }
  }

  removeSegment(groupIndex, segment) {
    const currentGroup = this.evaluationGroups[groupIndex];
    if (currentGroup !== undefined) {
      this.toggleSegment.emit(segment.eId);
      const indexOf = currentGroup.segments.indexOf(segment);
      if (indexOf >= 0) {
        currentGroup.segments.splice(indexOf, 1);
      }
    }
  }

  removeGroup(groupIndex) {
    const currentGroup = this.evaluationGroups[groupIndex];
    if (currentGroup) {
      currentGroup.segments.forEach((segment) => {
        this.toggleSegment.emit(segment.eId);
      });
      this.evaluationGroups.splice(groupIndex, 1);
    }
  }

  drop(event: CdkDragDrop<Segment[] | undefined>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      return;
    }

    let dropTargetData = event.container.data;
    if (event.container.id === 'addGroupButton') {
      const newGroupIndex = this.addDefaultGroup() - 1;
      dropTargetData = this.evaluationGroups[newGroupIndex].segments;
    }

    if (event.previousContainer.id === 'segments') {
      copyArrayItem(
        event.previousContainer.data,
        dropTargetData,
        event.previousIndex,
        event.currentIndex
      );
      // TODO 15.03.22: can be handled by drag event self
      this.toggleSegment.emit(event.previousContainer.data[event.previousIndex].eId);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        dropTargetData,
        event.previousIndex,
        event.currentIndex
      );

    }

  }

}
