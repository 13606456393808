<h1 class="ai-page-header ai-h1-light pl-4">Evaluation Report</h1>


<div id="evaluation-report-caption" class="px-3 py-4 d-flex justify-content-center">
  <span>Erstelle einen Report deiner Audience durch die Auswahl der offensichtlichsten Merkmale. Unser machine learning wird Millionen von Profilen analysieren, um tiefere Insights zu gewinnen.</span>
</div>

<div class="d-flex align-content-center justify-content-center my-4" *ngIf="!contentLoaded">
  <h3>Lade Segmente...</h3>
</div>

<div class="d-flex flex-row">

  <div class="mb-4 ai-full-width px-2" cdkDropListGroup>

    <div id="evaluation-filter-container" #filterEditor>
      <app-reports-evaluation-filter
        (toggleSegment)="toggleSegment($event)"
        [evaluationGroups]="evaluationGroups"></app-reports-evaluation-filter>
    </div>

    <section [matRippleAnimation]="{enterDuration: 50, exitDuration: 500}"
             id="evaluation-search-container"
             class="search-bar my-2"
             matRipple [style.top.px]="filterPosition">
      <app-reports-list-filter placeholder="Nach Segmenten suchen"
                               (changed)="searchTerm = $event"></app-reports-list-filter>
    </section>

    <div>
      <div *ngFor="let topic of segmentsTopics | evaluationSearch: searchTerm">
        <h2 class="ai-h2">{{topic.name}}</h2>
        <div class="segment-button-container d-flex flex-wrap"
             cdkDropList
             id="segments"
             cdkDropListSortingDisabled
             [cdkDropListEnterPredicate]="enterPredicate"
             [cdkDropListData]="topic.segments | evaluationSearch: searchTerm">
          <div *ngFor="let segment of topic.segments | evaluationSearch: searchTerm"
               cdkDrag
               [cdkDragDisabled]="segment.selected"
               [cdkDragData]="segment"
               class="p-1" [id]="'segment_' + segment.eId" (dblclick)="addToLastGroup(segment)">
            <div class="button-segment">
              {{segment.text}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-between controller-container align-items-center p-2">

  <div class="px-2 py-1" id="report-name-input-container">
    <mat-form-field>
      <input matInput
             required
             maxlength="50"
             id="evaluation-report-name"
             placeholder="Report Name"
             [formControl]="name">
      <mat-error>Bitte gib einen Namen ein</mat-error>
    </mat-form-field>
  </div>

  <button *ngIf="showButton"
          (click)="reportForEdit ? update() : create()"
          [disabled]="!reportAvailable"
          color="primary"
          mat-flat-button
          type="submit">
    {{reportForEdit ? 'Speichern' : 'Erstellen'}}
  </button>

</div>

