import { AbstractControl, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import * as moment from 'moment';
import { environment } from '../../environments/environment';

export class ParentErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = !!(form && form.submitted);
    const controlTouched = !!(control && (control.dirty || control.touched));
    const controlInvalid = !!(control && control.invalid);
    const parentInvalid = !!(control && control.parent && control.parent.invalid && (control.parent.dirty || control.parent.touched));

    return isSubmitted || (controlTouched && (controlInvalid || parentInvalid));
  }
}

export class ValidationUtils {

  static configPasswordValidation(charStrength: any = environment.cognitoPolicy) {
    const validations = [Validators.required, Validators.minLength(charStrength.MinimumLength)];

    if (charStrength.RequireLowercase) {
      validations.push(ValidationUtils.lowercaseValidator);
    }
    if (charStrength.RequireUppercase) {
      validations.push(ValidationUtils.uppercaseValidator);
    }
    if (charStrength.RequireNumbers) {
      validations.push(ValidationUtils.numbersValidator);
    }
    if (charStrength.RequireSymbols) {
      validations.push(ValidationUtils.symbolsValidator);
    }
    return validations;
  }

  static uppercaseValidator(control) {
    if (control.value.match(/[A-Z]/)) {
      return null;
    } else {
      return {noUppercase: true};
    }
  }

  static lowercaseValidator(control) {
    if (control.value.match(/[a-z]/)) {
      return null;
    } else {
      return {noLowercase: true};
    }
  }

  static symbolsValidator(control) {
    if (control.value.match(/[^\w\s]/)) {
      return null;
    } else {
      return {noSymbols: true};
    }
  }

  static numbersValidator(control) {
    if (control.value.match(/[0-9]/)) {
      return null;
    } else {
      return {noNumbers: true};
    }
  }

  static passwordConfirmEqual(passwordFormGroup: FormGroup) {
    let compareValue = '';
    let matches = false;
    Object.keys(passwordFormGroup.controls).forEach((key) => {
      const val = passwordFormGroup.controls[key].value;
      if (compareValue === val) {
        matches = true;
      }
      compareValue = val;
    });
    return matches ? null : {matchOther: true};
  }

  static dateEqualOrAfter(other: AbstractControl) {
    return (control: AbstractControl) => {
      if (other && other.value && control.value) {
        const otherDay = moment(control.value).startOf('day');
        const thisDay = moment(other.value).startOf('day');
        if (otherDay.isBefore(thisDay)) {
          return {dateEqualOrAfter: true};
        }
        return null;
      }
      return null;
    };
  }

  static dateEqualOrBefore(other: AbstractControl) {
    return (control: AbstractControl) => {
      if (other && other.value && control.value) {
        const otherDay = moment(other.value).startOf('day');
        const thisDay = moment(control.value).startOf('day');
        if (thisDay.isAfter(otherDay)) {
          return {dateEqualOrBefore: true};
        }
        return null;
      }
      return null;
    };
  }

}
