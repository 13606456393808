<div *ngIf="showReportSource()">
  <div id="report-source">
    <div class="report-source-caption">Report Source(s):</div>
    <div class="list-item">
      <div class="report-source-list">
        <ng-container *ngFor="let url of getUrls(); let i=index">
          <div class="report-source-url"
               *ngIf="urlListExpanded || i < urlToShow"
               [matTooltip]="url">{{url}}</div>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-container *ngIf="getUrls().length > 0 && getUrls().length > urlToShow">
    <button mat-flat-button
            class="report-source-expand"
            matTooltip="URL Liste einklappen"
            *ngIf="urlListExpanded"
            (click)="urlListExpanded = !urlListExpanded">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
    <button mat-flat-button
            class="report-source-expand"
            matTooltip="URL Liste ausklappen"
            *ngIf="!urlListExpanded"
            (click)="urlListExpanded = !urlListExpanded">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </ng-container>
</div>
