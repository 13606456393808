import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { LayoutFormModule } from '../../layout/form/form.module';
import { NoteModule } from '../../note/note.module';
import { UrlSelectModule } from '../url-select/url-select.module';
import { UrlEventSuccessComponent } from './success/success.component';
import { ReportsUrlEventComponent } from './url-event.component';
import { HighlightModule } from 'ngx-highlightjs';

export const COMPONENT_IMPORTS = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  NoteModule,
  LayoutFormModule,
  UrlSelectModule,
  HighlightModule
];

@NgModule({
  imports: COMPONENT_IMPORTS,
  declarations: [
    ReportsUrlEventComponent,
    UrlEventSuccessComponent
  ],
  exports: [
    ReportsUrlEventComponent
  ]
})
export class UrlEventModule {
}
