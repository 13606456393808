import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { slideInLeftAnimation } from '../../../app.animation';
import { NotificationService } from '../../../notification/notification.service';
import { TrackingService } from '../../../tracking/tracking.service';
import { UndoService } from '../../../undo/undo.service';
import { Pixel, TrackingEventReport } from '../../reports.interface';
import { ReportsService } from '../../reports.service';

@Component({
  selector: 'app-report-form-edit',
  templateUrl: './edit.component.html',
  styleUrls: [
    './edit.component.scss'
  ],
  animations: [slideInLeftAnimation]
})
export class ReportsPixelEditComponent implements OnInit {

  @ViewChild('reportPixelElement', {static: true}) reportPixelElement;

  readonly maxLengthName = 100;

  reportPixel: Pixel;

  submitReportForm: FormGroup;

  showQSView = false;

  reportId: string;

  reportStatus: string;

  lastReport: TrackingEventReport;

  subscriptionReportUpdatedAfterUndo: Subscription;

  subscription = new Subscription();

  constructor(private service: ReportsService,
              private router: Router,
              private formBuilder: FormBuilder,
              private trackingService: TrackingService,
              private activeRoute: ActivatedRoute,
              private undo: UndoService,
              private notification: NotificationService) {
  }

  @HostBinding('@slideInLeftAnimation') get slideInLeftAnimation() {
    return true;
  }

  ngOnInit() {
    this.submitReportForm = this.formBuilder.group({
      label: ['', [Validators.required]],
      eventId: [null, [Validators.required]]
    });

    this.reportId = this.activeRoute.snapshot.params['id'];

    /* istanbul ignore else */
    if (this.reportId) {
      this.getReport(this.reportId);
    }
  }

  getReport(id: string): void {
    this.service
      .getReport(id)
      .subscribe(
        (report: TrackingEventReport) => {
          // update form data ...
          this.submitReportForm.patchValue({
            label: report.label,
            eventId: report.eventId
          });

          this.lastReport = report;

          this.reportStatus = report.status;

          // update pixel ...
          this.reportPixel = {eventId: report.eventId, htmlElement: report.pixelHtml};
        },
        (error) => {
          this.notification.sendError('Der Report konnte nicht geladen werden. Bitte versuche es später erneut!');
        });
  }

  updateReport() {
    if (this.submitReportForm.dirty && this.submitReportForm.valid) {

      this.initUndoWithLastReport(this.lastReport);

      const report: TrackingEventReport = {...this.submitReportForm.value, id: this.reportId};
      this.service.updateReport(report).subscribe(() => {
        this.getReport(this.reportId);
        this.trackingService.event('updateReport', report);
      });
    }
  }

  initUndoWithLastReport(lastReport: TrackingEventReport) {
    this.undo.register(() => {
      this.undoAction(lastReport);
    });
  }

  undoAction(lastReport: TrackingEventReport) {
    this.service
      .updateReport(lastReport)
      .subscribe(() => {
        this.getReport(lastReport.id);
      });
  }
}
