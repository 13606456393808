import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-editable-table-cell',
  templateUrl: './editable-table-cell.component.html',
  styleUrls: ['./editable-table-cell.component.scss']
})
export class EditableTableCellComponent {
  @Input() inputModel: string;
  @Input() editable = true;
  @ViewChild('contentField', {static: false}) contentField: ElementRef;
  @Output() inputModelChange = new EventEmitter<string>();

  showForm = false;

  clickToEdit() {
    if (this.editable) {
      this.showForm = true;
      setTimeout(() => {
        /* istanbul ignore else */
        if (this.contentField && this.contentField.nativeElement) {
          this.contentField.nativeElement.focus();
        }
      });
    }
  }

  updateContent(input) {
    this.showForm = false;
    this.inputModelChange.emit(input);
  }
}
