<div class="form-input-container" id="tracking-pixel-container">

  <section class="header">
    <div (click)="setSelectedIntegrationType(typeScript)"
         [ngClass]="{'selected': selectedIntegrationType === typeScript}">
      <span>Script Integration</span>
    </div>
    <div (click)="setSelectedIntegrationType(typePixel)"
         [ngClass]="{'selected': selectedIntegrationType === typePixel}">
      <span>Pixel Integration</span>
    </div>
    <div (click)="setSelectedIntegrationType(typeAllow)"
         [ngClass]="{'selected': selectedIntegrationType === typeAllow}">
      <span>Zulassungsliste</span>
    </div>
  </section>

  <section class="content">

    <div *ngIf="selectedIntegrationType === typeScript">
      <label>
        Sammle Report Daten indem du ein JavaScript Tag in deiner Seite integrierst.</label>
      <mat-card class="pixel-code">
        <div><code #integrationScriptCode [highlight]="scriptIntegrationCode">{{scriptIntegrationCode}}</code></div>
        <div class="copy-button" title="{{tooltipCopyButton}}">
          <a (click)="copyPixelToClipboard(integrationScriptCode.textContent)" mat-icon-button>
            <mat-icon>content_copy</mat-icon>
          </a>
        </div>
      </mat-card>
      <span>
        Dieses Script kann automatisch den <strong>IAB TCF 2.0</strong> Standart Consent Data aus der integrierten Seite auslesen (soweit dieser zur Verfügung gestellt wird)</span>
    </div>

    <div *ngIf="selectedIntegrationType === typePixel">
      <label>Sammle Report Daten mit der Integration eines Image-Pixel.</label>
      <mat-card class="pixel-code" title="{{tooltipCopyButton}}">
        <div>
          <code #imagePixelScriptCode [highlight]="imagePixelIntegrationCode"></code>
        </div>
        <div class="copy-button">
          <a (click)="copyPixelToClipboard(imagePixelScriptCode.textContent)" mat-icon-button>
            <mat-icon>content_copy</mat-icon>
          </a>
        </div>

      </mat-card>

      <p><!-- place holder --></p>

      <label>Sammle Report Daten mit der Integration eines Java-Script-Image-Pixel</label>
      <mat-card class="pixel-code" title="{{tooltipCopyButton}}">
        <div>
          <code #pixelScriptCode [highlight]="pixelIntegrationCode"></code>
        </div>
        <div class="copy-button">
          <a (click)="copyPixelToClipboard(pixelScriptCode.textContent)" mat-icon-button>
            <mat-icon>content_copy</mat-icon>
          </a>
        </div>
      </mat-card>

      <app-note header="Consent Macros / Consent Parameter">
        <span>Die meisten AD Server können diese Standart GDPR Macros automatisch ohne dein Zutun setzen. Sollst du keinen
          allgemeinen AD Server benutzen, musst du selbst dafür sorgen, dass die Macros/Parameter korrekt gesetzt sind.</span>

        <p><!-- spacer --></p>

        <p>
          <code>$&#123;GDPR&#125;</code> = <code>0</code> / <code>1</code>
        </p>
        <p>
          <code>$&#123;GDPR_CONSENT_213&#125;</code> = <a
          href="https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20Consent%20string%20and%20vendor%20list%20formats%20v2.md#how-does-a-url-based-service-process-the-tc-string-when-it-cant-execute-javascript"
          target="_blank">Consent
          String</a> for vendor
          <strong>emetriq</strong>
          .
          <a href="https://doc.emetriq.de/#/profiling/adp/consent?id=consent-url-parameters" target="_blank">Weiterführende
            Informationen.</a>
        </p>


      </app-note>

      <label>Beispiel mit ersetzten Parametern/Macros:</label>
      <mat-card class="pixel-code">
        <div>
          <code
            #imagePixelScriptCode
            [highlight]="imagePixelIntegrationCode.replace('${GDPR}','1').replace('${GDPR_CONSENT_213}','A_VALID_CONSENT_STRING_ZAYzUw')">
          </code>
        </div>
      </mat-card>

    </div>

    <div *ngIf="selectedIntegrationType === typeAllow">
      <label>Was ist unsere "Zulassungsliste"</label>

      <p>Unsere Zulassungsliste ist für diejenigen unserer Kunden, die eine eigene Zustimmungsüberprüfung nutzen und
        unseren Pixel
        erst nach Prüfung der Zustimmung ihrer Nutzer aktivieren.</p>

      <label>Wie kannst du eingebunden werden?</label>

      <p>Hierfür musst du unseren Vertragsbestimmungen zustimmen. Bitte kontaktiere unseren Kundensupport <a
        src="mailto:support-insights@emetriq.com">support-insights@emetriq.com</a>, für weitere Informationen.</p>
    </div>

    <div *ngIf="selectedIntegrationType !== typeAllow">
      <app-note><span>Integriere den oben gezeigten Pixel auf deiner Seite innerhalb des
              <strong>&#60;body&#62;...&#60;&#8725;body&#62;</strong> oder
              <strong>&#60;head&#62;...&#60;&#8725;head&#62;</strong> Elements.
        Dies kann auch über einen Tag Manager gemacht werden.</span>
      </app-note>
    </div>
  </section>

</div>
