import { Component, HostBinding, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-chart-segment',
  templateUrl: './chart-segment.component.html',
  styleUrls: ['./chart-segment.component.scss']
})
export class ChartSegmentComponent {
  @Input() primaryPercentage: number;
  @Input() primaryPercentageLabel: string;
  @Input() secondaryPercentage: number;
  @Input() secondaryPercentageLabel: string;
  @Input() label: string;
  @Input() align: string;
  @HostBinding('class.highlight') @Input() highlight: boolean;
  @HostBinding('class.with-description') @Input() description: string;

  @HostListener('click', ['$event']) onClick(event) {
    event.currentTarget.classList.toggle('open');
  }
}
