<div *ngIf="false" class="hint">Daten für tracking event Report verfügbar! Klicke "Anwenden"</div>


<!--
  Main view ...
-->
<div id="main-container" [ngClass]="{'simple-page': displaySimplePage}">
  <app-header id="header" [displaySimplePage]="displaySimplePage" [simplePageTitle]="simplePageTitle"></app-header>
  <app-banner></app-banner>
  <div [@routerAnimation]="o.isActivated ? o.activatedRoute : ''" class="content-container">
    <router-outlet #o="outlet"></router-outlet>
  </div>
</div>

<app-footer></app-footer>

<app-notification></app-notification>
