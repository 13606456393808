import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RoutingData } from '../app.interface';

const DEFAULT_ROUTING_DATA: RoutingData = {title: 'Audience Insights', button: 'menu', disableButton: false};

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private routingData: Subject<RoutingData> = new BehaviorSubject<RoutingData>(DEFAULT_ROUTING_DATA);

  constructor(private router: Router) {
    this.listenForData(router.events);
  }

  listenForData(routerEvents: Observable<Event>) {
    routerEvents.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const currentRouterState = this.router.routerState;
      let activatedRouteSnapshot = currentRouterState.snapshot.root;
      // navigate the current active route down to child
      while (activatedRouteSnapshot.firstChild) {
        activatedRouteSnapshot = activatedRouteSnapshot.firstChild;
      }
      this.routingData.next(activatedRouteSnapshot.data as RoutingData);
    });
  }

  /**
   * @return observable as BehaviorSubject with initial null value
   */
  onRoutingData(): Observable<any> {
    return this.routingData;
  }
}
