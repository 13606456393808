import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  menuItems = [];
  showBreadcrumb = false;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private location: Location) {
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
      });
  }

  createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs = []) {
    const children: ActivatedRoute[] = route.children;

    /* istanbul ignore else */
    if (children.length === 0) {
      this.showBreadcrumb = route.snapshot.data['showBreadcrumb'] !== false;
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL = child.snapshot.url.map((segment) => segment.path).join('/');
      const params = child.snapshot.queryParams;

      /* istanbul ignore else */
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data['breadcrumb'] || '';

      /* istanbul ignore else */
      if (label) {
        breadcrumbs.push({label, url, params});
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  back(): void {
    this.location.back();
  }
}
