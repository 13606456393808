import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsEvaluationFilterComponent } from './reports-evaluation-filter.component';
import { NoteModule } from '../../../note/note.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ReportsEvaluationFilterComponent],
  imports: [
    CommonModule,
    NoteModule,
    DragDropModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [ReportsEvaluationFilterComponent]
})
export class ReportsEvaluationFilterModule {
}
