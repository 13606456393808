import { NgModule } from '@angular/core';
import { LayoutFormModule } from '../layout/form/form.module';
import { PageModule } from '../layout/page/page.module';
import { SharedModule } from '../modules/shared.module';
import { ChangePasswordComponent } from './change/change-password.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ForgotComponent } from './forgot/forgot.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { ResetComponent } from './reset/reset.component';
import { HasRoleDirective } from './role/has-role.directive';
import { JointControllerStepsComponent } from '../joint-controller/steps/steps.component';
import { JointControllerModule } from '../joint-controller/joint-controller.module';

@NgModule({
  imports: [
    SharedModule,
    LayoutFormModule,
    PageModule,
    JointControllerModule
  ],
  declarations: [
    LoginComponent,
    LogoutComponent,
    ConfirmComponent,
    ChangePasswordComponent,
    PasswordStrengthComponent,
    HasRoleDirective,
    ForgotComponent,
    ResetComponent,
    JointControllerStepsComponent,
  ],
  exports: [
    LoginComponent,
    LogoutComponent,
    HasRoleDirective
  ]
})
export class AuthModule {
}
