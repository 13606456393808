import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { registerIcons } from './icons';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: registerIcons,
      multi: true,
      deps: [
        MatIconRegistry,
        DomSanitizer
      ]
    }
  ],
  declarations: []
})
export class IconsModule {

  constructor() {

  }
}
