import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss']
})
export class PasswordStrengthComponent {
  @Input() control: AbstractControl;

  charStrength = environment.cognitoPolicy;

  uppercase(): boolean {
    return this.control && this.control.hasError('noUppercase');
  }

  lowercase(): boolean {
    return this.control && this.control.hasError('noLowercase');
  }

  numbers(): boolean {
    return this.control && this.control.hasError('noNumbers');
  }

  symbols(): boolean {
    return this.control && this.control.hasError('noSymbols');
  }

  required(): boolean {
    return this.control && this.control.hasError('required');
  }

  length(): boolean {
    return this.control && this.control.hasError('minlength');
  }

}
