import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadialBarComponent } from './radial-bar/radial-bar.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { StackedHorizontalBarComponent } from './stacked-horizontal-bar/stacked-horizontal-bar.component';



@NgModule({
  declarations: [
    RadialBarComponent,
    StackedHorizontalBarComponent
  ],
  imports: [
    CommonModule,
    NgApexchartsModule
  ],
  exports: [
    RadialBarComponent,
    StackedHorizontalBarComponent
  ]
})
export class ApexChartsWrapperModule { }
