import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-horizontal-bar',
  templateUrl: './horizontal-bar.component.html',
  styleUrls: ['./horizontal-bar.component.scss']
})
export class HorizontalBarComponent implements OnInit {
  @Input() label;
  @Input() audience;
  @Input() datapool;

  icons = {
    Frühstücksprodukte: '/assets/img/icons/charts/icon-charts-breakfast.png',
    Säfte: '/assets/img/icons/charts/icon-charts-juice.png',
    Milchprodukte: '/assets/img/icons/charts/icon-charts-milk.png',
    Käseprodukte: '/assets/img/icons/charts/icon-charts-cheese.png',
    Zahnpflege: '/assets/img/icons/charts/icon-charts-mouth-care.png',
    "Fleisch & Wurst": '/assets/img/icons/charts/icon-charts-meat.png',
    Grundnahrungsmittel: '/assets/img/icons/charts/icon-charts-food-and-drinks.png',
    Trinkwasser: '/assets/img/icons/charts/icon-charts-water.png',
    Waschmittel: '/assets/img/icons/charts/icon-charts-laundry.png',
    "Soft-Drinks": '/assets/img/icons/charts/icon-charts-soft-drinks.png',
    Fischprodukte: '/assets/img/icons/charts/icon-charts-fish.png',
    "Chips & Sticks": '/assets/img/icons/charts/icon-charts-chips-sticks.png',
    Süßigkeiten: '/assets/img/icons/charts/icon-charts-sweets.png',
    Suppen: '/assets/img/icons/charts/icon-charts-soup.png',
    Küchenwaren: '/assets/img/icons/charts/icon-charts-kitchen-products.png',
    Badezimmerbedarf: '/assets/img/icons/charts/icon-charts-icon-charts-bath-products.png',
    "Wein & Sekt": '/assets/img/icons/charts/icon-charts-wine.png',
    Marmelade: '/assets/img/icons/charts/icon-charts-jam.png',
    Spirituosen: '/assets/img/icons/charts/icon-charts-spirits.png',
    Reinigungsmittel: '/assets/img/icons/charts/icon-charts-cleaning-products.png',
    Tierfutter: '/assets/img/icons/charts/icon-charts-pet-food.png',
    Bierprodukte: '/assets/img/icons/charts/icon-charts-icon-charts-beer.png',
    Körperpflegeprodukt: '/assets/img/icons/charts/icon-charts-body-care-products.png',
    Haarpflege: '/assets/img/icons/charts/icon-charts-hair-care.png',
    "heiße Getränke": '/assets/img/icons/charts/icon-charts-hot-drinks.png',
    Bonbons: '/assets/img/icons/charts/icon-charts-sweets.png'
  };

  ngOnInit(): void {
    this.audience = Math.round(this.audience*100);
    this.datapool = Math.round(this.datapool*100);
  }

}
