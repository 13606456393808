import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportCreateComponent } from './create-tracking-report-entry/report-create.component';
import { ReportsEvaluationComponent } from './evaluation/evaluation.component';
import { ReportsPixelCreateComponent } from './pixel/create/create.component';
import { ReportsPixelEditComponent } from './pixel/edit/edit.component';
import { ReportsComponent } from './reports.component';
import { ConfirmOrderComponent } from './shopping-cart/confirm-order/confirm-order.component';
import { CartItemListComponent } from './shopping-cart/item-list/item-list.component';
import { CartSuccessComponent } from './shopping-cart/success/success.component';
import { UrlEventSuccessComponent } from './url-event/success/success.component';
import { ReportsUrlEventComponent } from './url-event/url-event.component';
import { ReportsPageComponent } from './reports-page/reports-page.component';
import { ChartsViewComponent } from './charts/charts-view/charts-view.component';
import { AuthGuard, JointControllerGuard } from '../auth/guards';

export const reportsRoutes: Routes = [
  {
    path: '',
    component: ReportsComponent,
    data: {
      breadcrumb: 'Reporte'
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ReportsPageComponent,
        data: {
          showBreadcrumb: false
        }
      },
      {
        path: 'create',
        component: ReportCreateComponent,
        canActivate: [JointControllerGuard],
        data: {
          breadcrumb: 'Report erstellen'
        }
      },
      {
        path: 'pixel',
        component: ReportsPixelCreateComponent,
        canActivate: [JointControllerGuard],
        data: {
          breadcrumb: 'Pixel Report'
        }
      },
      {
        path: 'pixel/edit/:id',
        component: ReportsPixelEditComponent,
        data: {
          breadcrumb: 'Report bearbeiten'
        }
      },
      {
        path: 'view/:id',
        component: ChartsViewComponent,
        data: {
          breadcrumb: 'Report anschauen'
        }
      },
      {
        path: 'evaluation',
        component: ReportsEvaluationComponent,
        data: {
          breadcrumb: 'Evaluation'
        }
      },
      {
        path: 'evaluation/edit/:id',
        component: ReportsEvaluationComponent,
        data: {
          breadcrumb: 'Report bearbeiten'
        }
      },
      {
        path: 'create',
        component: ReportCreateComponent,
        canActivate: [JointControllerGuard],
        data: {
          breadcrumb: 'Report erstellen'
        }
      },
      {
        path: 'url-event',
        component: ReportsUrlEventComponent,
        canActivate: [JointControllerGuard],
        data: {
          breadcrumb: 'URL Report'
        }
      },
      {
        path: 'url-event/edit/:id',
        component: ReportsUrlEventComponent,
        data: {
          breadcrumb: 'Report bearbeiten'
        }
      },
      {
        path: 'url-event/success',
        component: UrlEventSuccessComponent
      },
      {
        path: 'cart/items',
        component: CartItemListComponent,
        data: {
          breadcrumb: 'Artikel'
        }
      },
      {
        path: 'cart/order',
        component: ConfirmOrderComponent,
        data: {
          breadcrumb: 'Bestellung'
        }
      },
      {
        path: 'cart/success',
        component: CartSuccessComponent,
        data: {
          breadcrumb: 'Bestätigung'
        }
      }
    ]
  },
  {
    path: 'view',
    component: ReportsComponent,
    children: [
      {
        path: 'snapshot/:snapshotId',
        component: ChartsViewComponent,
        data: {title: 'Geteilter Report', button: 'no-button', sharedChart: true}
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(reportsRoutes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule {
}
