<mat-form-field>
  <input matInput class="ai-timeframe"
         [min]="report.created"
         [max]="now"
         [matDatepicker]="start"
         [(ngModel)]="startDate"
         (dateChange)="onDateChange()"
         placeholder="Startdatum"
         color="primary"
         required [disabled]="disabled">
  <mat-datepicker-toggle class="ai-timeframe" [for]="start" id="startToggle" matSuffix></mat-datepicker-toggle>
  <mat-datepicker #start></mat-datepicker>
</mat-form-field>
<mat-form-field>
  <input matInput class="ai-timeframe"
         [min]="report.created"
         [max]="now"
         [matDatepicker]="end"
         [(ngModel)]="endDate"
         (dateChange)="onDateChange()"
         placeholder="Enddatum"
         color="primary"
         required [disabled]="disabled">
  <mat-datepicker-toggle class="ai-timeframe" [for]="end" id="endToggle" matSuffix></mat-datepicker-toggle>
  <mat-datepicker #end></mat-datepicker>
</mat-form-field>
<button *ngIf="!disabled"
        mat-flat-button
        name="newChartData"
        color="primary"
        (click)="requestNewChartData()"
        [disabled]="isInvalid() || report?.immutable">
  Anwenden
</button>
