<section class="container-fluid">
  <h1>Boostrap Grid</h1>
  <div class="row">
    <div class="col-12 boxy">.col-12</div>
  </div>
  <div class="row">
    <div class="col-6 boxy">.col-6</div>
    <div class="col-6 boxy">.col-6</div>
  </div>
  <div class="row">
    <div class="col-6 boxy">.col-6</div>
    <div class="col-3 boxy">.col-3</div>
    <div class="col-3 boxy">.col-3</div>
  </div>
  <div class="row">
    <div class="col-sm-6 boxy">.col-sm-6</div>
    <div class="col-3 boxy">.col-3</div>
    <div class="col-3 boxy">.col-3</div>
  </div>
</section>

<app-form-container headline="Default form container with a headline and an icon" icon="input">

  <app-form-bulletpoint headline="Default Button" number="1">
    <button mat-flat-button color="primary">primary mat-flat-button</button>
  </app-form-bulletpoint>

  <app-form-bulletpoint number="2" headline="Note Container">
    <app-note header="Simple Note">This is a simple note</app-note>
    <app-note header="Hide Me" [closeable]="true" closeableId="298743569823476582346">This is a hide-able note</app-note>
  </app-form-bulletpoint>

</app-form-container>

