<app-form-container headline="Tracking Pixel Report" svgIcon="emq:report_pixel">
  <form (submit)="onSubmit()" [formGroup]="submitReportForm">
    <article class="centered">
      <section>
        <div class="centered-content">
          <app-form-bulletpoint headline="Report Name eintragen" number="1">
            <div class="form-input-container">
              <mat-form-field>
                <input matInput
                       required
                       #reportTitle
                       [maxlength]="maxLengthName"
                       id="report-title"
                       placeholder="Report"
                       formControlName="label">
                <mat-error>Report Name eintragen</mat-error>
                <mat-hint align="end">{{reportTitle.value.length}} / {{maxLengthName}}</mat-hint>
              </mat-form-field>
            </div>
          </app-form-bulletpoint>

          <app-form-bulletpoint headline="Deine Datenquelle integrieren" number="2">
            <app-pixel-integration [eventId]="reportPixel?.eventId"></app-pixel-integration>
          </app-form-bulletpoint>

          <button type="submit"
                  mat-flat-button
                  color="primary"
                  class="form-submit-button">Report erstellen
          </button>
        </div>
      </section>
    </article>
  </form>
</app-form-container>
