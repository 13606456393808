import { Component } from '@angular/core';
import { ReportsService } from '../../reports/reports.service';

@Component({
  selector: 'app-health',
  template: '<p>Healthy: {{healthy}}</p>'
})
export class HealthComponent {

  healthy = false;

  constructor(private reports: ReportsService) {
    this.healthy = this.is(reports);
  }

  private is(object: any) {
    return object !== null;
  }

}
