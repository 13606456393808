<h1>Live View</h1>
<div class="d-flex py-3" [ngClass]="{'justify-content-center': !small}">
  <div class="counter-container align-items-center d-flex" [ngClass]="small ? 'flex-row' : 'flex-column'">
    <div [class.increment]="ripple" class="counter">
      <svg #svgNumber [attr.viewBox]="viewBoxAttr ? viewBoxAttr : '0 0 100 100'">
        <text #svgText x="50%" y="50%">{{currentEventNumber}}</text>
      </svg>
    </div>
    <div class="counter-caption">
      <h1 class="m-0">Trackbare Events</h1>
      <div>innerhalb der letzten 5 Minuten.</div>
      <div>{{description}}</div>
      <div *ngIf="error" class="ai-text-error">{{error}}</div>
    </div>
  </div>
</div>
