/**
 * Texts for charts.
 */
export const chartsText = {
  legend: {
    yourAudience: '' +
      'Deine Zielgruppe besteht aus eindeutigen, von emetriq BEKANNTER Profilen/Benutzern, ' +
      'die im Datenstrom der Skript-Integration gesammelt wurden. Eindeutige Profile/Benutzer bedeuten, ' +
      'dass jeder Benutzer eines End-Geräts in deiner Audience im angegebenen Zeitraum nur einmal gezählt wird, ' +
      'selbst wenn die Benutzer Seitenaufrufe mehrmals auslösen.',
    dataPool: '' +
      'Innerhalb des emetriq-Datenpools können wir über 90 Millionen aktiver Benutzerprofile kombinieren. ' +
      'Diese Profile werden mit wertvollen Fakten und Absichtsdaten bekannter Werbetreibender, Marktforschungsunternehmen und Vergleichsplattformen mit ' +
      'Cookie-basierten Bewegungsdaten von Premium-Vermarktern angereichert. ' +
      'Vergleiche die Merkmale deiner Audience mit einer repräsentativen deutschen/europäischen Bevölkerung, um wertvolle Erkenntnisse zu gewinnen.',
    reach: '' +
      'Reichweite beschreibt, wie viele Nutzerprofile (Personen) über ein bestimmtes Segment angesprochen werden können. ' +
      'Das heißt, dass die Reichweite verwendet werden kann, um den relativen Einfluss eines Segments zu messen.',
  }
};
