import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Subscription } from 'rxjs';
import { BasicReport, ReportCharts } from '../../reports.interface';
import { ChartsService } from '../charts.service';

@Component({
  selector: 'app-timeframe',
  templateUrl: './timeframe.component.html',
  styleUrls: ['./timeframe.component.scss']
})
export class TimeframeComponent implements OnInit, OnDestroy {
  @Input() report: BasicReport;
  @Input() disabled = false;

  @ViewChild(MatButton, {static: false}) button: MatButton;

  startDate: Date;
  endDate: Date;

  chartsSubscription: Subscription;

  now = new Date();

  isApplyDisabled = false;

  constructor(private chartsService: ChartsService) {
  }

  ngOnInit() {
    this.chartsSubscription = this.chartsService.onReportCharts().subscribe((reportCharts: ReportCharts) => {
      /* istanbul ignore else */
      if (`${this.report.id}` === `${reportCharts.reportId}`) {
        this.startDate = new Date(reportCharts.start);
        this.endDate = new Date(reportCharts.end);
      }
    });
  }

  ngOnDestroy(): void {
    this.chartsSubscription.unsubscribe();
  }

  requestNewChartData() {
    this.isApplyDisabled = true;
    this.chartsService.getChartsWithPolling(this.report.id, this.startDate, this.endDate).then();
  }

  /* istanbul ignore next */
  ripple() {
    if (this.button && this.button.ripple) {
      this.button.ripple.launch(0, 0, {
        color: 'rgb(255, 255, 255, 0.8)',
        centered: true
      });
    }
  }

  isInvalid() {
    if (this.isApplyDisabled === true) {
      return true;
    }

    if (!this.startDate) {
      return true;
    }
    if (!this.endDate) {
      return true;
    }
    return false;
  }

  onDateChange() {
    this.isApplyDisabled = false;
    this.ripple();
  }

}
