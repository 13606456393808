import { EventEmitter, Injectable } from '@angular/core';
import { Notification } from './notification.interface';

@Injectable({providedIn: 'root'})
export class NotificationService {

  private messages = new EventEmitter<Notification>();

  constructor() {
  }

  onMessage() {
    return this.messages;
  }

  sendSuccess(message: string) {
    this.messages.emit({message, success: true});
  }

  sendError(message: string) {
    this.messages.emit({message, success: false});
  }
}
