import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TrackingService } from '../../tracking/tracking.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent {

  constructor(private router: Router,
              private authService: AuthService,
              private trackingService: TrackingService) {
    authService.logout();
    trackingService.event('logout');
    this.router.navigateByUrl('/login');
  }
}
