import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicReport, ReportType, TrackingEventReport } from '../reports.interface';
import { MatSort } from '@angular/material/sort';
import moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { UrlEventReport } from '../url-event/url-event.interface';
import { TrackingService } from '../../tracking/tracking.service';
import { ReportsService } from '../reports.service';
import { finalize } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';

export declare type TrackingReports = TrackingEventReport[] | UrlEventReport[];
export declare type ReportDataStatus = 'zero' | 'paused' | 'available';

@Component({
  selector: 'app-tracking-reports-table',
  templateUrl: './tracking-reports-table.component.html',
  styleUrls: ['./tracking-reports-table.component.scss']
})
export class TrackingReportsTableComponent implements OnInit {
  @Input() reports: Observable<TrackingReports>;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  _filterValue: string;

  readonly TrackingEventType = ReportType.TrackingEvent;
  readonly UrlEventType = ReportType.UrlEvent;
  readonly pageSizeAndDisplayPaginator = 50;
  dataSource: MatTableDataSource<BasicReport>;
  displayedColumns: string[] = ['id', 'label', 'type', 'created', 'quality', 'profiles', 'urls', 'status', 'actions'];
  showDownload = true;

  constructor(private trackingService: TrackingService,
              private reportService: ReportsService) {
  }

  @Input()
  get filterValue(): string {
    return this._filterValue;
  }

  set filterValue(v: string) {
    this._filterValue = v;
    /* istanbul ignore else */
    if (this.dataSource) {
      this.dataSource.filter = v?.trim().toLowerCase();
    }
  }

  get chartsUrl(): string {
    return '/reports/view/';
  }

  ngOnInit(): void {
    this.reports.subscribe((reports) => {
      this.dataSource = new MatTableDataSource<BasicReport>(reports);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  reportStatus(report: BasicReport): ReportDataStatus {
    if (report.uniqueProfileCount <= 0 || report.lastDataChanged == null) {
      return 'zero';
    }
    if (new Date(report.lastDataChanged) < moment().subtract(3, 'days').toDate()) {
      return 'paused';
    }
    return 'available';
  }

  reportStatusText(report: BasicReport): string {
    if (this.reportStatus(report) === 'zero') {
      return 'Zur Zeit sind noch keine Daten verfügbar!';
    }
    if (this.reportStatus(report) === 'paused') {
      return 'Es sind in den letzten Tagen keine neuen Daten hinzugekommen!';
    }
    return 'Daten verfügbar!';
  }

  trackClick(report: BasicReport) {
    this.trackingService.event('openEditReport', {id: report.id});
  }

  trackClickView(report) {
    this.trackingService.event('openReportView', {id: report.id});
  }

  download(report: BasicReport): void {
    this.trackingService.event('downloadReport');
    this.showDownload = false;

    this.reportService.downloadReport(report.id)
      .pipe(
        finalize(() => this.showDownload = true)
      ).subscribe();
  }

  showDownloadButton(report: BasicReport): boolean {
    return this.showDownload && report.dataAvailable;
  }

}
