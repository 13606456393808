import { Injectable } from '@angular/core';

@Injectable()
export class UndoService {

  private undoOperation: () => void = null;

  constructor() {
  }

  register(undo: () => void): void {
    this.undoOperation = undo;
  }

  execute() {
    if (this.undoOperation) {
      this.undoOperation();
      this.undoOperation = null;
    }
  }

  hasUndoAction(): boolean {
    return this.undoOperation !== null;
  }

}
