<h1 class="ai-h1-light ai-page-header pl-4">KPI Dashboard</h1>

<div class="pl-3 pr-3 pb-5">
  <h3 class="ai-h3-dark">Activation</h3>
  <div class="container-fluid">
    <div class="row">
      <div class="col-2">
        <app-kpi-rate
          title="Signup"
          lastPeriodValue="{{kpis?.activation.countSignUpsLastPeriod}}"
          thisPeriodValue="{{kpis?.activation.countSignUpsThisPeriod}}"
          [upliftRate]="kpis?.activation.signUpRateLastWeek"
        ></app-kpi-rate>
      </div>
      <div class="col-2">
        <mat-card>
          <mat-card-header>
            <mat-card-title>Registered Accounts</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <h3 class="ai-h3-dark text-center">{{kpis?.activation.registeredAccountsTotal}}</h3>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<div class="pl-3 pr-3 pb-5">
  <h3 class="ai-h3-dark">Retention</h3>
  <div class="container-fluid">
    <div class="row">
      <div class="col-3 pb-3">
        <app-kpi-rate
          title="Active User"
          lastPeriodValue="{{kpis?.retention.activeUser.countLastPeriod}}"
          thisPeriodValue="{{kpis?.retention.activeUser.countThisPeriod}}"
          [upliftRate]="kpis?.retention.activeUser.rate"
        ></app-kpi-rate>
      </div>
      <div class="col-3 pb-3" *ngFor="let valueCreation of kpis?.retention.valueCreations">
        <app-kpi-rate
          title="Value Creation {{valueCreation.type}}"
          lastPeriodValue="{{valueCreation.countLastPeriod}}"
          thisPeriodValue="{{valueCreation.countThisPeriod}}"
          [upliftRate]="valueCreation.rate"
        ></app-kpi-rate>
      </div>

    </div>
  </div>
</div>

<div class="pl-3 pr-3 pb-5">
  <h3 class="ai-h3-dark">Revenue</h3>
  <div class="container-fluid">
    <div class="row">
      <div class="col-2">
        <app-kpi-rate
          title="Customer Rate"
          lastPeriodValue="{{kpis?.revenue.customerRate.payingCustomersLastPeriod}}/{{kpis?.revenue.customerRate.activeUserLastPeriod}}"
          thisPeriodValue="{{kpis?.revenue.customerRate.payingCustomersThisPeriod}}/{{kpis?.revenue.customerRate.activeUserThisPeriod}}"
          [upliftRate]="kpis?.revenue.customerRate.rate"
        ></app-kpi-rate>
      </div>
      <div class="col-2">
        <app-kpi-rate
          title="Revenue Rate"
          lastPeriodValue="{{kpis?.revenue.revenueRate.revenueLastPeriod}}"
          thisPeriodValue="{{kpis?.revenue.revenueRate.revenueThisPeriod}}"
          [upliftRate]="kpis?.revenue.revenueRate.rate"
        ></app-kpi-rate>
      </div>
    </div>
  </div>
</div>

<div class="pl-3 pr-3 pb-5">
  <h3 class="ai-h3-dark">Unique User Logins</h3>
  <div class="container-fluid">
    <div class="row">
      <div class="col-11">
        <div class="d-flex flex-column">
          <app-chart-segment *ngFor="let uniqueUserLogin of kpis?.uniqueUserLogins"
                             [highlight]="true"
                             [label]="(uniqueUserLogin.period.start | datePipe) + ' - ' + (uniqueUserLogin.period.end| datePipe)"
                             [primaryPercentageLabel]="uniqueUserLogin.countOfUniqueLogins | number"
                             [primaryPercentage]="getPercentage(uniqueUserLogin.countOfUniqueLogins)"
                             color="primary">
          </app-chart-segment>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pl-3 pr-3 pb-5">
  <h3 class="ai-h3-dark">Retention Statistics</h3>
  <app-kpi-table *ngIf="kpis" [retentionStats]="kpis.retentionStats"></app-kpi-table>
</div>


