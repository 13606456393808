import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-arc',
  templateUrl: './svg-arc.component.html',
  styleUrls: ['./svg-arc.component.scss']
})
export class SvgArcComponent implements OnInit {
  @Input() width = 360;
  @Input() height = 360;
  @Input() centre = [180, 180];
  @Input() radii = [180, 180];
  @Input() startAngle = Math.PI;
  @Input() sweepAngle = 2*Math.PI - 0.0001;
  @Input() rotationAngle = 0;

  @Input() fill = 'transparent';
  @Input() fillOpacity = 1;
  @Input() strokeOpacity = 1;
  @Input() strokeWidth = 1;
  @Input() strokeColor = 'black';
  @Input() strokeDashArray = [];
  @Input() strokeLineJoin = 'round';
  @Input() strokeLineCap = 'square';

  @Input() displayValue;

  dAttribute = '';
  dasharray = '';
  viewBox = '0 0 360 360';
  scaleFactor = 0;

  cos = Math.cos;
  sin = Math.sin;
  pi = Math.PI;

  matrixMultiply = (( [[a,b], [c,d]]: number[][], [x,y]: number[]) => [ a * x + b * y, c * x + d * y]);
  rotationMatrix = ((x: number) => [[this.cos(x),-this.sin(x)], [this.sin(x), this.cos(x)]]);
  vectorAdd = (([a1, a2]: number[], [b1, b2]: number[]) => [a1 + b1, a2 + b2]);

  /**
   * returns an SVG path element that represent an ellipse.
   *
   * @param center - center of ellipse
   * @param center.cx - x coordinate of center
   * @param center.cy - y coordinate of center
   * @param radii - major minor radii of ellipse
   * @param radii.rx - major radius of ellipse
   * @param radii.ry - minor radius of ellipse
   * @param startAngle - start angle, in radian.
   * @param sweepAngle - angle to sweep, in radian. positive.
   * @param rotationAngle - rotation on the whole, in radian
   */
  SvgEllipseArc = (([cx,cy]: number[],[rx,ry]: number[], [startAngle, sweepAngle]: number[], rotationAngle: number ) => {
    sweepAngle = sweepAngle % (2*this.pi);
    const rotMatrix = this.rotationMatrix (rotationAngle);
    const [sX, sY] = ( this.vectorAdd ( this.matrixMultiply ( rotMatrix, [rx * this.cos(startAngle), ry * this.sin(startAngle)] ), [cx,cy] ) );
    const [eX, eY] = ( this.vectorAdd ( this.matrixMultiply ( rotMatrix, [rx * this.cos(startAngle+sweepAngle), ry * this.sin(startAngle+sweepAngle)] ), [cx,cy] ) );
    const fA = ( (  sweepAngle > this.pi ) ? 1 : 0 );
    const fS = ( (  sweepAngle > 0 ) ? 1 : 0 );
    const path_2wk2r = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    const pathAttribute = 'M ' + (sX + this.scaleFactor) + ' ' + (sY + this.scaleFactor) + ' A ' +
    [ rx , ry , rotationAngle / (2*this.pi) *360, fA, fS, eX + this.scaleFactor, eY + this.scaleFactor ].join(' ');
    path_2wk2r.setAttribute('d', pathAttribute);
    return pathAttribute;
  });

  ngOnInit() {
    this.width += this.strokeWidth*2;
    this.height += this.strokeWidth*2;

    this.centre = this.centre.map(e => e + this.strokeWidth/2);

    const boxSize = [this.width, this.height];

    this.scaleFactor = this.strokeWidth/2;

    this.viewBox = `0 0 ${boxSize[0]} ${boxSize[1]}`;

    this.dasharray = this.strokeDashArray.join(' ');

    this.dAttribute = this.SvgEllipseArc(this.centre, this.radii, [this.startAngle, this.sweepAngle], this.rotationAngle);
  }
}
