import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LiveEventsService } from './live-events.service';
import { LiveEventsBubbleComponent } from './bubble/live-events-bubble.component';
import { EventSourceClientModule } from '../event-source-client/event-source-client.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    LiveEventsBubbleComponent
  ],
  providers: [
    LiveEventsService
  ],
  imports: [
    CommonModule,
    EventSourceClientModule,
    FormsModule
  ],
  exports: [LiveEventsBubbleComponent]
})
export class LiveEventsModule {
}
