import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../notification/notification.service';
import { TrackingService } from '../../tracking/tracking.service';
import { UrlIntegration } from '../url-select/url-select.interface';
import { UrlSelectService } from '../url-select/url-select.service';
import { UrlEventReport } from './url-event.interface';
import { UrlEventService } from './url-event.service';
import { ClipboardService } from 'ngx-clipboard';
import { UserService } from '../../user/user.service';
import { InsightsScript } from '../../help/resource/insights-script';
import { User } from '../../user/user.interface';

@Component({
  selector: 'app-url-event',
  templateUrl: './url-event.component.html',
  styleUrls: ['./url-event.component.scss']
})
export class ReportsUrlEventComponent implements OnInit {

  readonly maxLengthName = 100;

  urlList: UrlIntegration[];

  isInUpdateMode = false;

  formGroup: FormGroup;

  reportForEdit: UrlEventReport;

  user: User;

  private label = new FormControl('', Validators.required);

  private urls = new FormControl();

  constructor(private service: UrlEventService,
              private notificationService: NotificationService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private urlSelectService: UrlSelectService,
              private userService: UserService,
              private clipBoard: ClipboardService) {
  }

  async ngOnInit() {
    this.buildFormGroup();
    this.subscribeToUrlListFromService();
    await this.applyIfUpdate();

    this.userService.getUser().subscribe(user => {
      this.user = user;
    });

    TrackingService.track('initURlEventReport');
  }

  async applyIfUpdate() {
    // get possible report id from current route
    const id = this.getRouteId();

    /* istanbul ignore else */
    if (id !== null) {

      this.isInUpdateMode = true;

      this.reportForEdit = await this.service.getUrlEventReport(id);

      this.label.patchValue(this.reportForEdit.label);

      this.urlSelectService.update(this.reportForEdit.urls);
    }
  }

  getRouteId() {
    return this.activatedRoute.snapshot.paramMap.get('id');
  }

  onSubmit() {

    if (this.isFormValid()) {

      const report: UrlEventReport = {
        label: this.label.value,
        urls: this.urlList
      };

      if (this.isInUpdateMode) {
        report.id = this.reportForEdit.id;
        this.updateUrlEventReport(report);
      } else {
        this.createUrlEventReport(report);
      }
    }
  }

  updateUrlEventReport(report) {
    this.service.updateUrlEventReport(report)
      .then(() => {
        TrackingService.track('updateUrlEventReport', report);
        this.router.navigateByUrl('/reports/url-event/success');
      })
      .catch(() => {
        TrackingService.track('updateUrlEventReportFail', report);
      });
  }

  createUrlEventReport(report) {
    this.service.createUrlEventReport(report)
      .then(() => {
        TrackingService.track('createUrlEventReport', report);
        this.router.navigateByUrl('/reports/url-event/success');
      })
      .catch(() => {
        TrackingService.track('createUrlEventReportFail', report);
      });
  }

  isFormValid() {
    /* istanbul ignore else */
    if (this.isUrlListInValid()) {
      this.notificationService.sendError('Es muß mindestens eine URL angegeben werden!');
      return false;
    }
    return this.formGroup.valid;
  }

  immutableSelect(): boolean {
    return !!this.reportForEdit && (this.reportForEdit.immutable || this.reportForEdit.classicDeprecated);
  }

  copyScriptToClipboard(): void {
    this.clipBoard.copyFromContent(new InsightsScript(this.user.aiId).asString());
  }

  integrationScript(): string {
    if (this. user && !this.user.hasClassicIntegration) {

      return new InsightsScript(this.user.aiId).asString();
    }

    return '';
  }

  private subscribeToUrlListFromService() {
    this.urlSelectService.create();

    this.urlSelectService.getUrlList().subscribe((list) => {
      this.urlList = list;
    });
  }

  private buildFormGroup() {
    this.formGroup = new FormGroup({
      label: this.label,
      urls: this.urls
    });
  }

  private isUrlListInValid() {
    return this.urlList.length === 0;
  }
}
