import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { EventSourceClient } from '../event-source-client/event-source-client.service';

@Injectable()
export class LiveEventsService {

  constructor(private eventSource: EventSourceClient) {
  }

  eventCountForIntegration(): Observable<number> {
    const url = `${environment.apiBasePath}/live-events/integration`;

    return this.eventSource.get<number>(url);
  }

  eventCountForReport(reportId: string): Observable<number> {
    const url = `${environment.apiBasePath}/live-events/report/${reportId}`;

    return this.eventSource.get<number>(url);
  }
}
