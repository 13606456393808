import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationComponent } from './notification.component';
import { NotificationService } from './notification.service';

@NgModule({
  imports: [CommonModule, MatSnackBarModule],
  declarations: [NotificationComponent],
  exports: [NotificationComponent],
  providers: [NotificationService]
})
export class NotificationModule {
}
