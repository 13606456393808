import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { LayoutFormModule } from '../layout/form/form.module';
import { NoteModule } from '../note/note.module';

export const STYLE_GUIDE_MODULES =
  [
    MatButtonModule,
    NoteModule,
    LayoutFormModule
  ];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    STYLE_GUIDE_MODULES
  ]
})
export class StyleGuideModule {
}
