import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SegmentInfo } from '../../reports.interface';
import { chartsText } from '../charts.texts';
import { ChartConfiguration, Chart } from 'chart.js';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {

  @Input() data;

    dimensions = {
        width: 400,
        height: 400
    };

    public chartOptions: ChartConfiguration<'bar'>['options'] = {
        responsive: true
    };
    public chartLabels: string[] = [];

    public chartDatasets: ChartConfiguration<'bar'>['data']['datasets'] = [];

    plugin = {
        id: 'customHover',
        afterEvent: (chart, event, opts) => {
            const evt = event.event;

            if (evt.type !== 'click') {
                return;
            }

            const [found, labelInfo] = this.findLabel(this.getLabelHitboxes(chart.scales), evt);

            if (found) {
                this.currentDescription = this.labelDescriptions;
            }

        }
    };

    labelDescriptions: {
        label: string;
    }[] = [];

    currentDescription: any = '';

    findLabel(labels, evt) {
        let found = false;
        let res = null;

        labels.forEach(l => {
            l.labels.forEach((label, index) => {
            if (evt.x > label.x && evt.x < label.x2 && evt.y > label.y && evt.y < label.y2) {
                res = {
                    label: label.label,
                    index
                };
                found = true;
            }
            });
        });

        return [found, res];
    };

    getLabelHitboxes(scales) {
        return (Object.values(scales).map((s: any) => ({
            scaleId: s.id,
            labels: s._labelItems.map((e, i) => ({
                x: e.options.translation[0] - s._labelSizes.widths[i],
                x2: e.options.translation[0] + s._labelSizes.widths[i] / 2,
                y: e.options.translation[1] - s._labelSizes.heights[i] / 2,
                y2: e.options.translation[1] + s._labelSizes.heights[i] / 2,
                label: e.label,
                index: i
            }))
        })));
    }



    ngOnInit() {
        Chart.register(this.plugin);

        this.chartLabels = this.data.map(d => d.label);

        //console.log("data in bar chart before formatting", this.data);
        const audience = this.data.map(e => Math.round(e.userProfilesPercent*100));
        //console.log("data in bar chart after formatting", audience);

        const dataPool = this.data.map(e => Math.round(e.dataPoolProfilesPercent*100));

        this.chartDatasets.push({
            label: 'Deine Audience',
            data: audience,
            backgroundColor: '#00748b'
        });

        this.chartDatasets.push({
            label: 'Datenpool',
            data: dataPool,
            backgroundColor: '#66696a'
        });

        const dimensionFactor = 30*(this.chartLabels.length - 5);
        this.dimensions.width += dimensionFactor;
        this.dimensions.height += dimensionFactor;


    }

}
