import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() displaySimplePage = false;
  @Input() simplePageTitle = '';

  constructor(private authService: AuthService) {
  }

  isAuthenticated() {
    return this.authService.isUserAuthenticated();
  }
}
