import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { v4 as uuid } from 'uuid';
import { AuthService } from '../auth/auth.service';
import { ITracking } from './tracking.interface';
import { Md5 } from 'ts-md5/dist/md5';

const NOOP = () => {
};

const trackUser = () =>
  // @ts-ignore
  window.trackUser || NOOP
;

const trackEvent = () =>
  // @ts-ignore
  window.trackEvent || NOOP
;

interface Session {
  id: string;
  start: Date;
}

@Injectable()
export class TrackingService {

  protected static _me: TrackingService;

  public uuidFunc = uuid;

  session: Session = {id: uuid(), start: new Date()};

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private auth: AuthService
  ) {

    TrackingService._me = this;

    /* istanbul ignore next */
    if (this.auth.getCognitoUser()) {
      const username = this.auth.getCognitoUser().getUsername();
      const hash = Md5.hashStr(username);
      // 3rd party tracker
      trackUser()(hash);
    }

    this.trackRouting(router);
  }

  public static track(eventName: string, data?: any): void {
    /* istanbul ignore next */
    if (!TrackingService._me) {
      return;
    }
    TrackingService._me.event(eventName, data);
  }

  trackRouting(router: Router) {
    if (router.events) {
      router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          this.event('routing', event.url);
        }
      });
    }
  }

  event(eventName: string, data?: any) {

    const metricEvent: ITracking = this.getTrackingEvent(eventName);

    if (data) {
      /* istanbul ignore else */
      if (typeof data !== 'object') {
        data = {value: data};
      }
      metricEvent.data = data;
    }

    // 3rd party tracker
    trackEvent()(eventName, data);

    // stop sending data to open search via serverless api gateway
    //
    // this.httpClient.post(`${environment.trackingBasePath}/`, metricEvent).subscribe(
    //   (success) => {
    //   },
    //   /* istanbul ignore next */
    //   (error) => {
    //     /* istanbul ignore next */
    //     if (environment.stage !== 'prod') {
    //       console.log('Failed tracking', 'Event:', eventName, metricEvent, 'Error:', error);
    //     }
    //   }
    // );

  }

  getDate(): Date {
    return new Date();
  }

  getUserId(): string {
    return this.auth.getCognitoUser() ? this.auth.getCognitoUser().getUsername() : '';
  }

  getUrl(): string {
    return this.router.url;
  }

  getTrackingEvent(eventName: string): ITracking {
    return {
      eventName,
      eventTime: this.getDate().toISOString(),
      sessionId: this.getTrackingSessionId(),
      url: this.getUrl(),
      userId: this.getUserId(),
      sessionAge: this.getSessionAge(),
      screen: {width: screen.width, height: screen.height},
      window: {width: window.innerWidth, height: window.innerHeight}
    };
  }

  getTrackingSessionId(): string {
    return this.session.id;
  }

  /**
   * age in seconds....
   *
   * @param now
   */
  getSessionAge(now = new Date()): number {
    return Math.round((now.getTime() - this.session.start.getTime()) / 1000);
  }

}
