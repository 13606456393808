import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TrackingService } from '../../../../tracking/tracking.service';
import { BasicReport, ReportCharts } from '../../../reports.interface';
import { ScrollService } from '../../../../scroll/scroll.service';
import { BannerLevel } from '../../../../banner/banner.interface';
import { ClipboardService } from 'ngx-clipboard';
import { BannerService } from '../../../../banner/banner.service';
import { ChartsService } from '../../charts.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss']
})
export class ShareButtonComponent implements OnInit, OnDestroy {

  @Input() reportsCharts: ReportCharts | undefined;
  @Input() report: BasicReport | undefined;

  subscriptions = new Subscription();

  constructor(private trackingService: TrackingService,
              private scrollTop: ScrollService,
              private chartsService: ChartsService,
              private clipBoard: ClipboardService,
              private banner: BannerService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  shareSnapshot() {
    this.trackingService.event('shareSnapshot');
    this.scrollTop.scrollAction();

    if (this.reportsCharts) {
      this.subscriptions.add(this.chartsService
        .createSnapshot(this.report.id, new Date(this.reportsCharts.start), new Date(this.reportsCharts.end))
        .subscribe(
          (id: string) => this.notifySharedSuccess(id),
          (error) => {
            console.error(error);
            this.notifySharedFailed();
          }));
    } else {
      console.log('No selected!!!');
    }
  }

  notifySharedSuccess(id: string) {
    const address = `${window.location.protocol}//${window.location.host}/reports/view/snapshot/${id}`;

    this.banner.notify({
      closeable: true,
      flexDirection: 'row',
      message: {
        major: 'Link wurde erstellt:',
        minor: address
      },
      actions: [{
        displayName: 'Kopiere',
        actionFn: this.notifySharedSuccessButtonAction(address)
      }]
    });
  }

  notifySharedFailed() {
    this.banner.notify({
      closeable: true,
      flexDirection: 'row',
      message: {
        icon: 'warning',
        minor: 'Link wurde nicht kopiert. Bitte versuche es später erneut.'
      },
      actions: [{
        displayName: 'Wiederholen',
        actionFn: () => this.shareSnapshot()
      }],
      level: BannerLevel.WARN
    });
  }

  notifySharedCopied(address: string) {
    this.banner.notify({
      closeable: true,
      flexDirection: 'row',
      message: {
        icon: 'done_all',
        major: 'Link wurde kopiert:',
        minor: address
      },
      actions: [{
        displayName: 'Kopiert',
        actionFn: this.notifySharedSuccessButtonAction(address)
      }]
    });
  }

  notifySharedSuccessButtonAction(address: string) {
    return () => {
      this.clipBoard.copyFromContent(address);
      this.notifySharedCopied(address);
    };
  }
}
