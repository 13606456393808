import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingIndicatorComponent } from './loading-indicator.component';

@Injectable()
export class LoadingIndicatorService {

  constructor(private dialog: MatDialog) {
  }

  displayLoading(text: string): Closeable {
    const config = {disableClose: true, data: text};
    const matDialogRef = this.dialog.open(LoadingIndicatorComponent, config);
    return {
      close: () => {
        matDialogRef.close();
      }
    };
  }

}

export interface Closeable {
  close: () => void;
}
