<button mat-icon-button
        matTooltip="Report editieren"
        matTooltipPosition="left"
        name="editReport"
        class="ai-action-button"
        color="primary"
        (click)="edit()"
        *ngIf="!isSnapshotView && canEdit()">
  <mat-icon class="mat-18">edit</mat-icon>
</button>
