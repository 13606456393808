<div class="container-fluid">
    <div class="row">
      <div class="col-10">
  
        <canvas baseChart
          [datasets]="chartDatasets"
          [options]="chartOptions"
          [labels]="chartLabels"
          [type]="'bar'">
        </canvas>
        <p>{{ currentDescription }}</p>
      </div>
    </div>
  </div>  