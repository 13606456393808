import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import * as Icons from './icons.list';

export const EMQ_ICONS = [
  {name: 'automotive', url: Icons.AssetIconChartsAutomotive},
  {name: 'bargain_hunter', url: Icons.AssetIconChartsBargainHunter},
  {name: 'beauty', url: Icons.AssetIconChartsBeauty},
  {name: 'books', url: Icons.AssetIconChartsBooks},
  {name: 'charity', url: Icons.AssetIconChartsCharity},
  {name: 'corona', url: Icons.AssetIconChartsCorona},
  {name: 'diy', url: Icons.AssetIconChartsDiy},
  {name: 'entertainment', url: Icons.AssetIconChartsEntertainment},
  {name: 'fashion', url: Icons.AssetIconChartsFashion},
  {name: 'financials', url: Icons.AssetIconChartsFinancials},
  {name: 'fitness', url: Icons.AssetIconChartsFitness},
  {name: 'food_and_drinks', url: Icons.AssetIconChartsFoodAndDrinks},
  {name: 'gaming', url: Icons.AssetIconChartsGaming},
  {name: 'health', url: Icons.AssetIconChartsHealth},
  {name: 'home_and_garden', url: Icons.AssetIconChartsHomeAndGarden},
  {name: 'insurance', url: Icons.AssetIconChartsInsurance},
  {name: 'lohas', url: Icons.AssetIconChartsLohas},
  {name: 'lottery', url: Icons.AssetIconChartsLottery},
  {name: 'organic', url: Icons.AssetIconChartsOrganic},
  {name: 'sports', url: Icons.AssetIconChartsSports},
  {name: 'telecommunication', url: Icons.AssetIconChartsTelecommunication},
  {name: 'toys', url: Icons.AssetIconChartsToys},
  {name: 'wellness', url: Icons.AssetIconChartsWellness},
  {name: 'account_box', url: Icons.AssetIconUiAccountBox},
  {name: 'account_menu', url: Icons.AssetIconUiAccountMenu},
  {name: 'account', url: Icons.AssetIconUiAccount},
  {name: 'add_audience', url: Icons.AssetIconUiAddAudience},
  {name: 'arrow_back_ios', url: Icons.AssetIconUiArrowBackIos},
  {name: 'arrow_back', url: Icons.AssetIconUiArrowBack},
  {name: 'arrow_forward_ios', url: Icons.AssetIconUiArrowForwardIos},
  {name: 'arrow_forward', url: Icons.AssetIconUiArrowForward},
  {name: 'audience', url: Icons.AssetIconUiAudience},
  {name: 'cancel', url: Icons.AssetIconUiCancel},
  {name: 'characteristics', url: Icons.AssetIconUiCharacteristics},
  {name: 'chevron_left', url: Icons.AssetIconUiChevronLeft},
  {name: 'chevron_right', url: Icons.AssetIconUiChevronRight},
  {name: 'clear', url: Icons.AssetIconUiClear},
  {name: 'close', url: Icons.AssetIconUiClose},
  {name: 'cloud_done', url: Icons.AssetIconUiCloudDone},
  {name: 'cloud_download', url: Icons.AssetIconUiCloudDownload},
  {name: 'consent_shield', url: Icons.AssetIconUiConsentShield},
  {name: 'create', url: Icons.AssetIconUiCreate},
  {name: 'diamond', url: Icons.AssetIconUiDiamond},
  {name: 'domain', url: Icons.AssetIconUiDomain},
  {name: 'edit', url: Icons.AssetIconUiEdit},
  {name: 'evaluation_box', url: Icons.AssetIconUiEvaluationBox},
  {name: 'evaluation', url: Icons.AssetIconUiEvaluation},
  {name: 'female', url: Icons.AssetIconUiFemale},
  {name: 'group_add', url: Icons.AssetIconUiGroupAdd},
  {name: 'imprint', url: Icons.AssetIconUiImprint},
  {name: 'male', url: Icons.AssetIconUiMale},
  {name: 'menu', url: Icons.AssetIconUiMenu},
  {name: 'notes', url: Icons.AssetIconUiNotes},
  {name: 'people', url: Icons.AssetIconUiPeople},
  {name: 'pie_chart', url: Icons.AssetIconUiPieChart},
  {name: 'privacy', url: Icons.AssetIconUiPrivacy},
  {name: 'refresh', url: Icons.AssetIconUiRefresh},
  {name: 'report_evaluation', url: Icons.AssetIconUiReportEvaluation},
  {name: 'report_pixel', url: Icons.AssetIconUiReportPixel},
  {name: 'report_url', url: Icons.AssetIconUiReportUrl},
  {name: 'report', url: Icons.AssetIconUiReport},
  {name: 'reports', url: Icons.AssetIconUiReports},
  {name: 'security', url: Icons.AssetIconUiSecurity},
  {name: 'terms', url: Icons.AssetIconUiTerms},
  {name: 'tracking_box', url: Icons.AssetIconUiTrackingBox},
  {name: 'tracking', url: Icons.AssetIconUiTracking}
];

const NAMESPACE = 'emq';

export const registerIcons = (iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) => () => {
  EMQ_ICONS.forEach((icon) => {
    const safeResourceUrl = domSanitizer.bypassSecurityTrustResourceUrl(icon.url);
    iconRegistry.addSvgIconInNamespace(NAMESPACE, icon.name, safeResourceUrl);
  });
};
