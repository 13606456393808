<app-form-container>

  <section class="header">
    <h2 *ngIf="isTracking() || isEvaluation()">Report kaufen</h2>
    <h2 *ngIf="isBusinessUpgrade()">Abonnement</h2>
  </section>

  <section class="content">
    <div class="item" *ngIf="isTracking()">

      <app-shopping-cart-item
        icon="reports"
        description="Kann für Tracking URL Reporte und Tracking Pixel Reporte genutzt werden"
        name="Tracking Report"
        [price]="order?.priceConfig?.priceNet"
        [quantity]="order?.quantity"
      ></app-shopping-cart-item>
    </div>

    <div class="item" *ngIf="isEvaluation()">

      <app-shopping-cart-item
        icon="reports"
        name="Audience Insights Evaluation Report"
        description="Kann für Evaluation Reporte genutzt werden"
        [price]="order?.priceConfig?.priceNet"
        [quantity]="order?.quantity"
      ></app-shopping-cart-item>
    </div>


    <div class="item" *ngIf="isBusinessUpgrade()">

      <app-shopping-cart-item
        icon="reports"
        [description]="order?.priceConfig?.description || 'Monats Abonnement'"
        [name]="order?.priceConfig?.name"
        [price]="order?.priceConfig?.priceNet"
        [isSubscription]="true"
        [quantity]="1"
      ></app-shopping-cart-item>
    </div>

    <mat-divider [inset]="true"></mat-divider>

  </section>
  <section class="buttons">
    <div class="form-input-container">
      <p class="subtotal">Zwischensumme: <strong>{{order?.displayTotals?.subtotal}} €</strong></p>
      <a [queryParams]="{type: reportType}" color="primary" mat-stroked-button routerLink="/reports">Zurück</a>
      <a class="right-button" color="primary" mat-flat-button routerLink="/reports/cart/order">zur Kasse</a>
    </div>
  </section>
</app-form-container>
