import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../../user/user.interface';
import { PlanType } from '../../user/user-administration.service';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  currentUser: User;

  constructor(private httpClient: HttpClient) {
  }

  getPlanForUser(): Observable<UserPlan> {
    return this.httpClient.get<UserPlan>(`${environment.apiBasePath}/plan`);
  }
}

export interface UserPlan {
  planConfig: PlanConfig;
  availableReports: AvailableReports;
  enterpriseContractEndDay?: string;
  upgradePossibleTo: PlanType[];
}

export interface PlanConfig {
  planType: PlanType;
  limitOfEvaluationReports?: number;
  limitOfTrackingReports?: number;
  limitOfLookALikes?: number;
  hasUnlimitedReports?: boolean;
}

export interface AvailableReports {
  availableTrackingReports?: number;
  availableEvaluationReports?: number;
  availableLookalikeOrders?: number;
}
