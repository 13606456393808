import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { BannerService } from '../../banner/banner.service';
import { PlanType } from '../../user/user-administration.service';
import { noReportsLeftNotification } from '../plan/plan.notification';
import { PlanService } from '../plan/plan.service';
import { ReportDisplayType } from '../reports.interface';
import { UserService } from '../../user/user.service';
import { User } from '../../user/user.interface';

@Component({
  selector: 'app-create',
  templateUrl: './report-create.component.html',
  styleUrls: ['./report-create.component.scss']
})
export class ReportCreateComponent implements OnInit {

  hasTrackingReportsLeft = false;

  user: User;

  constructor(public auth: AuthService,
              private userService: UserService,
              private banner: BannerService,
              private planService: PlanService,
              private router: Router) {
  }

  ngOnInit() {
    this.auth.refreshSession();

    this.planService.getPlanForUser().subscribe((plan) => {
      this.hasTrackingReportsLeft = plan.availableReports.availableTrackingReports > 0;
      this.checkPlan();
    });

    this.userService.getUser().subscribe(user => this.user = user);
  }

  checkPlan() {
    if (!this.hasTrackingReportsLeft) {
      this.handleNoReportsLeft();
    }
  }

  handleNoReportsLeft() {
    /* istanbul ignore next */
    this.banner.notify(noReportsLeftNotification(
      () => {
        this.router.navigateByUrl('reports/cart/items?reportType=' + ReportDisplayType.Tracking);
      },
      () => {
        this.router.navigateByUrl('reports/cart/items?planType=' + PlanType.Business);
      }
    ));
  }

  hasClassicIntegration(): boolean {
    return this.user && this.user.hasClassicIntegration;
  }

  /**
   * display "create url report" or "contact page".
   *
   * classic users must have sids to create url reports.
   */
  contactEmetriq(): boolean {
    if (this.hasClassicIntegration()) {
      const sids = this.user.sids || [];
      return sids.length === 0;
    }
    return false;
  }

  canCreateTrackingReports(): boolean {
    return this.hasTrackingReportsLeft;
  }

}
