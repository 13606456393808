import { Component, Input, OnInit } from '@angular/core';
import { SegmentsService } from '../../../segments/segments.service';
import { BasicReport, ReportType, SegmentEvaluation } from '../../reports.interface';
import { UrlEventReport } from '../../url-event/url-event.interface';

@Component({
  selector: 'app-reports-source',
  templateUrl: './report-source.component.html',
  styleUrls: ['./report-source.component.scss']
})
export class ReportSourceComponent implements OnInit {

  @Input() report: UrlEventReport | SegmentEvaluation | BasicReport;

  urlToShow = 2;

  urlListExpanded = false;

  constructor(private segments: SegmentsService) {
  }

  ngOnInit() {
  }

  showReportSource(): boolean {
    return this.report && this.report.type !== ReportType.TrackingEvent;
  }

  hasUrls(): boolean {
    return ((this.report as UrlEventReport).urls || []).length > 0;
  }

  getUrls(): string[] {
    return this.hasUrls() ? (this.report as UrlEventReport).urls.map((it) => it.url) : [];
  }

}
