<div class="row">

  <div class="col-6 d-flex flex-column">
    <div class="footer-logo-container"></div>
    <strong>emetriq GmbH</strong><br/>
    <p>Vorsetzen 35<br/>
      20459 Hamburg</p>
    <p>t +49 40 80813-7100<br/>
      f +49 40 80813-7199<br/>
      <a href="mailto:info@emetriq.com">info@emetriq.com</a></p>
  </div>

  <div class="col-6 d-flex justify-content-end align-items-end">
    <ul class="horizontal-list">
      <li><a routerLink="legal/terms-and-conditions">AGBs</a></li>
      <li><a routerLink="legal/privacy">Datenschutz</a></li>
      <li><a routerLink="legal/imprint">Impressum</a></li>
    </ul>
  </div>

</div>
