<div class="finish">
  <section class="finish-document" (click)="downloadPdf()">
    <div class="content">
      <mat-icon class="mat-icon-xl" aria-hidden="false">cloud_download</mat-icon>
      <span>Download</span>
    </div>
  </section>
  <section class="finish-spacer"></section>
  <section class="finish-text-container">
    <h2 class="dark">Download Joint Controller</h2>
    <p>Vielen Dank, dass Du dir Zeit genommen hast!</p>
    <div class="start-button">
      <a routerLink="/reports/create" mat-flat-button color="primary">Start Audience Insights</a>
    </div>
  </section>
</div>

