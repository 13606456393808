import { NgModule } from '@angular/core';
import { CountrySelectComponent } from './country-select.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SharedModule } from '../modules/shared.module';
import { LayoutFormModule } from '../layout/form/form.module';

@NgModule({
  declarations: [CountrySelectComponent],
  imports: [
    SharedModule,
    LayoutFormModule,
    MatAutocompleteModule
  ],
  providers: [],
  exports: [
    CountrySelectComponent
  ]
})
export class CountrySelectModule {
}
