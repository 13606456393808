
export const AssetIconChartsAutomotive = 'assets/img/icons/charts/icon-charts-automotive.svg';
export const AssetIconChartsBargainHunter = 'assets/img/icons/charts/icon-charts-bargain-hunter.svg';
export const AssetIconChartsBeauty = 'assets/img/icons/charts/icon-charts-beauty.svg';
export const AssetIconChartsBodyCareProducts = 'assets/img/icons/charts/icon-charts-body-care-products.png';
export const AssetIconChartsBooks = 'assets/img/icons/charts/icon-charts-books.svg';
export const AssetIconChartsBreakfast = 'assets/img/icons/charts/icon-charts-breakfast.png';
export const AssetIconChartsCharity = 'assets/img/icons/charts/icon-charts-charity.svg';
export const AssetIconChartsCheese = 'assets/img/icons/charts/icon-charts-cheese.png';
export const AssetIconChartsChipsSticks = 'assets/img/icons/charts/icon-charts-chips-sticks.png';
export const AssetIconChartsCleaningProducts = 'assets/img/icons/charts/icon-charts-cleaning-products.png';
export const AssetIconChartsCorona = 'assets/img/icons/charts/icon-charts-corona.svg';
export const AssetIconChartsDiy = 'assets/img/icons/charts/icon-charts-diy.svg';
export const AssetIconChartsEntertainment = 'assets/img/icons/charts/icon-charts-entertainment.svg';
export const AssetIconChartsFashion = 'assets/img/icons/charts/icon-charts-fashion.svg';
export const AssetIconChartsFinancials = 'assets/img/icons/charts/icon-charts-financials.svg';
export const AssetIconChartsFish = 'assets/img/icons/charts/icon-charts-fish.png';
export const AssetIconChartsFitness = 'assets/img/icons/charts/icon-charts-fitness.svg';
export const AssetIconChartsFoodAndDrinks = 'assets/img/icons/charts/icon-charts-food-and-drinks.svg';
export const AssetIconChartsGaming = 'assets/img/icons/charts/icon-charts-gaming.svg';
export const AssetIconChartsHairCare = 'assets/img/icons/charts/icon-charts-hair-care.png';
export const AssetIconChartsHealth = 'assets/img/icons/charts/icon-charts-health.svg';
export const AssetIconChartsHomeAndGarden = 'assets/img/icons/charts/icon-charts-home-and-garden.svg';
export const AssetIconChartsHotDrinks = 'assets/img/icons/charts/icon-charts-hot-drinks.png';
export const AssetIconChartsIconChartsBathProducts = 'assets/img/icons/charts/icon-charts-icon-charts-bath-products.png';
export const AssetIconChartsIconChartsBeer = 'assets/img/icons/charts/icon-charts-icon-charts-beer.png';
export const AssetIconChartsInsurance = 'assets/img/icons/charts/icon-charts-insurance.svg';
export const AssetIconChartsJam = 'assets/img/icons/charts/icon-charts-jam.png';
export const AssetIconChartsJuice = 'assets/img/icons/charts/icon-charts-juice.png';
export const AssetIconChartsKitchenProducts = 'assets/img/icons/charts/icon-charts-kitchen-products.png';
export const AssetIconChartsLaundry = 'assets/img/icons/charts/icon-charts-laundry.png';
export const AssetIconChartsLohas = 'assets/img/icons/charts/icon-charts-lohas.svg';
export const AssetIconChartsLollies = 'assets/img/icons/charts/icon-charts-lollies.png';
export const AssetIconChartsLottery = 'assets/img/icons/charts/icon-charts-lottery.svg';
export const AssetIconChartsMeat = 'assets/img/icons/charts/icon-charts-meat.png';
export const AssetIconChartsMilk = 'assets/img/icons/charts/icon-charts-milk.png';
export const AssetIconChartsMouthCare = 'assets/img/icons/charts/icon-charts-mouth-care.png';
export const AssetIconChartsOrganic = 'assets/img/icons/charts/icon-charts-organic.svg';
export const AssetIconChartsPetFood = 'assets/img/icons/charts/icon-charts-pet-food.png';
export const AssetIconChartsSoftDrinks = 'assets/img/icons/charts/icon-charts-soft-drinks.png';
export const AssetIconChartsSoup = 'assets/img/icons/charts/icon-charts-soup.png';
export const AssetIconChartsSpirits = 'assets/img/icons/charts/icon-charts-spirits.png';
export const AssetIconChartsSports = 'assets/img/icons/charts/icon-charts-sports.svg';
export const AssetIconChartsStapleFood = 'assets/img/icons/charts/icon-charts-staple-food.png';
export const AssetIconChartsSweets = 'assets/img/icons/charts/icon-charts-sweets.png';
export const AssetIconChartsTelecommunication = 'assets/img/icons/charts/icon-charts-telecommunication.svg';
export const AssetIconChartsToys = 'assets/img/icons/charts/icon-charts-toys.svg';
export const AssetIconChartsWater = 'assets/img/icons/charts/icon-charts-water.png';
export const AssetIconChartsWellness = 'assets/img/icons/charts/icon-charts-wellness.svg';
export const AssetIconChartsWine = 'assets/img/icons/charts/icon-charts-wine.png';
export const AssetIconUiAccountBox = 'assets/img/icons/ui/icon-ui-account-box.svg';
export const AssetIconUiAccountMenu = 'assets/img/icons/ui/icon-ui-account-menu.svg';
export const AssetIconUiAccount = 'assets/img/icons/ui/icon-ui-account.svg';
export const AssetIconUiAddAudience = 'assets/img/icons/ui/icon-ui-add-audience.svg';
export const AssetIconUiArrowBackIos = 'assets/img/icons/ui/icon-ui-arrow-back-ios.svg';
export const AssetIconUiArrowBack = 'assets/img/icons/ui/icon-ui-arrow-back.svg';
export const AssetIconUiArrowForwardIos = 'assets/img/icons/ui/icon-ui-arrow-forward-ios.svg';
export const AssetIconUiArrowForward = 'assets/img/icons/ui/icon-ui-arrow-forward.svg';
export const AssetIconUiAudience = 'assets/img/icons/ui/icon-ui-audience.svg';
export const AssetIconUiCancel = 'assets/img/icons/ui/icon-ui-cancel.svg';
export const AssetIconUiCharacteristics = 'assets/img/icons/ui/icon-ui-characteristics.svg';
export const AssetIconUiChevronLeft = 'assets/img/icons/ui/icon-ui-chevron-left.svg';
export const AssetIconUiChevronRight = 'assets/img/icons/ui/icon-ui-chevron-right.svg';
export const AssetIconUiClear = 'assets/img/icons/ui/icon-ui-clear.svg';
export const AssetIconUiClose = 'assets/img/icons/ui/icon-ui-close.svg';
export const AssetIconUiCloudDone = 'assets/img/icons/ui/icon-ui-cloud-done.svg';
export const AssetIconUiCloudDownload = 'assets/img/icons/ui/icon-ui-cloud-download.svg';
export const AssetIconUiConsentShield = 'assets/img/icons/ui/icon-ui-consent-shield.svg';
export const AssetIconUiCreate = 'assets/img/icons/ui/icon-ui-create.svg';
export const AssetIconUiDiamond = 'assets/img/icons/ui/icon-ui-diamond.svg';
export const AssetIconUiDomain = 'assets/img/icons/ui/icon-ui-domain.svg';
export const AssetIconUiEdit = 'assets/img/icons/ui/icon-ui-edit.svg';
export const AssetIconUiEvaluationBox = 'assets/img/icons/ui/icon-ui-evaluation-box.svg';
export const AssetIconUiEvaluation = 'assets/img/icons/ui/icon-ui-evaluation.svg';
export const AssetIconUiFemale = 'assets/img/icons/ui/icon-ui-female.svg';
export const AssetIconUiGroupAdd = 'assets/img/icons/ui/icon-ui-group-add.svg';
export const AssetIconUiImprint = 'assets/img/icons/ui/icon-ui-imprint.svg';
export const AssetIconUiMale = 'assets/img/icons/ui/icon-ui-male.svg';
export const AssetIconUiMenu = 'assets/img/icons/ui/icon-ui-menu.svg';
export const AssetIconUiNotes = 'assets/img/icons/ui/icon-ui-notes.svg';
export const AssetIconUiPeople = 'assets/img/icons/ui/icon-ui-people.svg';
export const AssetIconUiPieChart = 'assets/img/icons/ui/icon-ui-pie-chart.svg';
export const AssetIconUiPrivacy = 'assets/img/icons/ui/icon-ui-privacy.svg';
export const AssetIconUiRefresh = 'assets/img/icons/ui/icon-ui-refresh.svg';
export const AssetIconUiReportEvaluation = 'assets/img/icons/ui/icon-ui-report-evaluation.svg';
export const AssetIconUiReportPixel = 'assets/img/icons/ui/icon-ui-report-pixel.svg';
export const AssetIconUiReportUrl = 'assets/img/icons/ui/icon-ui-report-url.svg';
export const AssetIconUiReport = 'assets/img/icons/ui/icon-ui-report.svg';
export const AssetIconUiReports = 'assets/img/icons/ui/icon-ui-reports.svg';
export const AssetIconUiSecurity = 'assets/img/icons/ui/icon-ui-security.svg';
export const AssetIconUiTerms = 'assets/img/icons/ui/icon-ui-terms.svg';
export const AssetIconUiTrackingBox = 'assets/img/icons/ui/icon-ui-tracking-box.svg';
export const AssetIconUiTracking = 'assets/img/icons/ui/icon-ui-tracking.svg';
export const AssetIconUiNewIcon = 'assets/img/icons/ui/new_icon.svg';

