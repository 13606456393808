<app-form-container headline="Lade neue Enterprise Benutzer ein" icon="person_add">
  <article class="centered">
    <section>
      <div class="centered-content">
        <app-note>
          <strong>Enterprise Benutzer</strong> können sich nicht eigenständig registrieren und müssen von einem "Admin
          Benutzer" eingeladen werden.
        </app-note>
        <a color="primary" mat-flat-button routerLink="invite">Enterprise Benutzer einladen</a>
      </div>
    </section>
  </article>
</app-form-container>
<app-form-container headline="Existierende Benutzer verwalten" icon="person_add">
  <article class="centered">
    <section id="user-table">
      <app-management></app-management>
    </section>
  </article>
</app-form-container>
