/* istanbul ignore file */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserManagerGuard } from '../auth/guards';
import { KpiViewComponent } from './kpi-view/kpi-view.component';

export const kpiRoutes: Routes = [
  {path: '', component: KpiViewComponent, canActivate: [UserManagerGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(kpiRoutes)],
  exports: [RouterModule]
})
export class KpiRoutingModule {
}
