<h1>Audience Insights Persona</h1>
<div class="persona-container" (click)="clickPersona()">
  <div class="persona-image-container persona-mixed">
    <div *ngFor="let genders of getImageClasses()" class="ages-flex">
      <div *ngFor="let classNames of genders" [ngClass]="classNames"></div>
    </div>
  </div>
  <div class="persona-caption">
    <p>{{getText()}}</p>
  </div>
</div>
