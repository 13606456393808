<div id="add-url-info">
  <app-note [closeable]="true"
            closeableId="url-select-info-note"
            [closed]="true"
            closedIcon="info"
            cssInfoButtonPosition="absolute"
            cssInfoButtonTop="-60px"
            cssInfoButtonRight="-10px">

    <p>
      <strong>Unterstützte URL Beispiele</strong>
    </p>

    <ul>
      <li matTooltip="Stimmt genau überein mit https://www.example.com" matTooltipPosition="left">
        https://www.example.com
      </li>
      <li matTooltip="Stimmt überein mit https://www.example.com und allen Unterseiten" matTooltipPosition="left">
        https://www.example.com/<strong>*</strong></li>
      <li>https://example.com</li>
      <li>https://interesting.example.com</li>
      <li>https://www.example.com/interesting/detail</li>
      <li>https://www.example.com/interesting/<strong>*</strong></li>
      <li>https://www.example.com/detail.html</li>
      <li>https://www.example.com/interesting/detail.html</li>
    </ul>

    <p>
      <strong>Nicht unterstützte URL Beispiele</strong>
    </p>

    Parametern oder Ankerlinks werden aus der URL entfernt.

    <ul>
      <li>https://www.example.de<strong>?parameter=value</strong></li>
      <li>https://www.example.de<strong>#absatz</strong></li>
    </ul>

    <!--The websites based on the given URLs must contain an <code>emetriq profiling script</code>.-->
    <!--The websites have to be owned by the users company or are manageable by the users company.-->
  </app-note>
</div>

<div class="url-input-wrapper p-3 d-flex flex-column mt-3">
  <strong>URL mit dem emetriq Profiling Skript integriert</strong>
  <textarea #urlInput
            (keyup.enter)="addUrl()"
            [formControl]="urlControl"
            cdkAutosizeMinRows="1"
            cdkTextareaAutosize
            name="url"
            placeholder="https://(www.)example.com/interesting/path"
            type="url"></textarea>
  <div *ngIf="urlsPreview">
    <div>{{urlsPreview.input}}</div>
    <div>{{urlsPreview.generated}}</div>
  </div>
  <div class="d-flex flex-column align-items-start">
    <mat-checkbox *ngIf="urlsPreview"
                  [(ngModel)]="appendAdditionalUrl"
                  class="ai-mat-checkbox-inverted"
                  color="primary">
      <span *ngIf="hasGeneratedWww()">Fügt eine Url mit <strong>www.</strong> Prefix automatisch hinzu.</span>
      <span *ngIf="!hasGeneratedWww()">Fügt eine Url mit <strong>non-www.</strong> automatisch hinzu.</span>
    </mat-checkbox>
    <button (click)="addUrl()"
            [disabled]="immutable"
            class="align-self-end mt-3"
            mat-stroked-button
            type="button">URL hinzufügen
    </button>
  </div>
</div>

<div class="result-wrapper">
  <span class="result-text">
    <span class="notification">{{getAddedUrlCount()}} URLs hinzugefügt. </span>
    <span *ngIf="getFailedUrls() > 0" class="ai-text-warning">{{getFailedUrls()}} ungültige URLs!</span>
  </span>
  <div class="list" *ngFor="let integratedUrl of urlList | async; index as i">
    <div class="cell status ok" *ngIf="integratedUrl.status === 'Ok'">
      <mat-icon>check_circle</mat-icon>
    </div>
    <div class="cell status checking" *ngIf="integratedUrl.status === 'Checking'">
      <div class="pending-icon"></div>
    </div>
    <div class="cell status failed" *ngIf="integratedUrl.status === 'Failed'">
      <mat-icon>error</mat-icon>
    </div>
    <div class="cell url"><span>{{integratedUrl.url}}</span></div>
    <div class="cell action">
      <button type="button" mat-icon-button (click)="removeUrl(i)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="getFailedUrls() > 0" class="result-error">
    <p>Keine Events (innerhalb der letzten 24 Stunden) stimmen mit der angegeben URL überein.
    <ul>
      <li>Bitte überprüfe die <a routerLink="/integration">Integration</a> auf deiner Webseite!</li>
      <li>Bitte achte auf <code>http</code> oder <code>https</code>!</li>
    </ul>
  </div>
</div>
