<article class="centered">
  <section>
    <div class="centered-content">
      <form (submit)="onSubmit()" [formGroup]="confirmForm" novalidate>
        <p id="form-caption">Erstmaliger Login. Bitte erstelle ein neues Passwort! Dein Passwort muss folgendes
          enthalten:</p>
        <app-password-strength [control]="confirmForm.get('passwords').get('password1')"></app-password-strength>
        <p class="ai-text-error">{{errorMessage}}</p>
        <div class="form-input-container">
          <mat-form-field>
            <input name="username"
                   placeholder="E-Mail"
                   matInput
                   formControlName="username" autocomplete="off">
            <mat-error *ngIf="confirmForm.get('username').touched && confirmForm.get('username').hasError('required')">
              <span>Du musst deine E-Mail-Address angeben.</span>
            </mat-error>
          </mat-form-field>
        </div>

        <div formGroupName="passwords">
          <div class="form-input-container">
            <mat-form-field>
              <input name="password1"
                     placeholder="Passwort"
                     matInput
                     type="password"
                     formControlName="password1">
            </mat-form-field>
          </div>
          <div class="form-input-container">
            <mat-form-field>
              <input name="password2"
                     placeholder="Passwort bestätigen"
                     matInput
                     type="password"
                     formControlName="password2"
                     [errorStateMatcher]="parentErrorStateMatcher">
              <mat-error
                *ngIf="confirmForm.get('passwords').get('password2').touched && confirmForm.get('passwords').hasError('matchOther')">
                <span>Deine Passwörter stimmen nicht überein.</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <button [disabled]="confirmForm.invalid" color="primary" mat-flat-button name="confirm" type="submit">
          Absenden
        </button>
      </form>
    </div>
  </section>
</article>
