import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { FormBulletpointComponent } from './bulletpoint/bulletpoint.component';
import { FormContainerComponent } from './container/container.component';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule
  ],
  declarations: [
    FormContainerComponent,
    FormBulletpointComponent
  ],
  exports: [
    FormContainerComponent,
    FormBulletpointComponent
  ]
})
export class LayoutFormModule { }
