import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ParentErrorStateMatcher, ValidationUtils } from '../../helpers/validation.util';
import { TrackingService } from '../../tracking/tracking.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
  parentErrorStateMatcher = new ParentErrorStateMatcher();

  changePasswordFormGroup: FormGroup;

  constructor(private authService: AuthService,
              private formBuilder: FormBuilder,
              private trackingService: TrackingService,
              private router: Router) {
  }

  ngOnInit() {
    this.changePasswordFormGroup = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      passwords: new FormGroup({
        password1: new FormControl('', ValidationUtils.configPasswordValidation()),
        password2: new FormControl('', Validators.required)
      }, ValidationUtils.passwordConfirmEqual)
    });
  }

  onSubmit() {
    if (this.changePasswordFormGroup.valid) {
      const oldPass = this.changePasswordFormGroup.controls['oldPassword'].value;
      const newPass = (this.changePasswordFormGroup.controls['passwords'] as FormGroup).controls['password1'].value;
      this.authService.changePassword(oldPass, newPass)
        .then(() => {
          this.close();
        });

      this.trackingService.event('changePassword');
    }
  }

  close() {
    this.router.navigateByUrl('/account');
  }
}
