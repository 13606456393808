import { Component, Input } from '@angular/core';
import { ReportStatus } from '../reports.interface';

@Component({
  selector: 'app-report-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class ReportsIconComponent {
  @Input() status = ReportStatus.Future;
  @Input() size = 30;
  @Input() warn = false;

  readonly Collecting = ReportStatus.Collecting;

  readonly Future = ReportStatus.Future;

  readonly Done = ReportStatus.Done;

  readonly Tracking = ReportStatus.Tracking;

  getToolTipText(): string {
    if (this.warn) {
      return 'Es scheint, dasss der Tracking Pixel gestern nicht korrekt integriert wurde. Bitte behebe den Fehler und überprüfe den Report morgen erneut.';
    }

    if (this.status === ReportStatus.Future) {
      return 'Der Report wird morgen generiert werden und steht dann zum Download bereit';
    }

    return '';
  }
}
