<div class="row">

  <div class="item icon">
    <div class="icon">
      <mat-icon color="primary" svgIcon="emq:{{icon}}"></mat-icon>
    </div>
  </div>

  <div class="item name">
    <div>
      <h2>{{name}}</h2>
      <p>{{description}}</p>
    </div>
  </div>

  <div class="item amount">
    <div *ngIf="!isSubscription">
      <app-item-counter (valueChange)="amountChange($event)" [max]="1000" [min]="1"
                        [value]="quantity"></app-item-counter>
    </div>
  </div>

  <div class="item price">
    <div>
      <h2><span>{{price}} €</span><span *ngIf="isSubscription"> monatlich</span></h2>
    </div>
  </div>
</div>
