import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-quality-indicator-samll',
  templateUrl: './quality-indicator-small.component.html',
  styleUrls: ['./quality-indicator-small.component.scss']
})
export class QualityIndicatorSmallComponent {
  @Input() profiles = 0;

  readonly lowDataCount = 1000;
  readonly sufficientDataCount = 10000;
  readonly optimalDataCount = 50000;

  getText(): string {
    let text = 'low';
    if (this.isDataQualityLow()) {
      text = 'niedrig';
    } else if (this.isDataQualitySufficient()) {
      text = 'ausreichend';
    } else if (this.isDataQualityOptimal()) {
      text = 'optimal';
    } else if (this.isDataQualityReached()) {
      text = 'hoch';
    }
    return text;
  }

  isDataQualityLow() {
    return this.profiles <= this.lowDataCount;
  }

  isDataQualitySufficient() {
    return this.profiles > this.lowDataCount && this.profiles <= this.sufficientDataCount;
  }

  isDataQualityReached(): boolean {
    return this.profiles > this.optimalDataCount;
  }

  isDataQualityOptimal() {
    return this.profiles > this.sufficientDataCount && this.profiles <= this.optimalDataCount;
  }

  tooltip(): string {
    if (this.isDataQualityLow()) {
      return `${this.profiles} / ${this.lowDataCount} eindeutige Profile analysiert`;
    }
    if (this.isDataQualitySufficient()) {
      return `${this.profiles} / ${this.sufficientDataCount} eindeutige Profile analysiert`;
    }
    if (this.isDataQualityOptimal()) {
      return `${this.profiles} / ${this.optimalDataCount} eindeutige Profile analysiert`;
    }
    return `${this.profiles} / ${this.optimalDataCount} eindeutige Profile analysiert`;
  }

}
