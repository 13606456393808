<table mat-table [dataSource]="users" class="ai-table-hover">
  <ng-container matColumnDef="email">
    <th *matHeaderCellDef mat-header-cell> E-Mail</th>
    <td mat-cell *matCellDef="let user">
      <div class="ai-dynamic-ellipsis" [matTooltip]="user.info?.email">
        <div>{{user.info?.email}}</div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="username">
    <th *matHeaderCellDef mat-header-cell> Benutzernamen</th>
    <td mat-cell *matCellDef="let user">
      <div class="ai-dynamic-ellipsis" [matTooltip]="user.userId">
        <div>{{user.userId}}</div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="address">
    <th *matHeaderCellDef mat-header-cell> Firma</th>
    <td mat-cell *matCellDef="let user"> {{user.info?.company}} </td>
  </ng-container>

  <!-- removed for not being overly relevant for user management -->
  <!-- ng-container matColumnDef="aiId">
    <th *matHeaderCellDef mat-header-cell> AI Id</th>
    <td mat-cell *matCellDef="let user">
      {{user.aiId}}
    </td>
  </ng-container-->

  <ng-container matColumnDef="sids">
    <th *matHeaderCellDef mat-header-cell> SIDs</th>
    <td mat-cell *matCellDef="let user">
      <app-editable-table-cell
        [editable]="user.hasClassicIntegration"
        (inputModelChange)="updateSids($event, user)"
        [inputModel]="user.sids">
      </app-editable-table-cell>
    </td>
  </ng-container>

  <ng-container matColumnDef="privatePool">
    <th *matHeaderCellDef mat-header-cell> Private Pool</th>
    <td mat-cell *matCellDef="let user">
      <mat-slide-toggle
        (ngModelChange)="updatePrivatePool($event, user)"
        (click)="$event.stopPropagation()"
        [disabled]="!user.hasClassicIntegration"
        [(ngModel)]="user.privatePool"
        color="primary">
      </mat-slide-toggle>
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th *matHeaderCellDef mat-header-cell> Aktionen</th>
    <td mat-cell *matCellDef="let user">
      <button [routerLink]="['/user-management/edit/', user.userId]" class="edit-user-button ai-action-button"
              mat-icon-button>
        <mat-icon>bearbeiten</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<div *ngIf="loading" class="container-fluid">
  <div class="d-flex flex-row justify-content-center">
    <mat-spinner></mat-spinner>
  </div>
</div>
