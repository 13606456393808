import { ErrorHandler, Injectable } from '@angular/core';
import { TrackingService } from './tracking.service';

@Injectable()
export class ErrorTracking implements ErrorHandler {

  handleError(error: any): void {
    try {
      JSON.stringify(error);
      TrackingService.track('globalError', error);
    } catch (e) {
      if (error.message) {
        TrackingService.track('globalError', error.message);
      } else {
        TrackingService.track('globalError', `${error}`);
      }
    }
    throw error;
  }
}
