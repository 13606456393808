<div class="charts-wrapper">
  <h1 class="pl-4 ai-h1-light ai-page-header">{{reportName()}}</h1>
  <div class="d-flex" *ngIf="report && report.type !== typeEvaluation">
    <div class="p-2 mx-3">
      <strong>Qualität</strong><br>
      <app-quality-indicator-samll [profiles]="reportsCharts?.uniqueProfiles"></app-quality-indicator-samll>
    </div>
    <div class="p-2 mx-3">
      <strong>Datenpool</strong><br>
      <span class="data-pool-size">{{100000000 | toLocaleNumber}}</span>
    </div>
    <div class="p-2 mx-3">
      <strong>Deine Audience</strong><br>
      <span class="profiles-count">{{reportsCharts?.uniqueProfiles | toLocaleNumber}}</span>
    </div>
    <div [ngClass]="{'me-auto': !chartsHaveData()}" class="p-2 mx-3">
      <strong>Letzte empfangene Daten</strong><br>
      <span class="last-data-changed">{{(report?.lastDataChanged | date:'dd.MM.YYYY') || 'niemals'}}</span>
    </div>
    <div *ngIf="report" class="p-2 mx-3 me-auto">
      <strong>Trackbare Events</strong><br/>
      <span id="live-events">{{liveEventsCount}} in den letzten 5 Minuten.</span>
    </div>
    <div class="p-2 mx-3">
      <app-timeframe [disabled]="isSnapshotView" [report]="report"></app-timeframe>
    </div>
  </div>
  <div *ngIf="evaluationGroups">
    <app-reports-evaluation-filter [edit]="false" [evaluationGroups]="evaluationGroups"></app-reports-evaluation-filter>
  </div>

  <!-- <div *ngIf="sourceUrlList" class="report-sources-list-container p-2 m-3">
      <h4 class="ai-h4 pb-1"><strong>Report Quellen</strong></h4>
      <p class="ai-p report-source-spacing">{{sourceUrlList}}</p>
  </div> -->

  <mat-expansion-panel hideToggle *ngIf="sourceUrlList" style="margin-left: 20px; margin-right: 40px;">
    <mat-expansion-panel-header>
      <mat-panel-title class="ai-h4 pb-1">
        <strong>
        Report Quellen
      </strong>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p>{{sourceUrlList}}</p>
  </mat-expansion-panel>

<br>

  <div class="report-action-button-container d-flex mx-4 justify-content-end">
    <app-edit-button [plan]="plan"
                     [report]="report"
                     [isSnapshotView]="isSnapshotView">
    </app-edit-button>
    <button id="download-report"
            mat-icon-button
            color="primary"
            matTooltip="Report herunter laden {{reportsCharts?.start}} - {{reportsCharts?.end}}"
            class="ai-action-button"
            (click)="download()"
            *ngIf="chartsHaveData() && !isSnapshotView">
      <mat-icon>cloud_download</mat-icon>
    </button>
    <button id="screenshot"
            mat-icon-button
            color="primary"
            matTooltip="Mache einen Screenshot (experimental)"
            class="ai-action-button"
            (click)="screenshot()"
            *ngIf="chartsHaveData()">
      <mat-icon>camera_alt</mat-icon>
    </button>
    <app-share-button *ngIf="!isSnapshotView && chartsHaveData()"
                      [reportsCharts]="reportsCharts"
                      [report]="report">
    </app-share-button>
  </div>

  <div *ngIf="report && !isLoading && !chartsHaveData()"
       class="p-3 d-flex justify-content-center">
    <app-live-events-bubble [description]="liveEventsDescription" [reportId]="report.id"></app-live-events-bubble>
  </div>

  <div *ngIf="!isLoading && chartsHaveData()">

    <div class="p-3">
      <app-audience-overview [persona]="reportsCharts?.personasOverview"></app-audience-overview>
    </div>

    <div class="nav-wrapper">

      <app-navigation></app-navigation>

      <div id="charts">

        <app-chart-topic class="open" [dataProvider]="findChartDataBy('gender')?.dataProvider" label="Geschlecht" category="Personendaten">
          <app-distribution-chart [chartData]="findChartDataBy('gender')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('gender')?.data" role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('gender')?.data" role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('age')?.dataProvider" label="Alter" category="Personendaten">
          <app-distribution-chart [chartData]="findChartDataBy('age')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('age')?.data" role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('age')?.data" role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('genderAge')?.dataProvider" label="Alter nach Geschlecht" category="Personendaten">
          <app-distribution-chart [chartData]="findChartDataBy('genderAge')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('genderAge')?.data" role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('genderAge')?.data" role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('hasChildren')?.dataProvider" label="Hat Kinder" category="Personendaten">
          <app-distribution-chart [chartData]="findChartDataBy('hasChildren')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('hasChildren')?.data" role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('hasChildren')?.data" role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('childrenAge')?.dataProvider" label="Alter der Kinder" category="Finanzdaten">
          <app-distribution-chart [chartData]="findChartDataBy('childrenAge')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('childrenAge')?.data" role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('childrenAge')?.data" role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('netHouseholdIncome')?.dataProvider"
                         label="Haushaltsnettoeinkommen" category="Finanzdaten">
          <app-distribution-chart [chartData]="findChartDataBy('netHouseholdIncome')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('netHouseholdIncome')?.data"
                           role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('netHouseholdIncome')?.data"
                           role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('purchasingPower')?.dataProvider" label="Kaufkraft" category="Finanzdaten">
          <app-distribution-chart [chartData]="findChartDataBy('purchasingPower')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('purchasingPower')?.data"
                           role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('purchasingPower')?.data"
                           role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('financialInterests')?.dataProvider"
                         label="Finanzielle Interessen" category="Finanzdaten">
          <app-distribution-chart [chartData]="findChartDataBy('financialInterests')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('financialInterests')?.data"
                           role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('financialInterests')?.data"
                           role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('creditworthiness')?.dataProvider" label="Kreditwürdigkeit" category="Finanzdaten">
          <app-distribution-chart [chartData]="findChartDataBy('creditworthiness')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('creditworthiness')?.data"
                           role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('creditworthiness')?.data"
                           role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('jobStatus')?.dataProvider" label="Arbeitsverhältnis" category="Job">
          <app-distribution-chart [chartData]="findChartDataBy('jobStatus')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('jobStatus')?.data" role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('jobStatus')?.data" role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('education')?.dataProvider" label="Ausbildung" category="Job">
          <app-distribution-chart [chartData]="findChartDataBy('education')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('education')?.data" role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('education')?.data" role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('householdSize')?.dataProvider" label="Haushaltsgröße" category="Personendaten">
          <app-distribution-chart [chartData]="findChartDataBy('householdSize')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('householdSize')?.data"
                           role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('householdSize')?.data"
                           role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('tvConsumers')?.dataProvider" label="TV Verhalten" category="Entertainment">
          <app-distribution-chart [chartData]="findChartDataBy('tvConsumers')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('tvConsumers')?.data" role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('tvConsumers')?.data" role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('travel')?.dataProvider" label="Reiseverhalten" category="Entertainment">
          <app-distribution-chart [chartData]="findChartDataBy('travel')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('travel')?.data" role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('travel')?.data" role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('sinus')?.dataProvider" label="Sinus Milieu" category="Einstellungstypen">
          <app-distribution-chart [chartData]="findChartDataBy('sinus')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('sinus')?.data" role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('sinus')?.data" role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('newSinus')?.dataProvider" label="Neue Sinus Milieu" category="Einstellungstypen">
          <app-distribution-chart [chartData]="findChartDataBy('newSinus')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('newSinus')?.data" role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('newSinus')?.data" role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('automotiveModel')?.dataProvider" label="Automarke" category="Interessen">
          <app-distribution-chart [chartData]="findChartDataBy('automotiveModel')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('automotiveModel')?.data"
                           role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('automotiveModel')?.data"
                           role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('interests')?.dataProvider" label="Interessen" category="Interessen">
          <app-distribution-chart [chartData]="findChartDataBy('interests')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('interests')?.data" role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('interests')?.data" role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('heavyBuyer')?.dataProvider" label="Vielkäufer" category="Entertainment">
          <app-distribution-chart [chartData]="findChartDataBy('heavyBuyer')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('heavyBuyer')?.data" role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('heavyBuyer')?.data" role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('industrySectors')?.dataProvider" label="Industrie Sektoren" category="Job">
          <app-distribution-chart [chartData]="findChartDataBy('industrySectors')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('industrySectors')?.data"
                           role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('industrySectors')?.data"
                           role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('affinity')?.dataProvider" label="Affinität" category="Interessen">
          <app-distribution-chart [chartData]="findChartDataBy('affinity')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('affinity')?.data" role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('affinity')?.data" role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('limbicTypes')?.dataProvider" label="Limbische Typen" category="Einstellungstypen">
          <app-distribution-chart [chartData]="findChartDataBy('limbicTypes')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('limbicTypes')?.data" role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('limbicTypes')?.data" role="reach-chart"></app-reach-chart>
        </app-chart-topic>

        <app-chart-topic [dataProvider]="findChartDataBy('entertainmentStreaming')?.dataProvider"
                         label="Entertainment Streaming" category="Entertainment">
          <app-distribution-chart [chartData]="findChartDataBy('entertainmentStreaming')?.data"
                                  role="distribution-chart"></app-distribution-chart>
          <app-index-chart [chartData]="findChartDataBy('entertainmentStreaming')?.data"
                           role="index-chart"></app-index-chart>
          <app-reach-chart [chartData]="findChartDataBy('entertainmentStreaming')?.data"
                           role="reach-chart"></app-reach-chart>
        </app-chart-topic>

      </div>
    </div>

  </div>

  <div *ngIf="!chartsHaveData() && !isLoading" class="d-flex justify-content-center">
    <h4></h4>
  </div>

  <div *ngIf="isLoading" id="loading" class="py-5">
    <mat-icon class="rotate">cached</mat-icon>
    Report wird generiert ...
  </div>
</div>
