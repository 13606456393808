import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByModified'
})
export class OrderByModifiedPipe implements PipeTransform {

  static sort(value: any[]) {
    return value.sort((a, b) => {
      if (!a.modified && !b.modified) {
        return 0;
      }
      if (!a.modified && b.modified) {
        return 1;
      }
      if (a.modified && !b.modified) {
        return -1;
      }
      return b.modified.getTime() - a.modified.getTime();
    });
  }

  transform(value: any[], args?: any): any {
    if (value === null || value === undefined) {
      return value;
    }
    return OrderByModifiedPipe.sort(value);
  }
}
