import { Component, Input, OnInit } from '@angular/core';
import { SegmentInfo } from '../../reports.interface';
import { chartsText } from '../charts.texts';

@Component({
  selector: 'app-index-chart',
  templateUrl: './index-chart.component.html',
  styleUrls: ['./index-chart.component.scss']
})
export class IndexChartComponent implements OnInit {
  @Input() chartData: SegmentInfo[] = [];

  readonly text = chartsText;

  data: IndexDataPoint[] = [];

  ngOnInit(): void {
    //console.log("data in index before mapping: ", this.chartData);
    this.data = this.chartData
      .map((segmentInfo) => ({
        value: segmentInfo.uplift*100,
        name: segmentInfo.label,
        description: segmentInfo.description
      }));
      //.sort((a, b) => b.value - a.value);
    //console.log("data in index after mapping: ", this.data);
    //für Polar: value: segmentInfo.uplift*100,
    //für alte Darstellung: value: segmentInfo.uplift -1

  }

  /**
   * Calculates a width value which ist scaled to 100.
   *
   * @param value the uplift value
   */
  getPercentage(value: number): number {
    const values = this.data.map(uplift => uplift.value);
    const minOrMax = value > 0 ? Math.max(...values) : Math.min(...values);
    return Math.abs(100 * value / minOrMax);
  }
}

interface IndexDataPoint {
  value: number;
  name: string;
  description?: string;
}
