<!-- <div class="container-fluid">
  <div class="row">
    <div class="col-10"> -->
      <!-- Start Chart -->
     <!--  <div *ngFor="let uplift of data" class="d-flex flex-row justify-content-center">

        <div class="d-flex flex-column align-items-end flex-50">
          <app-chart-segment *ngIf="uplift.value < 0"
                             [label]="uplift.name"
                             [description]="uplift.description"
                             [primaryPercentage]="getPercentage(uplift.value)"
                             [primaryPercentageLabel]="uplift.value.toFixed(2)"
                             align="right"></app-chart-segment>
        </div>

        <div class="divider"></div>

        <div class="d-flex flex-column flex-50 chart-bar-container">
          <app-chart-segment *ngIf="uplift.value > 0"
                             [label]="uplift.name"
                             [description]="uplift.description"
                             [primaryPercentage]="getPercentage(uplift.value)"
                             [primaryPercentageLabel]="'+' + uplift.value.toFixed(2)"></app-chart-segment>
        </div>

      </div> -->
      <!-- End Chart -->
  <!--   </div>
    <div class="col-2"> -->
      <!-- Start Legend -->
     <!--  <app-chart-legend-item color="brand"
                             label="Deine Audience"></app-chart-legend-item>
      <app-chart-legend-item color="dark"
                             label="Index: 1.0 basierend auf dem Datenpool"></app-chart-legend-item> -->
      <!-- End Legend -->
    <!-- </div>
  </div>
</div> -->

<!-- <div *ngIf="data.length <= 2" class="container-fluid">
  <div class="row">
    <div class="col-10 d-flex flex-row"> -->
      <!-- Start Chart -->
    <!--   <div *ngFor="let uplift of data" class="d-flex flex-row justify-content-center">

        <app-svg-chart
          *ngIf="uplift.value < 0"
          [width]="400"
          [radius]="200"
          [label]="uplift.name"
          [description]="uplift.description"
          [value]="(-1*uplift.value)"
        ></app-svg-chart>

        <app-svg-chart
          *ngIf="uplift.value > 0"
          [width]="400"
          [radius]="200"
          [label]="uplift.name"
          [description]="uplift.description"
          [value]="(uplift.value)"
        ></app-svg-chart>

      </div> -->
      <!-- End Chart -->
   <!--  </div>
    <div class="col-2"> -->
      <!-- Start Legend -->
 <!--      <app-chart-legend-item color="brand"
                             label="Deine Audience"></app-chart-legend-item>
      <app-chart-legend-item color="dark"
                             label="Index: 1.0 basierend auf dem Datenpool"></app-chart-legend-item> -->
      <!-- End Legend -->
   <!--  </div>
  </div>
</div> -->


<!-- Mit conditioning:
<div *ngIf="data.length > 2" class="container-fluid"></div> -->

<div class="container-fluid">
  <div class="row">
    <div class="col-6">
      <app-radial-chart [data]="data"></app-radial-chart>
    </div>
  </div>
</div>