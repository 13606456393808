import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { UndoService } from '../undo/undo.service';
import { Notification } from './notification.interface';
import { NotificationService } from './notification.service';

@Component({
  selector: 'app-notification',
  template: '',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  snackBarRef: MatSnackBarRef<SimpleSnackBar>;

  subscription: Subscription;

  constructor(private snackBar: MatSnackBar,
              private undo: UndoService,
              private service: NotificationService) {
  }

  ngOnInit() {
    this.subscribeToNotifications();
  }

  subscribeToNotifications() {
    this.subscription = this.service
      .onMessage()
      .subscribe((notification: Notification) => this.process(notification));
  }

  process(notification: Notification) {
    /* istanbul ignore else */
    if (notification) {
      if (notification.success) {
        const action = this.undo.hasUndoAction() ? 'undo' : '';
        this.snackBarRef = this.snackBar.open(notification.message, action, {
          verticalPosition: 'top',
          duration: 3000,
          panelClass: 'ai-snackbar-success'
        });
      } else {
        this.snackBarRef = this.snackBar.open(notification.message, 'close', {
          verticalPosition: 'top',
          duration: 20000,
          panelClass: 'ai-snackbar-error'
        });
      }
      this.snackBarRef.onAction().subscribe(() => this.undo.execute());
    }
  }

  ngOnDestroy(): void {
    /* istanbul ignore else  */
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
