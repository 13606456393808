export class ProfilingScript {

  private params = new Map<string, any>();

  /**
   * Create a ProfilingScript
   *
   * @param sid this sid text, default <code><SID></code>
   */
  constructor(private sid: string | number = '<SID>') {
  }

  static sid(sid: string | number): ProfilingScript {
    return new ProfilingScript(sid);
  }

  withPrivatePool(is: boolean = true): ProfilingScript {
    if (is) {
      this.params.set('private_pool', '1');
    }
    return this;
  }

  withEventId(id: number): ProfilingScript {
    this.params.set('event_id', id ? id : '?');
    return this;
  }

  toPixelAsLoaderScript(): string {
    let url = `https://ups.xplosion.de/loader/${this.sid}/default.js`;

    if (this.params.size) {
      url += '?' + Array.from(this.params.keys()).map((key) => `${key}=${this.params.get(key)}`).join('&');
    }

    return `<script src="${url.toString()}" type="text/javascript"></script>`;
  }

  toDataScriptString() {
    let url = `https://ups.xplosion.de/data?_sid=${this.sid}`;

    if (this.params.size) {
      url += '&' + Array.from(this.params.keys())
        .map((key) => `${key}=${this.params.get(key)}`)
        .join('&');
    }

    url += '&ssi=1&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_213}';

    return `<script>(new Image()).src = "${url.toString()}"</script>`;
  }

  toPixelAsImagePixel(): string {
    let script = `<img src="https://ups.xplosion.de/data?_sid=${this.sid}`;

    for (const entry of Array.from(this.params.entries())) {
      script += '&';
      script += entry[0] + '=' + entry[1];
    }

    return `${script}&ssi=1&gdpr=\${GDPR}&gdpr_consent=\${GDPR_CONSENT_213}"/>`;
  }
}
