import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { JointControllerService, JointControllerUserDataDto } from '../joint-controller.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NotificationService } from '../../notification/notification.service';
import { TrackingService } from '../../tracking/tracking.service';
import { AuthService } from '../../auth/auth.service';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-joint-controller',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss']
})
export class JointControllerSignComponent implements OnInit {
  jointControllerFormGroup: FormGroup;

  examplePdfSource: SafeResourceUrl;

  constructor(private jointControllerService: JointControllerService,
              private activeRoute: ActivatedRoute,
              private router: Router,
              private domSanitizer: DomSanitizer,
              private notificationService: NotificationService,
              private authService: AuthService,
              private userService: UserService) {
    this.jointControllerFormGroup = new FormGroup({
      name: new FormControl('', Validators.required),
      company: new FormControl('', Validators.required),
      telephone: new FormControl('', Validators.required),
      street: new FormControl('', Validators.required),
      postcode: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {

    this.userService.isJointControllerSigned().subscribe((result: boolean) => {
      if (result) {
        this.router.navigateByUrl('/');
      }
    });

    this.jointControllerService.getExampleJointControllerDocument().subscribe((data) => {
      const pdfBlog = new Blob([data], {type: 'application/pdf'});
      this.examplePdfSource = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(pdfBlog));
    });

    TrackingService.track('show-joint-controller', {newUser: this.authService.isConfirmAllowed()});
  }

  signJointController() {
    const userData = this.jointControllerFormGroup.value as JointControllerUserDataDto;
    this.jointControllerService.saveJointController(userData).then(() => {
      this.router.navigate(['/joint-controller/finish']);
    }).catch(() => {
      this.notificationService.sendError('Leider ist ein Fehler aufgetreten!');
    });
  }
}
