import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../auth.service';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective {

  hasView = false;

  groups: string[] = [];

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private auth: AuthService) {
    this.fillGroups();
  }

  @Input() set appHasRole(role: string) {
    this.applyFor(role);
  }

  groupsContains(role: string): boolean {
    if (!role) {
      return false;
    }
    const normalized = role.toLowerCase().trim();
    if (normalized.startsWith('!')) {
      return !this.groups.includes(normalized.replace('!', ''));
    }
    return this.groups.includes(normalized);
  }

  applyFor(role: string) {
    if (this.groupsContains(role) && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!this.groupsContains(role) && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    } else {
      // do nothing
    }
  }

  private fillGroups() {
    this.groups = this.auth.getGroups().map((group) => group.toLowerCase().trim());
  }
}
