import {
  AfterContentInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { LiveEventsService } from '../live-events.service';

@Component({
  selector: 'app-live-events-bubble',
  templateUrl: './live-events-bubble.component.html',
  styleUrls: ['./live-events-bubble.component.scss']
})
export class LiveEventsBubbleComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input() reportId?: string;

  @Input() description: string;

  @HostBinding('class.small') @Input() small: boolean;

  @ViewChild('svgText') svgNumberText: ElementRef<SVGTextElement>;

  viewBoxAttr?: string;

  currentEventNumber = 0;

  subscription?: Subscription;

  ripple = false;


  error?: string;


  constructor(private service: LiveEventsService) {
  }

  ngOnInit(): void {
    this.sendRequest();
  }

  ngAfterContentInit() {
    this.resizeSVGText(this.currentEventNumber.toString());
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  applyValue(value: number): void {
    this.ripple = false;
    /* istanbul ignore else */
    if (this.currentEventNumber !== value) {
      this.currentEventNumber = value;
      this.resizeSVGText(value.toString());
      this.ripple = true;
    }
  }

  resizeSVGText(value: string) {
    /* istanbul ignore else */
    if (this.svgNumberText) {
      /* This is a hack that triggers re calc of the box */
      this.svgNumberText.nativeElement.textContent = value;

      const textRect = this.svgNumberText.nativeElement.getBBox();
      this.viewBoxAttr = `0 0 ${textRect.width} ${textRect.height}`;
    }
  }

  sendRequest(): void {
    if (this.reportId) {
      this.subscription = this.service.eventCountForReport(`${this.reportId}`)
        .subscribe((n) => this.applyValue(n));
    } else {
      this.subscription = this.service.eventCountForIntegration()
        .subscribe((n) => this.applyValue(n));
    }
  }
}
