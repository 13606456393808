import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '../../api/app.api';
import { NotificationService } from '../../notification/notification.service';
import { TrackingService } from '../../tracking/tracking.service';
import { BasicReport } from '../reports.interface';
import { UrlEventReport } from './url-event.interface';

@Injectable({
  providedIn: 'root'
})
export class UrlEventService {

  constructor(private httpClient: HttpClient,
              private notification: NotificationService) {
  }

  public createUrlEventReport(report: UrlEventReport): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.httpClient.put(`${environment.apiBasePath}/reports/url-event`, report)
        .subscribe(() => {
          this.notification.sendSuccess('Report erstellt!');
          resolve();
        }, () => {
          this.notification.sendError('Leider ist ein Fehler aufgetreten!');
          reject();
        });
    });
  }

  public updateUrlEventReport(report: UrlEventReport): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.httpClient.post(`${environment.apiBasePath}/reports/url-event`, report)
        .subscribe(() => {
          this.notification.sendSuccess('Report geändert!');
          resolve();
        }, (e) => {
          this.notification.sendError('Leider ist ein Fehler aufgetreten!');
          reject();
        });
    });
  }

  public getUrlEventReport(id: string): Promise<UrlEventReport> {
    return new Promise<UrlEventReport>((resolve, reject) => {
      this.httpClient.get<ApiResponse<UrlEventReport>>(`${environment.apiBasePath}/reports/url-event/${id}`)
        .subscribe(
          (response) => {
            TrackingService.track('getUrlEventReport');
            resolve(this.convertReport(response.data));
          },
          () => {
            this.notification.sendError('Der Report konnte nicht geladen werden!');
            reject();
          }
        );
    });
  }

  private convertReport<T extends BasicReport>(raw: T): T {
    /* istanbul ignore else */
    if (typeof raw.modified === 'string') {
      raw.modified = new Date(raw.modified);
    }
    /* istanbul ignore else */
    if (typeof raw.created === 'string') {
      raw.created = new Date(raw.created);
    }
    return raw;
  }
}
