import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, Observable } from 'rxjs';
import { ApiResponse } from '../../api/app.api';
import { IntegrationStatus, UrlIntegration, UrlIntegrationResponse } from './url-select.interface';
import { environment } from '../../../environments/environment';
import { AI_SID, User } from '../../user/user.interface';
import { UserService } from '../../user/user.service';
import { bufferCount, concatMap, delay, map, mergeMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UrlSelectService {

  private urlList = new BehaviorSubject<UrlIntegration[]>([]);

  constructor(
    private httpClient: HttpClient,
    private userService: UserService) {
  }

  /**
   * Creates a elastic search request.
   *
   * @param urlIntegration the url to use
   * @param reportClassic use the classic sids if available or use new integration style
   */
  buildRequest(urlIntegration: UrlIntegration, user: User): IntegrationCheckRequest {
    const hasClassicIntegration = user.hasClassicIntegration;

    const request: IntegrationCheckRequest = {
      // copy of sidCollection
      sids: [...user.sids || []],
      urlIntegration
    };
    if (!hasClassicIntegration) {
      request.sids.push(AI_SID);
    }
    // TODO 28.06.21: DPF-1989 use event id when new report integration style!!!
    return request;
  }

  /**
   * Checks URL. Requests a new fresh user.
   *
   * @param urlIntegration
   */
  public check(urlIntegration: UrlIntegration): Observable<any> {
    return this.userService.getUser().pipe(
      concatMap((user) => this.checkWithUser(urlIntegration, user))
    );
  }

  /**
   * Checks all given URLs.
   *
   * The user will only fetched once.
   *
   * @param urls
   */
  checkAll(urls: UrlIntegration[]): Observable<UrlIntegration[]> {
    return this.userService.getUser().pipe(
      mergeMap((user) =>
        // concat runs request as sequence
        concat(...urls.map(url => this.checkWithUser(url, user).pipe(delay(1000))))
      ),
      // makes an array of urls from sequential url requests
      bufferCount(urls.length)
    );
  }

  getUrlList(): BehaviorSubject<UrlIntegration[]> {
    return this.urlList;
  }

  create() {
    this.clearUrls();
  }

  async update(urls: UrlIntegration[] = []) {
    this.clearUrls();
    const list = this.urlList.getValue();
    urls.forEach((url) => {
      const urlIntegration = {
        url: url.url,
        status: IntegrationStatus.Checking
      };
      list.push(urlIntegration);
    });
    this.checkAll(urls).subscribe();
  }

  addUrl(url: string) {
    const list = this.urlList.getValue();
    const urlIntegration = {
      url,
      status: IntegrationStatus.Checking
    };
    list.push(urlIntegration);
    this.check(urlIntegration).subscribe();
  }

  getFailedUrls() {
    return this.urlList.getValue().filter(item => item.status === IntegrationStatus.Failed).length;
  }

  getAddedUrlCount() {
    return this.urlList.getValue().length;
  }

  removeUrlFromList(index: number) {
    this.urlList.getValue().splice(index, 1);
  }

  private checkWithUser(urlIntegration: UrlIntegration, user: User): Observable<UrlIntegration> {
    const integrationCheckRequest = this.buildRequest(urlIntegration, user);
    const url = `${environment.integrationBasePath}/check`;
    return this.httpClient
      .post<ApiResponse<UrlIntegrationResponse>>(url, integrationCheckRequest)
      .pipe(
        tap((response: ApiResponse<UrlIntegrationResponse>) => {
          urlIntegration.status = response.data.integrated.success ? IntegrationStatus.Ok : IntegrationStatus.Failed;
          const urlIntegrations = this.urlList.getValue();
          urlIntegrations.find((u) => u.url === urlIntegration.url).status = urlIntegration.status;
          this.urlList.next(urlIntegrations);
        }),
        map(response => urlIntegration)
      );
  }

  private clearUrls() {
    this.urlList.getValue().splice(0, this.urlList.getValue().length);
  }
}

export interface IntegrationCheckRequest {
  sids: string[];
  urlIntegration: UrlIntegration;
  aiId?: string;
}
