import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TrackingService } from '../tracking/tracking.service';
import { saveAs } from 'file-saver';
import { NotificationService } from '../notification/notification.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JointControllerService {

  readonly dateFormat = 'DD.MM.YYYY';

  saveAs = saveAs;

  constructor(private httpClient: HttpClient, private notificationService: NotificationService) {
  }

  saveJointController(userData: JointControllerUserDataDto): Promise<any> {
    return this.httpClient.post(`${environment.apiBasePath}/joint-controller`, userData).toPromise();
  }

  downloadJointController() {
    this.httpClient.get<Blob>(`${environment.apiBasePath}/joint-controller`,
      {observe: 'response', responseType: 'blob' as 'json'})
      .subscribe((res: HttpResponse<Blob>) => {
          const fileName = this.extractFileName(res.headers.get('content-disposition'));
          this.saveAs(res.body, fileName);
        },
        (errorResponse: HttpErrorResponse) => {
          TrackingService.track('downloadJointControllerError', errorResponse);
          this.notificationService.sendError('Leider ist ein Fehler aufgetreten!');
        });
  }

  getExampleJointControllerDocument(): Observable<any> {
    const httpOptions = {responseType: 'blob' as 'json'};
    return this.httpClient.get(`${environment.apiBasePath}/joint-controller/example`, httpOptions);
  }

  private extractFileName(dispositionHeader) {
    let fileName;
    if (dispositionHeader) {
      fileName = dispositionHeader.split(';')[1].trim().split('=')[1].replace(new RegExp('"', 'g'), '');
    } else {
      fileName = 'example-joint-controller.pdf';
    }
    return fileName;
  }
}

export interface JointControllerUserDataDto {
  name?: string;
  company?: string;
  telephone?: string;
  street?: string;
  postcode?: string;
  city?: string;
  country?: string;
}
