<app-form-container headline="Tracking Pixel Report" svgIcon="emq:report_pixel">
  <form [formGroup]="submitReportForm">
    <article class="centered">
      <section>
        <div class="centered-content">
          <app-form-bulletpoint headline="Name des Reports" number="1">
            <div class="form-input-container">
              <mat-form-field>
                <input matInput
                       required
                       id="report-title"
                       #reportTitle
                       [maxlength]="maxLengthName"
                       placeholder="Report"
                       formControlName="label"
                       (blur)="updateReport()">
                <mat-error>Report Name eintragen</mat-error>
                <mat-hint align="end">{{reportTitle.value.length}} / {{maxLengthName}}</mat-hint>
              </mat-form-field>
            </div>
          </app-form-bulletpoint>

          <app-form-bulletpoint headline="Integriere deine Datenquelle" number="2">
            <app-pixel-integration [eventId]="reportPixel?.eventId"></app-pixel-integration>
          </app-form-bulletpoint>
        </div>
      </section>
    </article>
  </form>
</app-form-container>
