<app-page>
  <p class="ai-text-error">{{errorMessage}}</p>
  <form id="forgotPasswordForm" [formGroup]="forgotPasswordForm" (submit)="requestPasswordReset()">
    <div class="form-input-container">
      <mat-form-field>
        <input name="email"
               placeholder="E-Mail"
               matInput
               required
               type="text"
               formControlName="username"
               autocomplete="off">
      </mat-form-field>
    </div>
    <button mat-flat-button
            type="submit"
            color="primary"
            [disabled]="!forgotPasswordForm.valid">
      Absenden
    </button>
  </form>
</app-page>
