import { Component, Input, OnInit } from '@angular/core';
import { TrackingService } from '../../../tracking/tracking.service';
import { PersonaOverview, SegmentInfo } from '../../reports.interface';

@Component({
  selector: 'app-audience-overview',
  templateUrl: './audience-overview.component.html',
  styleUrls: ['./audience-overview.component.scss']
})
export class AudienceOverviewComponent implements OnInit {

  @Input() persona: PersonaOverview;

  constructor() {
  }

  ngOnInit(): void {
  }

  getImageClasses(): string[][] {
    if (!this.persona) {
      return [];
    }

    const imageClassNames = [];

    const female = this.persona.genders.find(segment => segment.segment === 'e2');
    const male = this.persona.genders.find(segment => segment.segment === 'e1');

    /* istanbul ignore else */
    if (female) {
      imageClassNames.push(this.getAgeClassnames(female));
    }
    /* istanbul ignore else */
    if (male) {
      imageClassNames.push(this.getAgeClassnames(male));
    }

    return imageClassNames;
  }

  getText(): string {
    if (!this.persona) {
      return '';
    }

    const parts = ['Deine Audience'];

    if (this.persona.genders.length === 1) {
      const segmentInfo = this.persona.genders[0];
      parts.push(`ist zu ${this.asPercent(segmentInfo.userProfilesPercent)}% ${segmentInfo.label}`);
    } else {
      const segments = this.persona.genders
        .map((gender) => `${this.asPercent(gender.userProfilesPercent)}% ${gender.label}`) //todo change in db
        .join(' und zu ');
      parts.push(`ist zu ${segments}`);
    }

    if (this.persona.ages.length === 1) {
      const segmentInfo = this.persona.ages[0];
      parts.push(`und ${this.asPercent(segmentInfo.userProfilesPercent)}% sind ${segmentInfo.label} Jahre alt`);
    } else {
      const length = this.persona.ages.length;
      const first = this.persona.ages[0];
      const last = this.persona.ages[length - 1];
      parts.push(`und das Alter liegt zwischen ${first.label} und ${last.label}`);
    }

    if (this.persona.incomes.length === 1) {
      const segmentInfo = this.persona.incomes[0];
      parts.push(`und hat ein Haushaltsnettoeinkommen von ${segmentInfo.label}`);
    } else {
      const length = this.persona.incomes.length;
      const first = this.persona.incomes[0];
      const last = this.persona.incomes[length - 1];
      parts.push(`und hat ein Haushaltsnettoeinkommen zwischen ${first.label} und ${last.label}`);
    }

    return parts.join(' ').concat('.');
  }

  asPercent(p: number): number {
    return Math.round(p * 100);
  }

  clickPersona() {
    TrackingService.track('clickPersona');
  }

  private getAgeClassnames(segmentInfo: SegmentInfo) {
    // see audience-overview.component.scss for mapping
    return this.persona.ages.map(age => `persona-image persona-gender-${segmentInfo.segment} persona-age-${age.segment}`);
  }
}
