import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PlanConfig, UserPlan } from '../plan.service';
import { ReportDisplayType } from '../../reports.interface';
import { PlanType } from '../../../user/user-administration.service';
import { Router } from '@angular/router';

export enum BannerButton {
  NotDefined,
  BuyReport,
  UpgradePlan
}

@Component({
  selector: 'app-plan-banner',
  templateUrl: './plan-banner.component.html',
  styleUrls: ['./plan-banner.component.scss']
})
export class PlanBannerComponent implements OnChanges {

  @Input() userPlan: UserPlan;
  @Input() reportDisplayType: ReportDisplayType;
  @Input() createButtonRipple = false;
  @Output() createReport = new EventEmitter<any>();

  ReportDisplayType = ReportDisplayType;
  enumBannerButton = BannerButton;

  config: PlanConfig;

  buttonType: BannerButton = BannerButton.NotDefined;
  planTypeEnum = PlanType;

  constructor(private router: Router) {}

  ngOnChanges(): void {

    if (this.userPlan?.planConfig.planType === PlanType.Free) {
      this.buttonType = BannerButton.UpgradePlan;
    } else if (this.userPlan?.planConfig.planType === PlanType.Business) {
      this.buttonType = BannerButton.BuyReport;
    } else {
      this.buttonType = BannerButton.NotDefined;
    }
  }

  availableReportsInLimitRange(reportsLimit: number, availableReports: number) {
    if (availableReports > reportsLimit) {
      return reportsLimit;
    } else {
      return availableReports;
    }
  }

  additionalPlanText(reportsLimit: number, availableReports: number): string {
    const additionalReports = availableReports - reportsLimit;

    if (additionalReports >= 1) {
      return ` + ${additionalReports} extra`;
    } else {
      return '';
    }
  }

  getReportAvailabilityTrackingText(): string {
    const availableReports = this.userPlan?.availableReports.availableTrackingReports || 0;
    const limit = this.userPlan?.planConfig.limitOfTrackingReports || 0;
    const availableInLimitRange = this.availableReportsInLimitRange(limit, availableReports);
    const planText = this.additionalPlanText(limit, availableReports);

    return this.getAvailabilityText(availableReports, availableInLimitRange, limit, planText);
  }

  getReportAvailabilityEvaluationText(): string {
    const availableReports = this.userPlan?.availableReports.availableEvaluationReports || 0;
    const limit = this.userPlan?.planConfig.limitOfEvaluationReports || 0;
    const availableInLimitRange = this.availableReportsInLimitRange(limit, availableReports);
    const planText = this.additionalPlanText(limit, availableReports);

    return this.getAvailabilityText(availableReports, availableInLimitRange, limit, planText);
  }

  buyAdditionalReport() {
    this.router.navigateByUrl('reports/cart/items?reportType=' + this.reportDisplayType);
  }

  buyBusinessPlanUpgrade() {
    this.router.navigateByUrl('reports/cart/items?planType=Business');
  }

  private getAvailabilityText(availableReports, availableInLimitRange, limit, planText) {
    if (this.userPlan?.planConfig.planType === PlanType.Enterprise) {
      return `${availableReports} verbleibend`;
    } else {
      return `${availableInLimitRange} von ${limit}${planText}`;
    }
  }
}
