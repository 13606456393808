import { Component, Input, OnInit } from '@angular/core';
import { BasicReport, ReportType } from '../../../reports.interface';
import { PlanType } from '../../../../user/user-administration.service';
import { UserPlan } from '../../../plan/plan.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss']
})
export class EditButtonComponent implements OnInit {

  @Input() plan: UserPlan;
  @Input() report: BasicReport | undefined;
  @Input() isSnapshotView: boolean;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }


  edit() {
    if (this.report.type === ReportType.TrackingEvent) {
      this.router.navigate(['/reports/pixel/edit/', this.report.id]);
    } else if (this.report.type === ReportType.SegmentEvaluation) {
      this.router.navigate(['/reports/evaluation/edit/', this.report.id]);
    } else {
      this.router.navigate(['/reports/url-event/edit/', this.report.id]);
    }
  }

  canEdit() {
    // undefined guard
    if (!this.plan) {
      return false;
    }

    // undefined guard
    if (!this.report) {
      return false;
    }

    if (this.report.type === ReportType.SegmentEvaluation) {
      return this.plan.planConfig.planType !== PlanType.Free;
    }

    return true;
  }


}
