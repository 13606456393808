import { Component, Input, OnInit, ViewChild } from '@angular/core';
//import { Component, ViewChild } from '@angular/core';
//import { ChartComponent} from 'ng-apexcharts';

import {
  ApexNonAxisChartSeries, //needed
  //ApexStroke,
  //ApexFill,
  ApexPlotOptions, //not needed
  ApexChart, //needed
  ApexLegend, //not needed
  ApexResponsive, //needed
  ChartComponent //not needed
} from 'ng-apexcharts';

export interface ChartOptions {
  series: ApexNonAxisChartSeries; //needed
  chart: ApexChart; //needed
  labels: string[]; //change string to any
  colors: string[]; //not needed
  legend: ApexLegend; //not needed
  plotOptions: ApexPlotOptions; //not needed
  responsive: ApexResponsive[]; //needed
};

@Component({
  selector: 'app-radial-bar',
  templateUrl: './radial-bar.component.html',
  styleUrls: ['./radial-bar.component.scss']
})
export class RadialBarComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  @Input() data;

  ngOnInit() {

    //console.log("this.data radial-bar Geschlecht: ", this.data);

    const series = [Math.round(this.data.userProfilesPercent*100), Math.round(this.data.dataPoolProfilesPercent*100)];

    this.chartOptions = {
      series,
      chart: {
        height: 390,
        type: 'radialBar', //polarArea
      },
      //stroke: {
        //colors: ["#fff"]
      //},
      //fill: {
        //opacity: 0.8
      //}
      //responsive: [
      //  {
      //    breakpoint: 480,
      //    options: {
      //        chart: {
      //            width: 200
      //        },
      //        legend: {
      //            position: "bottom"
      //        }
      //    }
      //  }
      //]


      // rest not needed

      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 0,
            size: '30%',
            background: 'transparent',
            image: undefined
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              show: false
            }
          }
        }
      },
      colors: ['#4bb9e2', '#17748F'],
      labels: ['Deine Audience', 'Datenpool'],
      legend: {
        show: true,
        floating: true,
        fontSize: '16px',
        position: 'left',
        offsetX: -45,
        offsetY: 10,
        labels: {
          useSeriesColors: true
        },
        formatter: (seriesName, opts) => seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex],
        itemMargin: {
          horizontal: 0
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }
      ]
    };
  }

}
