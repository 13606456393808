<div id="content-text-container" *ngIf="!showForm" (click)="clickToEdit()">
  <div class="ai-dynamic-ellipsis" [matTooltip]="inputModel">
    <div>
      {{inputModel}}
      <mat-icon *ngIf="editable">edit</mat-icon>
    </div>
  </div>
</div>
<div id="content-form-container" *ngIf="showForm">
  <mat-form-field>
    <input matInput
           #contentField
           [(ngModel)]="inputModel" />
    <button id="content-submit-button" mat-icon-button (click)="updateContent(inputModel)" matSuffix>
      <mat-icon>save</mat-icon>
    </button>
    <button id="cancel-edit-button" mat-icon-button (click)="showForm = false" matSuffix>
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>
