<div class="">
  <app-chart-bar color="primary"
                [percentage]="primaryPercentage"
                [percentageLabel]="primaryPercentageLabel"
                [align]="align"></app-chart-bar>

  <app-chart-bar *ngIf="secondaryPercentage"
                [percentage]="secondaryPercentage"
                [percentageLabel]="secondaryPercentageLabel"
                [align]="align"></app-chart-bar>
</div>

<div class="chart-segment-label-description-container">
  <div class="chart-segment-label">{{label}}</div>

  <div class="chart-segment-description">
    {{description}}
  </div>
</div>
