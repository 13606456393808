import { Component, Input, OnInit } from '@angular/core';
import { SegmentInfo } from '../../reports.interface';
import { chartsText } from '../charts.texts';

@Component({
  selector: 'app-reach-chart',
  templateUrl: './reach-chart.component.html',
  styleUrls: ['./reach-chart.component.scss']
})
export class ReachChartComponent implements OnInit {
  @Input() chartData: SegmentInfo[] = [];

  readonly text = chartsText;

  data: SegmentInfo[] = [];

  ngOnInit(): void {
    this.data = this.chartData
      .sort((a, b) => b.dataPoolProfilesSize - a.dataPoolProfilesSize);
  }

  getPercentage(value: number): number {
    const reaches = this.data.map(segmentInfo => segmentInfo.dataPoolProfilesSize);
    const max = Math.max(...reaches);
    return Math.abs(100 * value / max);
  }
}
