import { DetailedError } from './reports/reports.interface';

export const toError = (errorObj: any): DetailedError => {

  let errorResult: DetailedError = {error: '', errorCode: 0};

  /* istanbul ignore next */
  if (typeof errorObj === 'string') {
    errorResult.error = errorObj;

  } else if (typeof errorObj === 'number') {
    errorResult.errorCode = errorObj;

  } else if (errorObj.error === undefined || errorObj.error === null) {

    errorResult = {
      error: errorObj.statusText || '',
      errorCode: errorObj.status || 0
    };

  } else {
    errorResult = {
      error: errorObj.error.error || errorObj.error.message || errorObj.message || errorObj.error,
      errorCode: errorObj.error.errorCode || errorObj.error.status || errorObj.errorCode || errorObj.status || 0
    };
  }

  return errorResult;
};
