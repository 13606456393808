import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-topic',
  templateUrl: './chart-topic.component.html',
  styleUrls: ['./chart-topic.component.scss']
})
export class ChartTopicComponent implements OnInit {

  /** Category */
  @Input() category!: string;

  /** Display label. */
  @Input() label!: string;

  /** The name of the data provider. */
  @Input() dataProvider;

  constructor() {
  }

  ngOnInit(): void {
  }

  getProviderClass(): string {
    return this.dataProvider?.toLowerCase() || 'unknown';
  }

}
