import { BannerNotification } from '../../banner/banner.interface';

export const noReportsLeftNotification = (buyReportButtonAction: () => void,
                                          upgradePlanAction: () => void): BannerNotification => ({
  closeable: false,
  flexDirection: 'column',
  message: {
    icon: 'hourglass_empty',
    major: '<h3>Keine weiteren Reporte verfügbar</h3>',
    minor: 'Es verbleiben momentan <strong>0 Reporte</strong> in deinem Abonnement. Bitte erweitere deinen Vertrag oder kaufe einen weiteren Einzelreport.'
  },
  actions: [
    {displayName: 'Vertrag erweitern', actionFn: upgradePlanAction},
    {displayName: 'Report kaufen', actionFn: buyReportButtonAction},
  ]
});
