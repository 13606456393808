import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
/* import { SegmentInfo } from '../../reports.interface';
import { chartsText } from '../charts.texts';
import { ChartConfiguration } from 'chart.js'; */
import { ChartComponent} from 'ng-apexcharts';

import {
    ApexNonAxisChartSeries,
    ApexResponsive,
    ApexChart,
    ApexStroke,
    ApexFill,
  } from "ng-apexcharts";

  export interface ChartOptions {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    responsive: ApexResponsive[];
    labels: any;
    stroke: ApexStroke;
    fill: ApexFill;
  };

@Component({
    selector: 'app-radial-chart',
    templateUrl: './radial-chart.component.html',
    styleUrls: ['./radial-chart.component.scss']
})
export class RadialChartComponent implements OnInit {

    @ViewChild('chart') chart: ChartComponent;
    public chartOptions: Partial<ChartOptions>;

    @Input() data;

    /* dimensions = {
        width: 400,
        height: 400
    };

    public radarChartOptions: ChartConfiguration<'radar'>['options'] = {
        responsive: false,
        scales: {
            r: {
                ticks: {
                    color: 'rgba(0,0,0,0)',
                    display: false
                },
                grid: {
                    color: ctx => ctx.index === 1 ? '#378EA0' : '#f3f3f3',
                    tickWidth: 10,
                }
            }
        }
    };
    public radarChartLabels: string[] = [];

    public radarChartDatasets: ChartConfiguration<'radar'>['data']['datasets'] = [
        {
            data: [],
            pointStyle: false,
            fill: false,
            borderWidth: 1
        }
    ]; */

    ngOnInit() {

        /* this.radarChartLabels = this.data.map(e => e.name);
        this.radarChartDatasets[0].data = this.data.map(e => e.value);

        const dimensionFactor = 30*(this.radarChartLabels.length - 5);
        this.dimensions.width += dimensionFactor;
        this.dimensions.height += dimensionFactor; */

        //console.log("this.data in radial-chart: ", this.data);
        const dataseries = this.data.map(e => Math.round(e.value));
        const label = this.data.map(e => e.name);
        /* console.log("label: ", label);
        console.log("dataseries: ", dataseries); */

        this.chartOptions = {
            series: dataseries,
            chart: {
              type: "polarArea",
              height: 390,
              events: {
                mounted: (chart) => {
                  chart.windowResizeHandler()
                },
                updated: (chart) => {
                  chart.windowResizeHandler()
                }
              }
            },
            labels: label,
            stroke: {
              colors: ["#fff"]
            },
            fill: {
              opacity: 0.8
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ]
        };

        window.dispatchEvent(new Event("resize"))

    }


}
