<div [style.height.px]="size">

  <div class="loading"
       *ngIf="status === Collecting"
       [matTooltip]="getToolTipText()"
       matTooltipPosition="right"
       [ngClass]="{'warn': warn}"
       [style.width.px]="size"
       [style.height.px]="size">
    <div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <mat-icon *ngIf="status === Done" class="done" svgIcon="emq:tracking_box" [style.width.px]="size"  [style.height.px]="size" ></mat-icon>

  <div *ngIf="status === Future" class="future" [style.width.px]="size" [matTooltip]="getToolTipText()"></div>

  <!-- Tracking is not a real status. It is a (class) name hack.  -->
  <div *ngIf="status === Tracking" class="tracking" [style.width.px]="size">
    <div></div>
  </div>
</div>
