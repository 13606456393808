import { Injectable, OnDestroy } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HistoryService implements OnDestroy {
  public subscription: Subscription;
  history: string[] = [];

  constructor(private router: Router) {
    this.subscription = this.router
      .events
      .pipe(filter(event => event instanceof RoutesRecognized))
      .subscribe(({urlAfterRedirects}: RoutesRecognized) => this.history.push(urlAfterRedirects));
  }

  public back() {
    // current activated route is last in stack
    const lastURL = this.history.length > 1 ? this.history[this.history.length - 2] : '/';

    if (this.history.length > 1) {
      this.history.pop();
      this.history.pop();
    }

    if (lastURL === '/logout') {
      this.router.navigateByUrl('/');
    } else {
      this.router.navigateByUrl(lastURL);
    }
  }

  public lastUrl(): string {
    if (this.history.length === 0) {
      return '/';
    }
    return this.history[this.history.length - 1];
  }

  ngOnDestroy() {
    /* istanbul ignore else */
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
