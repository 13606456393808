import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-initial-login',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class JointControllerStepsComponent implements OnInit {

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  isLinkActive(link: string) {
    const activeUrl: string = this.router.routerState.snapshot.url;
    return !activeUrl.endsWith(`/${link}`);
  }
}
