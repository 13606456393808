import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../api/app.api';
import { NotificationService } from '../notification/notification.service';
import { TrackingService } from '../tracking/tracking.service';
import { BasicReport, StartChartsRequest, TrackingEventReport } from './reports.interface';
import { LoadingIndicatorService } from '../loading-indicator/loading-indicator.service';

@Injectable({providedIn: 'root'})
export class ReportsService {

  readonly dateFormat = 'DD.MM.YYYY';

  constructor(private httpClient: HttpClient,
              private notification: NotificationService,
              private loading: LoadingIndicatorService) {
  }

  getReports(): Observable<BasicReport[]> {
    const url = `${environment.apiBasePath}/reports`;
    return this.httpClient
      .get<ApiResponse<BasicReport[]>>(url)
      .pipe(
        map((response) => response.data.map((report) => this.convertReport(report))),
        catchError((error: HttpErrorResponse) => {
          this.notification.sendError('Die Daten konnten nicht geladen werden. Bitte versuche es später erneut!');
          TrackingService.track('getReportsError', error);
          throw error;
        })
      );
  }

  public getReport(id: string): Observable<BasicReport> {
    TrackingService.track('getReportNoStore', id);
    return this.httpClient
      .get<ApiResponse<BasicReport>>(`${environment.apiBasePath}/reports/${id}`)
      .pipe(
        map((r: ApiResponse<BasicReport>) => this.convertReport(r.data)),
        catchError((error) => {
          TrackingService.track('getReportNoStoreError', error);
          throw error;
        }));
  }

  public downloadReport(reportId: any, start?: Date, end?: Date): Observable<any> {
    let uri = `${environment.apiBasePath}/reports/${reportId}?generate=true`;
    if (start) {
      uri += `&start=${start.toISOString()}`;
    }
    if (end) {
      uri += `&end=${end.toISOString()}`;
    }
    const loading = this.loading.displayLoading('Erstelle Report...');

    const req = new HttpRequest(
      'GET',
      uri,
      {
        reportProgress: true,
        responseType: 'blob'
      }
    );

    let fileName;

    return this.httpClient.request(req)
      .pipe(
        finalize(() => {
          loading.close();
        }),
        tap(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.ResponseHeader:
                fileName = this.extractFileName(event.headers.get('content-disposition'));
                break;
              case HttpEventType.Response:
                this.saveFile(event.body, fileName);
            }
          },
          (error: HttpErrorResponse) => {
            this.notification.sendError('Entschuldigung! 😱 Der Report konnten nicht herunter geladen werden. Bitte versuche es später erneut!');
            TrackingService.track('downloadReportError', error);
          })
      )
      ;
  }

  /* istanbul ignore next */
  saveFile(body, fileName: string) {
    saveAs(body, fileName);
  }

  public getPixel(): Observable<any> {
    return this.httpClient.get(`${environment.apiBasePath}/pixel`)
      .pipe(
        map((r: ApiResponse<BasicReport>) => r.data),
        catchError((error: HttpErrorResponse) => {
          TrackingService.track('getPixelError', error);
          /* istanbul ignore else */
          if (this.isConnectionError(error)) {
            this.notification.sendError('Leider ist ein Fehler aufgetreten!');
          }
          throw error;
        })
      );
  }

  public createReport(report: TrackingEventReport | any): Observable<BasicReport> {
    return this.httpClient.put(`${environment.apiBasePath}/reports/tracking-event`, report)
      .pipe(
        map((r: ApiResponse<BasicReport>) => {
          this.notification.sendSuccess('Report erstellt!');
          TrackingService.track('reportCreated', report);
          return this.convertReport(r.data);
        }),
        catchError((error: HttpErrorResponse) => {
          this.notification.sendError('Leider ist ein Fehler aufgetreten!');
          TrackingService.track('reportCreatedError', error.message);
          throw error;
        }));
  }

  public updateReport(report: TrackingEventReport): Observable<BasicReport> {

    return this.httpClient.post(`${environment.apiBasePath}/reports/tracking-event/${report.id}`, report)
      .pipe(
        map((r: ApiResponse<BasicReport>) => {
          this.notification.sendSuccess('Changes saved.');
          TrackingService.track('reportCreated', report);
          return this.convertReport(r.data);
        }),
        catchError((error: HttpErrorResponse) => {
          this.notification.sendError('Leider ist ein Fehler aufgetreten!');
          TrackingService.track('updateReportError', error);
          throw error;
        }));
  }

  generate(reportId: string) {
    this.httpClient.put(`${environment.apiBasePath}/manageReports/regenerate/${reportId}?sync=true`, '')
      .subscribe(
        () => {
          this.notification.sendSuccess('Report generiert!');
        },
        (error: HttpErrorResponse) => {
          this.notification.sendError('Fehler beim generieren  des Reports ' + error.message);
        });
  }

  startReport(startRequest: StartChartsRequest): Promise<void | Error> {
    return new Promise((resolve, reject) => {
      TrackingService.track('startReportWithPeriod', startRequest);
      this.httpClient.post(`${environment.apiBasePath}/reports`, startRequest).subscribe(
        () => {
          this.notification.sendSuccess('Erstellung des Reports gestartet');
          resolve();
        },
        (error: HttpErrorResponse) => {
          this.notification.sendError('Erstellung des Reports fehlgeschlagen');
          TrackingService.track('startReportWithPeriodFailed', {request: startRequest, error});
          reject(error);
        });
    });
  }

  private extractFileName(dispositionHeader) {
    let fileName;
    if (dispositionHeader) {
      fileName = dispositionHeader.split(';')[2].trim().split('=')[1].replace(new RegExp('"', 'g'), '');
    } else {
      fileName = `report_${new Date(this.dateFormat).toString()}.xslx`;
    }
    return fileName;
  }

  private isConnectionError(error: HttpErrorResponse) {
    return [503, 504].includes(error.status);
  }

  private convertReport<T extends BasicReport>(raw: T): T {
    /* istanbul ignore else */
    if (typeof raw.modified === 'string') {
      raw.modified = new Date(raw.modified);
    }
    /* istanbul ignore else */
    if (typeof raw.created === 'string') {
      raw.created = new Date(raw.created);
    }
    return raw;
  }

}
