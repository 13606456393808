import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { ErrorHandler, NgModule, Component } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HIGHLIGHT_OPTIONS, HighlightModule, HighlightOptions } from 'ngx-highlightjs';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { AuthModule } from './auth/auth.module';
import { AuthService } from './auth/auth.service';
import { AuthGuard, ConfirmGuard, JointControllerGuard, UserManagerGuard } from './auth/guards';
import { HttpAuthInterceptor } from './auth/httpauth.interceptor';
import { BannerModule } from './banner/banner.module';
import { FeedbackModule } from './feedback/feedback.module';
import { HealthModule } from './health/health.module';
import { IntegrationComponent } from './help/resource/integration.component';
import { WindowRef } from './helpers/window.injectable';
import { IconsModule } from './icons/icons.module';
import { JointControllerModule } from './joint-controller/joint-controller.module';
import { KpiModule } from './kpi/kpi.module';
import { BreadcrumbModule } from './layout/breadcrumb/breadcrumb.module';
import { FooterModule } from './layout/footer/footer.module';
import { LayoutFormModule } from './layout/form/form.module';
import { HeaderModule } from './layout/header/header.module';
import { LiveEventsModule } from './live-events/live-events.module';
import { AuthServiceMock } from './mock/auth.service.mock';
import { SharedModule } from './modules/shared.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { NoteModule } from './note/note.module';
import { NotificationModule } from './notification/notification.module';
import { ScrollService } from './scroll/scroll.service';
import { StyleGuideComponent } from './style-guide/style-guide.component';
import { StyleGuideModule } from './style-guide/style-guide.module';
import { ErrorTracking } from './tracking/error-tracking';
import { UndoService } from './undo/undo.service';
import { UserModule } from './user/user.module';
import { LoadingIndicatorModule } from './loading-indicator/loading-indicator.module';
import { RadialChartComponent } from './radial-chart/radial-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { ReportsModule } from './reports/reports.module';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

registerLocaleData(localeDe, 'de-DE');

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    IntegrationComponent,
    StyleGuideComponent,
    RadialChartComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    // IN_MEMORY_DB_IMPORTS,
    environment.animation ? BrowserAnimationsModule : NoopAnimationsModule,
    SharedModule,
    AppRoutingModule,
    FooterModule,
    BreadcrumbModule,
    IconsModule,
    AuthModule,
    HeaderModule,
    LayoutFormModule,
    NotificationModule,
    NoteModule,
    HealthModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.enableServiceWorker}),
    StyleGuideModule,
    FeedbackModule,
    HighlightModule,
    UserModule,
    BannerModule,
    JointControllerModule,
    KpiModule,
    LiveEventsModule,
    LoadingIndicatorModule,
    NgChartsModule,
    ReportsModule,
    BrowserAnimationsModule,
  ],
  providers: [
    UndoService,
    ScrollService,
    AuthGuard,
    ConfirmGuard,
    UserManagerGuard,
    JointControllerGuard,
    WindowRef,
    {provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true},
    {provide: ErrorHandler, useClass: ErrorTracking},
    {provide: AuthService, useClass: environment.useAuthServiceMock ? AuthServiceMock : AuthService},
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
        lineNumbers: true
      } as HighlightOptions
    }
  ],
  exports: [],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}

