import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SharedModule } from '../../modules/shared.module';
import { QualityIndicatorSmallComponent } from './quality-indicator-small/quality-indicator-small.component';

@NgModule({
  declarations: [
    QualityIndicatorSmallComponent,
  ],
  providers: [],
  imports: [
    CommonModule,
    MatProgressBarModule,
    SharedModule
  ],
  exports: [QualityIndicatorSmallComponent]
})
export class QualityIndicatorModule {
}
