<div class="data-container">
  <app-svg-arc
    class="bottom"
    [width]="width"
    [height]="width"
    [centre]="[radius, radius]"
    [radii]="[radius, radius]"
    [strokeWidth]="50"
    [strokeColor]="'#4bb9e2'"
    [sweepAngle]="sweepAngle"
    [displayValue]="percentage"
  ></app-svg-arc>
  <div class="overlay" [attr.style]="'--size-factor: ' + sizeFactor">
    <!-- <app-svg-arc
      class="absolute z-0"
      [width]="overlayDimensions[0]"
      [height]="overlayDimensions[0]"
      [centre]="overlayRadii"
      [radii]="overlayRadii"
      [strokeWidth]="10"
      [fill]="'white'"
    ></app-svg-arc> -->
    <div class="chart-segment-label-description-container">
      <div class="chart-segment-label">{{label}}</div>
    
      <div class="chart-segment-description">
        {{description}}
      </div>
    </div>
  </div>
</div>
