import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PlanType, SignUpRequest, UserAdministrationService } from '../user-administration.service';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss']
})
export class InviteUserComponent implements OnInit {

  country = new FormControl('', Validators.required);

  formGroup = new FormGroup({
    type: new FormControl(PlanType.Enterprise, Validators.required),
    info: new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', Validators.email),
      telephone: new FormControl('', Validators.required)
    }),
    address: new FormGroup({
      company: new FormControl('', Validators.required),
      street: new FormControl('', Validators.required),
      postcode: new FormControl('', [Validators.required, Validators.minLength(5), Validators.pattern('[0-9]*')]),
      city: new FormControl('', Validators.required),
      country: this.country,
      vatid: new FormControl('', Validators.required)
    })
  });

  disableButton = false;

  plans = Object.keys(PlanType);

  constructor(private userService: UserAdministrationService) {
  }

  ngOnInit(): void {
  }

  submit() {
    this.disableButton = true;
    const signUpRequest = this.formGroup.value as SignUpRequest;
    this.userService.inviteUser(signUpRequest)
      .then(() => {
        this.formGroup.get('info').reset();
        this.formGroup.get('address').reset();
        this.disableButton = false;
      })
      .catch(() => {
        this.disableButton = false;
      });
  }

}
