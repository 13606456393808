/* istanbul ignore file */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangePasswordComponent } from './auth/change/change-password.component';
import { ConfirmComponent } from './auth/confirm/confirm.component';
import { ForgotComponent } from './auth/forgot/forgot.component';
import { AuthGuard, ConfirmGuard } from './auth/guards';
import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { ResetComponent } from './auth/reset/reset.component';
import { HealthComponent } from './health/health/health.component';
import { IntegrationComponent } from './help/resource/integration.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { StyleGuideComponent } from './style-guide/style-guide.component';
import { AccountComponent } from './user/account/account.component';
import { JointControllerStepsComponent } from './joint-controller/steps/steps.component';
import { JointControllerFinishComponent } from './joint-controller/finish/finish.component';
import { JointControllerSignComponent } from './joint-controller/sign/sign.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'reports?type=tracking',
    pathMatch: 'full'
  },
  {path: 'login', component: LoginComponent},
  {path: 'logout', component: LogoutComponent},
  {path: 'forgot', component: ForgotComponent},
  {path: 'reset', component: ResetComponent},
  {path: 'changePassword', component: ChangePasswordComponent, canActivate: [AuthGuard]},
  {path: 'confirm', component: ConfirmComponent, canActivate: [ConfirmGuard]},
  {path: 'health', component: HealthComponent},
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Benutzerkonto', activateUserMenu: true}
  },
  {
    path: 'user-management',
    loadChildren: () => import('app/user/user.module').then(m => m.UserModule),
    data: {
      activateUserMenu: true,
      showBreadcrumb: false
    }
  },
  {
    path: 'kpi',
    loadChildren: () => import('app/kpi/kpi.module').then(m => m.KpiModule),
    data: {
      activateUserMenu: true,
      showBreadcrumb: false
    }
  },
  {
    path: 'integration',
    component: IntegrationComponent,
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Integration', activateUserMenu: true}
  },
  {
    path: 'reports',
    loadChildren: () => import('app/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'legal',
    loadChildren: () => import('app/legal/legal.module').then(m => m.LegalModule),
    data: {
      breadcrumb: null
    }
  },
  {
    path: 'style-guide',
    component: StyleGuideComponent
  },
  {
    path: 'joint-controller',
    component: JointControllerStepsComponent,
    canActivate: [],
    data: {showBreadcrumb: false},
    children: [
      {path: '', redirectTo: 'confirm', pathMatch: 'full'},
      {path: 'confirm', component: ConfirmComponent, canActivate: [ConfirmGuard]},
      {path: 'sign', component: JointControllerSignComponent, canActivate: [AuthGuard]},
      {path: 'finish', component: JointControllerFinishComponent, canActivate: [AuthGuard]}
    ]
  },
  {path: 'loading', component: LoadingIndicatorComponent},
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {

}