import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DatePipe } from '../helpers/date.pipe';
import { NumberLocalePipe } from '../helpers/number.pipe';
import { NoteModule } from '../note/note.module';
import { TrackingService } from '../tracking/tracking.service';
import { MaterialModule } from './material.module';

const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialModule,
  NoteModule
];

const DECLARATIONS = [
  DatePipe,
  NumberLocalePipe
];

@NgModule({
  imports: [
    MODULES,
  ],
  exports: [
    MODULES,
    DECLARATIONS
  ],
  declarations: [
    DECLARATIONS
  ],
  providers: [
    TrackingService
  ]
})
export class SharedModule {
}
