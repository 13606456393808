import { Component, Input, OnInit } from '@angular/core';
import { ShoppingCartService } from '../../shopping-cart.service';

@Component({
  selector: 'app-shopping-cart-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class CartItemComponent implements OnInit {

  @Input() name = '';
  @Input() description = '';
  @Input() icon = '';
  @Input() price: any = '';
  @Input() quantity;
  @Input() isSubscription = false;

  constructor(private shoppingCartService: ShoppingCartService) {
  }

  ngOnInit(): void {
  }

  amountChange(value: any) {
    this.shoppingCartService.updateCurrentOrderQuantity(value);
  }
}
