import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../user/user.interface';
import { ReportDisplayType } from '../../reports.interface';
import { InvoiceAddress, ShoppingCartOrder, ShoppingCartService } from '../shopping-cart.service';
import { HistoryService } from '../../../helpers/history.service';
import { UserService } from '../../../user/user.service';
import { NotificationService } from '../../../notification/notification.service';

@Component({
  selector: 'app-confirm-order',
  templateUrl: './confirm-order.component.html',
  styleUrls: ['./confirm-order.component.scss']
})
export class ConfirmOrderComponent implements OnInit {

  order: ShoppingCartOrder;
  user: User;
  invoiceAddress: InvoiceAddress;
  reportDisplayType: ReportDisplayType;
  isEditUserData: boolean;

  constructor(
    private shoppingCartService: ShoppingCartService,
    private userService: UserService,
    private notification: NotificationService,
    private router: Router,
    private history: HistoryService) {
  }

  ngOnInit(): void {

    this.shoppingCartService.getOrderObservable().subscribe((order) => {
      this.order = order;
      if (!this.order) {
        this.back();
      }
    });

    this.userService.getUser()
      .subscribe((user: User) => {
        this.user = user;

        this.invoiceAddress = {
          firstName: user.info?.firstName,
          lastName: user.info?.lastName,
          companyName: user.address?.company,
          postcode: user.address?.postcode,
          streetAndNumber: user.address?.street,
          city: user.address?.city,
          country: user.address?.country,
          vatId: user.address?.vatid
        };
      });

    this.reportDisplayType = this.shoppingCartService.getReportDisplayType();

    this.isEditUserData = false;
  }

  back() {
    this.history.back();
  }

  buyNow() {
    this.shoppingCartService.submitOrder(this.invoiceAddress)
      .then(() => {
        const isUpgrade = this.shoppingCartService.isUpgradePlanOrder();
        const reportDisplayType = this.shoppingCartService.getReportDisplayType();
        this.shoppingCartService.clearCart(); // move this to the service
        this.router.navigate(['/reports/cart/success'], {
          queryParams: {
            isUpgradePlanOrder: isUpgrade,
            reportDisplayType
          }
        });
      })
      .catch(() => this.notification.sendError('Fehler beim Absenden der Bestellung. Bitte versuche es später erneut.'));
  }

  showEditUserData(value: boolean) {
    this.isEditUserData = value;
  }

  setNewAddress(value: InvoiceAddress) {
    this.invoiceAddress = value;
  }

  isUpgradePlan() {
    return this.shoppingCartService.isUpgradePlanOrder();
  }

  isInvoiceDataValid(): boolean {
    if (!this.invoiceAddress) {
      return false;
    }
    for (const key in this.invoiceAddress) {
      if (this.isNullOrUndefinedOrBlank(this.invoiceAddress[key])) {
        return false;
      }
    }
    return true;
  }

  private isNullOrUndefinedOrBlank(value): boolean {
    return value == null || value === '';
  }
}
