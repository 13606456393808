import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-radial-chart',
  templateUrl: './radial-chart.component.html',
  styleUrls: ['./radial-chart.component.scss']
})
export class RadialChartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
