import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UserAdministrationService } from '../user-administration.service';
import { User } from '../user.interface';
import { NotificationService } from '../../notification/notification.service';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class UserManagementComponent implements OnInit {
  displayedColumns: string[] = ['email', 'username', 'address', /* 'aiId',*/ 'sids', 'privatePool', 'action'];

  users: User[] = [];

  isFeatureShown = true;

  loading = false;

  constructor(
    private userService: UserAdministrationService,
    private notify: NotificationService) {
  }

  ngOnInit() {
    this.fetchUsers();
  }

  onUserUpdated() {
    this.fetchUsers();
  }

  updateSids(sids: string, user: User) {
    const sidsAsList = sids.split(',').map((sid) => sid.trim());
    this.userService
      .updateSids(user.userId, sidsAsList)
      .subscribe(() => {
        this.notify.sendSuccess('SIDs geändert!');
      }, (error) => {
        this.notify.sendError(`Änderung der SIDs fehl geschlagen! ${error.message}`);
      });
  }

  updatePrivatePool(privatePool: boolean, user: User): void {
    user.privatePool = privatePool;

    this.userService.updatePrivatePool(user.userId, privatePool)
      .subscribe(() => {
        this.notify.sendSuccess('Private-Pool geändert!');
      }, (error) => {
        this.notify.sendError(`Die Änderung des Private-Pool ist fehlgeschlagen! ${error.message}`);
      });
  }

  private fetchUsers() {
    this.loading = true;
    this.userService.getUsers()
      .pipe(finalize(() => this.loading = false))
      .subscribe((users) => this.users = users);
  }
}
