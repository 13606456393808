<app-page>
  <form [formGroup]="resetPasswordForm" (submit)="onSubmit()">
    <app-note>
      Du solltest eine E-Mail mit deinem Bestätigungscode erhalten haben. Trage diesen Bestätigungscode hier ein und
      erstelle danach ein neues Passwort für dein Konto.
    </app-note>
    <div class="form-input-container">
      <mat-form-field>
        <input name="confirmation"
               placeholder="Bestätigungscode"
               matInput
               required
               formControlName="confirmationCode" autocomplete="off">
        <mat-error *ngIf="resetPasswordForm.get('confirmationCode').touched &&
                          resetPasswordForm.get('confirmationCode').hasError('required')">
          <span>Bitte gebe deinen Bestätigungscode ein.</span>
        </mat-error>
      </mat-form-field>
    </div>
    <div [formGroup]="passwordFormGroup">
      <div class="form-input-container">
        <app-password-strength [control]="passwordFormGroup.get('password1')"></app-password-strength>
        <mat-form-field>
          <input name="password1"
                 placeholder="Passwort"
                 matInput
                 type="password"
                 formControlName="password1">
        </mat-form-field>
      </div>
      <div class="form-input-container">
        <mat-form-field>
          <input name="password2"
                 placeholder="Passwort bestätigen"
                 matInput
                 type="password"
                 formControlName="password2"
                 [errorStateMatcher]="parentErrorStateMatcher">
          <mat-error
            *ngIf="passwordFormGroup.get('password2').touched && passwordFormGroup.hasError('matchOther')">
            <span>Deine Passwörter stimmen nicht überein.</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <button [disabled]="resetPasswordForm.invalid" color="primary" id="reset-password-button" mat-flat-button
            type="submit">
      Absenden
    </button>
  </form>
</app-page>
