import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReportSourceComponent } from './report-source.component';

@NgModule({
  declarations: [ReportSourceComponent],
  exports: [
    ReportSourceComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule
  ]
})
export class ReportSourceModule { }
