import { Component, Input, OnInit } from '@angular/core';
import { RetentionStats } from '../kpi.service';

@Component({
  selector: 'app-kpi-table',
  templateUrl: './kpi-table.component.html',
  styleUrls: ['./kpi-table.component.scss']
})
export class KpiTableComponent implements OnInit {
  @Input() retentionStats: RetentionStats = {registeredCohorts: []};

  displayedColumns: string[] = ['date', 'users'];

  weeks: string[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.createWeeks();
  }

  createWeeks(): void {
    for (let i = 0; i < this.retentionStats.registeredCohorts.length; i++) {
      this.weeks.push(`week${i + 1}`);
    }
    this.displayedColumns.push(...this.weeks);
  }

  getBackgroundColor(rate?: number): string {
    if (rate === 0) {
      return 'rgba(230, 172, 224, 0.2)';
    }
    return rate != null ? `rgba(23, 172, 224, ${(rate / 100) + .1})` : '';
  }

}
