<app-form-container headline="Neue Benutzer einladen" icon="person_add">
  <article class="centered">
    <section>
      <div class="centered-content">
        <form [formGroup]="formGroup">
          <mat-form-field>
            <input [matAutocomplete]="auto" formControlName="type" matInput placeholder="Vertragstyp" required
                   type="text">
            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
              <mat-option *ngFor="let plan of plans" [value]="plan">
                {{plan}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <app-note>
            Für Publisher die das Profiling Script mit einer SID nutzen - Bitte wähle den Typ
            <strong>Enterprise</strong>!
          </app-note>
          <div formGroupName="info">
            <mat-form-field>
              <input formControlName="firstName" matInput placeholder="Vorname" required>
            </mat-form-field>
            <mat-form-field>
              <input formControlName="lastName" matInput placeholder="Nachname" required>
            </mat-form-field>
            <mat-form-field>
              <input formControlName="email" matInput placeholder="E-Mail" required>
            </mat-form-field>
            <mat-form-field>
              <input formControlName="telephone" matInput placeholder="Telefon" required>
            </mat-form-field>
          </div>
          <div formGroupName="address">
            <mat-form-field>
              <input formControlName="company" matInput placeholder="Firma" required>
            </mat-form-field>
            <mat-form-field>
              <input formControlName="street" matInput placeholder="Straße" required>
            </mat-form-field>
            <mat-form-field>
              <input formControlName="postcode" matInput placeholder="PLZ" required>
            </mat-form-field>
            <mat-form-field>
              <input formControlName="city" matInput placeholder="Stadt" required>
            </mat-form-field>
            <app-country-select id="country" [control]="country"></app-country-select>
            <mat-form-field>
              <input formControlName="vatid" matInput placeholder="Steuernummer" required>
            </mat-form-field>
          </div>
        </form>
        <button (click)="submit()" [disabled]="!formGroup.valid || disableButton" class="submit" color="primary"
                mat-flat-button
                type="submit">
          Benutzer einladen
        </button>
        <app-note>
          Eingeladene Benutzer erhalten eine E-Mail mit einem vorläufigen (initialen) Passwort.
          Auch bestehende Benutzer erhalten eine E-Mail und werden auf ein "temporäres Passwort" umgestellt.
          Bestehende Reporte bleiben dabei erhalten.
        </app-note>
      </div>
    </section>
  </article>
</app-form-container>
