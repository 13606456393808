import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HorizontalComponent } from './horizontal/horizontal.component';
import { HorizontalBarComponent } from './horizontal-bar/horizontal-bar.component';



@NgModule({
  declarations: [
    HorizontalComponent,
    HorizontalBarComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HorizontalComponent,
    HorizontalBarComponent
  ]
})
export class StackedBarModule { }
