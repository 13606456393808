import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { AuthModule } from '../../auth/auth.module';
import { BannerModule } from '../../banner/banner.module';
import { LayoutFormModule } from '../../layout/form/form.module';
import { PageModule } from '../../layout/page/page.module';
import { ReportCreateComponent } from './report-create.component';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    AuthModule,
    BannerModule,
    PageModule,
    LayoutFormModule,
    MatTabsModule
  ],
  declarations: [
    ReportCreateComponent
  ],
  exports: [ReportCreateComponent]
})
export class ReportCreateModule {
}
