import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { routerAnimation } from './app.animation';
import { HistoryService } from './helpers/history.service';
import { LayoutService } from './layout/layout.service';
import { ScrollService } from './scroll/scroll.service';
import { TrackingService } from './tracking/tracking.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [routerAnimation]
})
export class AppComponent implements OnDestroy {
  scrollSubscription: Subscription;
  displaySimplePage = false;
  simplePageTitle = '';
  scrollPosition = 0;

  constructor(private trackingService: TrackingService,
              private history: HistoryService,
              private router: Router,
              private layoutService: LayoutService,
              private title: Title,
              private scrollService: ScrollService) {

    trackingService.event('pageLoad');

    this.setTitle(title);

    this.scrollSubscription = this.layoutService.onRoutingData().subscribe((data) => {
      /* istanbul ignore else */
      if (data) {
        this.displaySimplePage = data.sharedChart === true;
        this.simplePageTitle = data.title;
      }
    });

    this.scrollService.scrolled.subscribe(() => this.scrollContent());
  }

  scrollContent() {
    window.scroll(0, 0);
  }

  ngOnDestroy(): void {
    this.scrollSubscription.unsubscribe();
  }

  private setTitle(title: Title) {
    /* istanbul ignore next */
    if (environment.stage.toLowerCase() === 'dev') {
      title.setTitle(`DEV ${title.getTitle()}`);
    } else if (environment.stage.toLowerCase() === 'hmr') {
      title.setTitle(`HMR ${title.getTitle()}`);
    } else if (environment.stage.toLowerCase() === 'prototype') {
      title.setTitle(`PROTO ${title.getTitle()}`);
    }
  }
}
