<section>
  <mat-icon class="mat-icon-xxl" color="primary">check_circle</mat-icon>
  <span>Vielen Dank!</span>
  <div class="plan-upgrade" *ngIf="isUpgradePlanOrder">
    <span>Dein Vertrag wurde erweitert.</span>
    <button color="primary" mat-flat-button routerLink="/reports">Weiter</button>
  </div>

  <div class="report-upgrade" *ngIf="reportDisplayType !== undefined && !isUpgradePlanOrder">
    <span>Deine Reporte wurden erweitert.</span>
    <button [queryParams]="{type: reportDisplayType}" color="primary" mat-flat-button routerLink="/reports">Weiter
    </button>
  </div>
</section>
