import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { NotificationService } from '../../notification/notification.service';
import { User } from '../../user/user.interface';
import { ProfilingScript } from './profiling-script';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { InsightsScript } from './insights-script';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-user-resource',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss']
})
export class IntegrationComponent implements OnInit {
  user: User;
  scriptTags: string[] = [];
  codeBlock: string;
  showNotification = false;
  isQSUser = false;

  constructor(private notification: NotificationService,
              private userService: UserService,
              private clipBoard: ClipboardService,
              private authService: AuthService) {
    this.isQSUser = this.authService.isInQSGroup();
  }

  ngOnInit() {
    this.scriptTags = [];

    this.userService.getUser().subscribe((user) => {
      this.user = user;

      if (!this.user.hasClassicIntegration) {
        this.scriptIntegration();
      } else {
        this.classicScriptIntegration();
      }

      this.codeBlock = `<html>
  <head></head>
  <body>
    <!-- .... -->
    ${this.scriptTags[0]}
  </body>
</html>`;

      this.clipBoard.copyResponse$.subscribe((response: IClipboardResponse) => {
        if (response.isSuccess) {
          this.copyScriptSuccess();
        } else {
          this.copyScriptFail();
        }
      });

    });
  }

  classicScriptIntegration() {
    let sidsToRender = ['<your sId>'];
    if (this.user && this.user.sids) {
      sidsToRender = this.user.sids.map(sid => sid.trim());
    }

    sidsToRender.forEach((sId) => this.scriptTags.push(
      ProfilingScript.sid(sId).withPrivatePool(this.user && this.user.privatePool === true).toPixelAsLoaderScript())
    );
  }

  scriptIntegration() {
    this.scriptTags.push(
      new InsightsScript(this.user.aiId).asString()
    );
  }

  copyScriptToClipboard(index: number): void {
    this.clipBoard.copyFromContent(this.scriptTags[index]);
  }

  copyScriptSuccess() {
    this.notification.sendSuccess('Script wurde in die Zwischenablage kopiert.');
  }

  copyScriptFail() {
    this.notification.sendError('Das Kopieren des Scripts ist fehlgeschlagen!');
  }

}
