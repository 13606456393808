import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'toLocaleNumber'})
export class NumberLocalePipe implements PipeTransform {

  transform(value: number | string, locale: any = 'de-DE'): string {
    const num = Number(value);
    return isNaN(num) ? String(value) : `${num.toLocaleString(locale)}`;
  }

}
