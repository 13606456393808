import { Injectable } from '@angular/core';
import { Observable, Subscriber, throwError } from 'rxjs';
import { Log } from '../helpers/log';
import { AuthService } from '../auth/auth.service';

// Example usage:
//  https://developer.mozilla.org/en-US/docs/Web/API/Server-sent_events/Using_server-sent_events

// https://github.com/EventSource/eventsource

@Injectable()
export class EventSourceClient {

  constructor(private log: Log, private auth: AuthService) {
  }

  createEventSource(url: string, token?: string): EventSource {
    let query = url;
    if (token) {
      query += `?token=${token}`;
    }
    return new EventSource(query);
  }

  get<D>(url: string): Observable<D> {

    const user = this.auth.getCognitoUser();

    if (user == null) {
      return throwError('Missing user');
    }

    return new Observable((observer: Subscriber<D>) => {
      let eventSourceWithToken: EventSource;

      user.getSession((error, session) => {
        if (error) {
          observer.error(error);
        } else {
          const token = session.getIdToken().getJwtToken();
          eventSourceWithToken = this.createEventSource(url, token);

          eventSourceWithToken.addEventListener('message', message => {
            this.log.log('# EventSource message:', message);
            observer.next(message.data);
          });
          eventSourceWithToken.addEventListener('error', eventSourceError => {
            this.log.log('# EventSource error:', eventSourceError);
            observer.error(eventSourceError);
          });
        }
      });

      return () => {
        this.log.log('# Closing eventSource:', eventSourceWithToken);
        return eventSourceWithToken?.close();
      };
    });

  }

}
