import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PlanType, UpdateUserRequest, UserAdministrationService } from '../../user-administration.service';
import { User } from '../../user.interface';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  country = new FormControl('', Validators.required);

  formGroup = new FormGroup({
    type: new FormControl('', Validators.required),
    info: new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl(''),
      telephone: new FormControl('', Validators.required)
    }),
    address: new FormGroup({
      company: new FormControl('', Validators.required),
      street: new FormControl('', Validators.required),
      postcode: new FormControl('', [Validators.required, Validators.minLength(5), Validators.pattern('[0-9]*')]),
      city: new FormControl('', Validators.required),
      country: this.country,
      vatid: new FormControl('', Validators.required)
    }),
    contractEndDay: new FormControl('', Validators.required)
  });

  userId: string;
  user: User;
  plans = Object.keys(PlanType);
  disableButton = false;
  notFoundError = false;
  planType = PlanType;
  showContractEndDate = false;

  constructor(private activatedRoute: ActivatedRoute, private userService: UserAdministrationService) {
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(paramMap => {
      this.handlePlanTypeChange();
      this.userId = paramMap.get('id');

      this.userService.getUser(this.userId)
        .subscribe(
          (user: User) => {
            if (user) {
              this.user = user;
              if (user.type) {
                this.formGroup.patchValue({
                  type: user.type,
                  address: user.address,
                  info: user.info,
                  contractEndDay: user.contractEndDay.toString()
                });
              }
            } else {
              // todo should only occur when user exists in cognito but not in User backend, is used by e2e?
              this.getUserFromCognito();
            }
          },
          () => {
            this.getUserFromCognito();
          });
    });
  }

  submit() {
    this.disableButton = true;
    const signUpRequest = this.formGroup.value as unknown as UpdateUserRequest;
    // email must be set in case of the user is cognito only
    signUpRequest.info.email = this.user.info.email; // todo ???
    this.userService.updateUser(signUpRequest, this.userId)
      .then(() => {
        this.disableButton = false;
      })
      .catch(() => {
        this.disableButton = false;
      });
  }

  private getUserFromCognito() {
    this.userService.getUsers().subscribe((users) => {
      this.user = users.find(it => it.userId === this.userId);
      if (this.user) {
        this.formGroup.patchValue({address: {company: this.user.address?.company}});
      }
    });
  }

  private handlePlanTypeChange() {
    this.formGroup.get('type').valueChanges.subscribe((value) => {
      if (value === PlanType.Enterprise) {
        this.showContractEndDate = true;
        this.formGroup.setControl('contractEndDay', new FormControl('', Validators.required));
      } else {
        this.showContractEndDate = false;
        this.formGroup.setControl('contractEndDay', new FormControl());
      }
      this.formGroup.updateValueAndValidity();
    });
  }
}