import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const user = this.authService.getCognitoUser();

    if (req.url.includes('/tracking/')) {
      return next.handle(req);
    }

    if (user !== null) {
      return new Observable<HttpEvent<any>>((observer) => {

        user.getSession((cognitoError, cognitoSession) => {
          if (cognitoError) {
            this.authService.logout();
            observer.error(cognitoError);
          } else {
            const token = cognitoSession.getIdToken().getJwtToken();

            next.handle(req.clone({headers: req.headers.set('Authorization', `Bearer ${token}`)}))
              .pipe(finalize(() => {
                observer.complete();
              }))
              .subscribe(
                httpEvent => observer.next(httpEvent),
                err => observer.error(err)
              );
          }

        });

      });
    }

    return next.handle(req);
  }
}
