<div class="navigation d-flex flex-column align-items-stretch"
     [class.folded]="!expanded">

  <div class="navigation-link-container">
    <div *ngFor="let category of categories" class="category-link-hover">
      <div [class.active]="category.active" class="navigation-link d-flex flex-row">
        <div class="navigation-link-hover"></div>
        <div class="navigation-link-caption d-flex flex-row">
            <span (click)="scrollTo(category.scrollRef)">{{category.label}}</span>
            <ul>
              <li *ngFor="let headline of category.children">
                <span [class.active]="headline.active" (click)="showElement(headline.scrollRef)">{{headline.label}}</span>
              </li>
            </ul>
        </div>
      </div>
    </div>
    <div class="d-flex ai-pointer justify-content-end"
         (click)="toggleView()">
      <mat-icon *ngIf="!expanded">chevron_right</mat-icon>
      <mat-icon *ngIf="expanded">chevron_left</mat-icon>
    </div>
  </div>

</div>
