import { ApplicationRef, Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { environment } from '../../environments/environment';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-sw-update',
  templateUrl: './sw-update.component.html',
  styleUrls: ['./sw-update.component.scss']
})
export class SwUpdateComponent implements OnInit {

  updateAvailable = false;

  constructor(private updates: SwUpdate, private appRef: ApplicationRef) {
  }

  ngOnInit(): void {
    console.log('ServiceWorker:', environment.enableServiceWorker, 'updates enabled:', this.updates.isEnabled);
    /* istanbul ignore next */
    if (environment.enableServiceWorker && this.updates.isEnabled) {
      const minute = 1000 * 60 * 5;
      concat(
        this.appRef.isStable.pipe(first(isStable => isStable === true)),
        interval(minute)).subscribe(() => this.updates.checkForUpdate()
      );
    }

    /* istanbul ignore next */
    this.updates.activated.subscribe(event => {
      console.log('Old version:', event.previous, 'New version:', event.current);
    });

    /* istanbul ignore next */
    this.updates.available.subscribe(event => {
      console.log('Update available! Current version:', event.current, 'available version:', event.available);
      this.updateAvailable = true;
    });
  }

  /* istanbul ignore next */
  reload() {
    location.reload();
  }
}
