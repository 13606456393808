import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datePipe'
})
export class DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const dateString = new Date(value).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});
      if (dateString === 'Invalid Date') {
        return value;
      }
      return dateString;
    }
    return 'Unbefristet';
  }

}
