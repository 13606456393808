<div class="header-container">
  <div class="d-flex justify-content-between header-inner-container">
    <div class="px-3">
      <a routerLink="/">
        <div class="logo"></div>
      </a>
    </div>

    <ng-container *ngIf="displaySimplePage">
      <div class="d-flex">
        <div id="simple-page-title" class="align-items-end p-4">
          {{simplePageTitle}}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!displaySimplePage">
      <div class="px-3">
        <app-sw-update></app-sw-update>
      </div>

      <div class="d-flex">
        <div *ngIf="isAuthenticated()" class="reports-navigation d-flex align-items-end">
          <a [queryParams]="{type: 'tracking'}"
             routerLink="/reports"
             routerLinkActive="selected"
             class="ai-menu-button">
            Tracking
          </a>
          <a [queryParams]="{type: 'evaluation'}"
             routerLink="/reports"
             routerLinkActive="selected"
             class="ai-menu-button">
            Evaluation
          </a>
          <app-user-account-menu></app-user-account-menu>
        </div>
      </div>
    </ng-container>
  </div>

  <app-breadcrumb *ngIf="isAuthenticated() && !displaySimplePage"></app-breadcrumb>
</div>
