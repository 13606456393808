import { Injectable } from '@angular/core';

/* istanbul ignore next */
@Injectable()
export class WindowRef {

  fakeWidth;

  get nativeWindow(): any {
    return window;
  }

}
