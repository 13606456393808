import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'evaluationSearch'
})
export class EvaluationSearchPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    return items.filter((it) => {
      const searchArray = [];
      if(it.segments !== undefined) {
        searchArray.push(it.name, it.nameEn);
        it.segments.map((segment) => searchArray.push(segment.text, segment.eId));
      } else {
        searchArray.push(it.text, it.eId);
      }
      return searchArray.join(' ')
        .toLowerCase()
        .search(searchText.toLocaleLowerCase()) !== -1;
    });
  }
}
