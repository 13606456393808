import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoryService } from '../../helpers/history.service';
import { TrackingService } from '../../tracking/tracking.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../auth.component.scss']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  errorMessage: string;

  constructor(private authService: AuthService,
              private trackingService: TrackingService,
              private history: HistoryService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.errorMessage = null;

    /* istanbul ignore else */
    if (this.activatedRoute.snapshot.queryParams['email']) {
      this.username = this.activatedRoute.snapshot.queryParams['email'];
    }

    this.authService.checkAuthenticationStatus()
      .then((isSessionValid: boolean) => {
        /* istanbul ignore next */
        if (isSessionValid) {
          this.router.navigate(['/']);
          this.trackingService.event('loginOnInitSessionValid');
        } else {
          this.trackingService.event('loginOnInitSessionNotValid');
        }
      })
      .catch((error: Error) => {
        TrackingService.track('loginCheckAuthStatusError', error);
      });
  }

  onSuccess() {
    this.trackingService.event('login');
    this.history.back();
  }

  onError(err) {
    this.trackingService.event('loginFail', err);
    this.errorMessage = 'Anmeldung fehlgeschlagen!';
  }

  onLogin() {
    if (this.username == null || this.password == null) {
      this.errorMessage = 'Alle Felder sind erforderlich';
    } else {
      this.errorMessage = null;
      this.authService
        .authenticate(this.username, this.password)
        .then(() => this.onSuccess())
        .catch((err) => this.onError(err));
    }
  }
}
