<div class="note-container" *ngIf="!closed">

  <header class="note-header" *ngIf="header">
    <mat-icon color="primary">info</mat-icon>
    <strong>{{header}}</strong>
  </header>

  <a (click)="close()" *ngIf="closeable" class="small-close-button" matTooltip="Info verstecken">
    <mat-icon>close</mat-icon>
  </a>

  <section class="note">
    <mat-icon color="primary" class="info-icon-standalone" *ngIf="!header">info</mat-icon>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </section>

</div>

<button mat-icon-button *ngIf="closed" (click)="open()" [style.position]="cssInfoButtonPosition" [style.top]="cssInfoButtonTop"
        [style.right]="cssInfoButtonRight">
  <mat-icon class="info info-open-button" color="primary">{{closedIcon}}</mat-icon>
</button>
