import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KpiService {

  constructor(private httpClient: HttpClient) {
  }

  /* istanbul ignore next */
  getKpis(): Observable<Kpis> {
    const url = `${environment.apiBasePath}/kpi`;
    return this.httpClient
      .get<Kpis>(url)
      .pipe(
        catchError((error) => {
          throw error;
        }));
  }
}

export interface Kpis {
  activation: Activation;
  retention: Retention;
  revenue: Revenue;
  retentionStats: RetentionStats;
  uniqueUserLogins: UniqueUserLogin[];
}

export interface Activation {
  signUpRateLastWeek: number;
  registeredAccountsTotal: number;
  countSignUpsThisPeriod: number;
  countSignUpsLastPeriod: number;
}

export interface Retention {
  activeUser: ActiveUser;
  valueCreations: ValueCreation[];
}

export interface ActiveUser {
  countThisPeriod: number;
  countLastPeriod: number;
  rate: number;
}

export interface ValueCreation {
  countThisPeriod: number;
  countLastPeriod: number;
  rate: number;
  type: string;
}

export interface Revenue {
  customerRate: CustomerRate;
  revenueRate: RevenueRate;
}

export interface CustomerRate {
  rate: number;
  activeUserThisPeriod: number;
  payingCustomersThisPeriod: number;
  activeUserLastPeriod: number;
  payingCustomersLastPeriod: number;
}

export interface RevenueRate {
  rate: number;
  revenueThisPeriod: number;
  revenueLastPeriod: number;
}

export interface RetentionStats {
  registeredCohorts: RetentionStatsCohort[];
}

export interface RetentionStatsCohort {
  registeredInPeriod: KpiPeriod;
  registeredCount: number;
  reuseRates: RetentionStatsCohortReuse[];
}

export interface KpiPeriod {
  start: string;
  end: string;
}

export interface RetentionStatsCohortReuse {
  period: KpiPeriod;
  rate: number;
}

export interface UniqueUserLogin {
  countOfUniqueLogins: number;
  period: KpiPeriod;
}
