import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartBarComponent } from './chart-bar/chart-bar.component';
import { ChartSegmentComponent } from './chart-segment/chart-segment.component';
import { ChartVerticalBarComponent } from './chart-vertical-bar/chart-vertical-bar.component';

@NgModule({
  declarations: [
    ChartBarComponent,
    ChartSegmentComponent,
    ChartVerticalBarComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ChartBarComponent,
    ChartSegmentComponent
  ]
})
export class ChartSegmentBarModule {
}
