<app-form-container headline="Dein Account" icon="person_add">
  <article class="centered">
    <section>

      <div class="centered-content">
        <h3>Konto Informationen</h3>

        <form>

          <div class="form-input-container">
            <mat-form-field>
              <input matInput
                     required
                     id="account-name"
                     #accountName
                     maxlength="50"
                     placeholder="Benutzername"
                     [value]="user?.userId"
                     disabled>
            </mat-form-field>
          </div>

          <div class="form-input-container">
            <mat-form-field>
              <input matInput
                     type="email"
                     required
                     id="account-email"
                     #accountEmail
                     maxlength="50"
                     placeholder="E-Mail"
                     [value]="user?.info?.email"
                     disabled>
            </mat-form-field>
          </div>

          <div class="form-input-container">
            <mat-form-field>
              <input matInput
                     type="password"
                     required
                     id="user-name"
                     #accountPassword
                     placeholder="Passwort"
                     value="12345"
                     disabled>
            </mat-form-field>
          </div>

          <a class="change-password" color="primary" mat-flat-button routerLink="/changePassword">Passwort ändern</a>
          <a class="logout" color="primary" mat-flat-button routerLink="/logout">Abmelden</a>

        </form>

        <div>
          <h3>Abonnement und Vertrag</h3>


          <form *ngIf="plan; else loading">

            <mat-form-field>
              <mat-label>Abonnement</mat-label>
              <mat-select #planSelect [(value)]="plan?.planConfig.planType" disabled>
                <mat-option *ngFor="let plan of planTypes" [value]="plan">
                  {{plan}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div id="contract-end-day" class="plan-end-day" *ngIf="plan?.planConfig.planType === 'Enterprise'">
              <div class="end-day-label">Enddatum:</div>
              <div id="end-day-value" class="end-day-value">{{contractEndDate | datePipe}}</div>
            </div>

          </form>

          <ng-template #loading>Plan wird geladen ...</ng-template>

        </div>

        <div *ngIf="user && user.privatePool === true" id="private-pool"
             matTooltip="Die Daten werden nicht im emetriq© Datenpool benutzt.">
          <h3>Private Pool</h3>
          <div class="private-pool-status">
            <mat-icon>done</mat-icon>
            <span>Aktiv</span>
          </div>
        </div>


        <div *ngIf="user && hasUserDocuments()" id="documents">
          <h3>Dokumente</h3>
          <div *ngIf="hasUserJointControllerDocument()" class="form-input-container" id="joint-controller">
            <a (click)="downloadJointControllerPdf()" color="primary" mat-flat-button>Joint Controller PDF
              herunterladen</a>
          </div>
        </div>

      </div>
    </section>
  </article>

  <div class="bottom-place"></div>
</app-form-container>
