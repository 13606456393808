<mat-form-field>
  <input matInput
         placeholder="Land"
         [formControl]="control"
         [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
      {{country}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
