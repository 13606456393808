import { Injectable } from '@angular/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { CognitoUserStub } from 'app/mock/cognitouserstub';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AuthServiceMock extends AuthService {
  private isLoggedIn = true;

  override logout() {
    this.isLoggedIn = false;
  }

  isAuthenticated(): boolean {
    return this.isLoggedIn;
  }

  override isUserAuthenticated(): boolean {
    return this.isLoggedIn;
  }

  override completeNewPasswordChallenge(username: string, password: string, requiredAttributes: any = []): Promise<void | string> {
    return new Promise((resolve) => resolve());
  }

  override authenticate(username: string, password: string): Promise<void | string> {
    this.isLoggedIn = true;
    return new Promise((resolve) => resolve());
  }

  override getCognitoUser(): CognitoUser {
    return new CognitoUserStub();
  }

  override isInQSGroup(): boolean {
    return true;
  }

  override isInUserAdminGroup(): boolean {
    return true;
  }

  override getGroups(): string[] {
    return ['ADMIN', 'QS'];
  }

  override changePassword(oldPassword: string, newPassword: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (oldPassword.toLowerCase().includes('error')) {
        this.notification.sendError('Bei der Änderung des Passworts ist ein Fehler aufgetreten!');
        reject(new Error('changePassword error'));
      } else {
        this.notification.sendSuccess('Passwort wurde erfolgreich geändert!');
        resolve();
      }
    });
  }

  override checkAuthenticationStatus(): Promise<boolean> {
    return Promise.resolve(true);
  }
}
