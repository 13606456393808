import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PlanType } from '../../../user/user-administration.service';
import { ReportDisplayType } from '../../reports.interface';
import { ShoppingCartOrder, ShoppingCartService } from '../shopping-cart.service';

@Component({
  selector: 'app-shopping-cart-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class CartItemListComponent implements OnInit {

  reportType: ReportDisplayType;

  order: ShoppingCartOrder;

  planType: PlanType;

  constructor(
    private route: ActivatedRoute,
    private shoppingCartService: ShoppingCartService) {
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params: Params) => {
      this.reportType = params['reportType'];
      this.planType = params['planType'];

      if (this.reportType) {
        this.shoppingCartService.addAdditionalReport(this.reportType);
      }

      if (this.planType) {
        this.shoppingCartService.addPlanUpgrade(this.planType);
      }
    });

    this.shoppingCartService.getOrderObservable().subscribe(order => {
      this.order = order;
    });
  }

  isTracking(): boolean {
    return this.reportType === ReportDisplayType.Tracking;
  }

  isEvaluation(): boolean {
    return this.reportType === ReportDisplayType.Evaluation;
  }

  isBusinessUpgrade() {
    return this.planType === PlanType.Business;
  }
}
