import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { debounceTime, fromEvent } from 'rxjs';

@Component({
  selector: 'app-reports-list-filter',
  styleUrls: ['./filter.component.scss'],
  templateUrl: './filter.component.html'
})
export class FilterComponent implements AfterViewInit {
  @Output() changed = new EventEmitter<string>();
  @ViewChild('searchInput') something: ElementRef;

  source: any;

  filterValue = '';

  @Input() placeholder = 'Suche';

  constructor() {
  }

  ngAfterViewInit(): void {
    this.source = fromEvent(this.something.nativeElement, 'input');
    this.source.pipe(debounceTime(500)).subscribe(() => {
      this.changed.emit(this.filterValue);
    });
  }
}
