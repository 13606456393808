<app-form-container headline="Benutzer bearbeiten" icon="person_add">
  <article class="centered">
    <section>
      <div class="centered-content" *ngIf="user">
        <form [formGroup]="formGroup">
          <mat-form-field>
            <input matInput required type="text" placeholder="Plan Type" formControlName="type"
                   [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let plan of plans" [value]="plan">
                {{plan}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div formGroupName="info">
            <mat-form-field>
              <input formControlName="firstName" matInput placeholder="Vorname" required>
            </mat-form-field>
            <mat-form-field>
              <input formControlName="lastName" matInput placeholder="Nachname" required>
            </mat-form-field>
            <mat-form-field>
              <input [value]="user?.info?.email" disabled matInput placeholder="E-Mail" required="false" type="email">
            </mat-form-field>
            <mat-form-field>
              <input formControlName="telephone" matInput placeholder="Telefonnr." required>
            </mat-form-field>
          </div>
          <div formGroupName="address">
            <mat-form-field>
              <input aria-label="Company" formControlName="company" matInput placeholder="Firma" required>
            </mat-form-field>
            <mat-form-field>
              <input formControlName="street" matInput placeholder="Straße" required>
            </mat-form-field>
            <mat-form-field>
              <input formControlName="postcode" matInput placeholder="PLZ" required>
            </mat-form-field>
            <mat-form-field>
              <input formControlName="city" matInput placeholder="Stadt" required>
            </mat-form-field>
            <app-country-select id="country" [control]="country"></app-country-select>
            <mat-form-field>
              <input matInput required placeholder="Vat ID" formControlName="vatid">
            </mat-form-field>
          </div>
          <mat-form-field *ngIf="showContractEndDate">
            <input formControlName="contractEndDay" matInput placeholder="Vertrags Ende" required type="date">
          </mat-form-field>
        </form>
        <button [disabled]="!formGroup.valid || disableButton" class="submit" color="primary" mat-flat-button
                type="submit" (click)="submit()">
          Benutzer bearbeiten
        </button>
      </div>

      <div *ngIf="!user" class="centered-content">
        <mat-card>
          <mat-icon class="rotate">cached</mat-icon>
          Benutzer werden geladen...
        </mat-card>
      </div>
    </section>
  </article>
</app-form-container>
