<h1 class="ai-h1-light ai-page-header pl-4">URL Report</h1>

<form (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <article class="centered">
    <section>
      <div class="centered-content">
        <app-form-bulletpoint headline="Name des Reports" number="1">
          <div class="form-input-container">
            <mat-form-field>
              <input #label
                     [maxLength]="maxLengthName"
                     formControlName="label"
                     id="report-title"
                     matInput
                     name="label"
                     placeholder="Report"
                     required>
              <mat-error>Gibt einen Namen ein.</mat-error>
              <mat-hint align="end">{{label.value.length}} / {{maxLengthName}}</mat-hint>
            </mat-form-field>
          </div>
        </app-form-bulletpoint>

        <app-form-bulletpoint headline="Skript integrieren" number="2">
          <span *ngIf="isInUpdateMode === false"
                id="script-link">
            Bevor dieser Report Daten sammeln kann, musst du <a
            routerLink="/integration">das Skript auf deiner Webseite einbinden.</a></span>

          <app-note *ngIf="isInUpdateMode && reportForEdit?.classicDeprecated === true" header="Hinweis!"
                    id="classic-deprecated-hint">
            <span>Dieser Report basiert auf Daten, die mit einem älteren Skript verarbeitet werden.
                    Das alte Skript kann nicht mehr für neue Reporte benutzt werden.</span>
          </app-note>

          <div *ngIf="user && !user.hasClassicIntegration && !(isInUpdateMode && reportForEdit?.classicDeprecated === true)">
            <p>
              Kopiere den Script Tag in den <strong>&lt;head&gt;</strong> bzw. <strong>&lt;body&gt;</strong> Bereich
              deiner Website
            </p>

            <div class=" p-3 container-fluid ">
              <div class="row script">
                <div class="col-10">
                  <pre><code [highlight]="integrationScript()"></code></pre>
                </div>
                <div class="col-2 d-flex justify-content-end align-items-start">
                  <a (click)="copyScriptToClipboard()" mat-flat-button class="foobar">
                    <mat-icon>content_copy</mat-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </app-form-bulletpoint>

        <app-form-bulletpoint headline="URLs" number="3">
          <p>Füge ein oder mehrere URLs hinzu. Die Seitenaufrufe dieser URLs werden dann in diesem Report gesammelt.</p>
          <app-url-select [immutable]="immutableSelect()">
          </app-url-select>
        </app-form-bulletpoint>

        <ng-container [ngSwitch]="isInUpdateMode">
          <div *ngSwitchCase="false" class="form-field submit-button-field">
            <button color="primary" mat-flat-button type="submit">Report erstellen</button>
          </div>
          <div *ngSwitchCase="true" class="form-field submit-button-field">
            <button [disabled]="reportForEdit?.immutable" color="primary" mat-flat-button type="submit">
              Report aktualisieren
            </button>
          </div>
        </ng-container>
      </div>
    </section>
  </article>
</form>
