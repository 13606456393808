import { HttpClient, HttpErrorResponse, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '../../api/app.api';
import { NotificationService } from '../../notification/notification.service';
import { TrackingService } from '../../tracking/tracking.service';
import { ReportCharts, ReportChartsSnapshot } from '../reports.interface';
import { ReportsService } from '../reports.service';

@Injectable({providedIn: 'root'})
export class ChartsService {

  private reportCharts = new Subject<ReportCharts>();

  private loadingCharts = new BehaviorSubject(false);

  constructor(private httpClient: HttpClient,
              private notification: NotificationService,
              private reportsService: ReportsService) {
  }

  onReportCharts(): Observable<ReportCharts> {
    return this.reportCharts;
  }

  isLoadingCharts(): Observable<boolean> {
    return this.loadingCharts;
  }

  getCharts(reportId: any) {
    this.loadingCharts.next(true);

    const apiUrl = `${environment.apiBasePath}/charts/${reportId}`;

    this.httpClient.get(apiUrl).subscribe(
      (response: ApiResponse<ReportCharts>) => {
        this.handleChartResponse(new EventEmitter(), response);
      },
      (error: HttpErrorResponse) => {
        this.handlePollingError(new EventEmitter(), error);
      });

  }

  async getChartsWithPolling(reportId: any, start?: Date, end?: Date): Promise<void> {

    const apiUrl = `${environment.apiBasePath}/charts/${reportId}`;

    // did not call by evaluation report
    if (start && end) {
      try {
        await this.reportsService.startReport({reportId, start, end});
      } catch (error) {
        this.notification.sendError('Es ist ein Fehler aufgetreten!');
        TrackingService.track('startReportFailed', error.message);
        throw new Error(error);
      }
    }

    const stop404Polling = new EventEmitter();

    const options = this.optionWithParams(start, end);

    TrackingService.track('getChartsRequest', options);

    this.loadingCharts.next(true);

    let duration = 0;

    await timer(0, environment.asyncCharts.pollingInterval).pipe(takeUntil(stop404Polling))
      .subscribe(() => {
        duration = duration + environment.asyncCharts.pollingInterval;
        if (duration > environment.asyncCharts.maxPollingTime) {
          const timeoutError = new Error('Polling Timeout');
          this.handlePollingError(stop404Polling, timeoutError);
        }
        this.httpClient.get(apiUrl, options).subscribe(
          (response: ApiResponse<ReportCharts>) => {
            this.handleChartResponse(stop404Polling, response);
          },
          (error: HttpErrorResponse) => {
            if (error.status !== 404) {
              this.handlePollingError(stop404Polling, error);
            }
          });
      });
  }

  /**
   * Makes a post request to backend api and returns a id for the snapshot.
   *
   * @return Observable<string> with the new snapshotId.
   * @throws HttpErrorResponse as ErrorObs.
   */
  createSnapshot(reportId: string, start: Date, end: Date): Observable<string> {

    const data = {
      reportId,
      start,
      end
    };

    TrackingService.track('createChartViewSnapshot', data);

    return this.httpClient
      .post<ApiResponse<string>>(`${environment.apiBasePath}/charts/snapshot`, data)
      .pipe(
        map((r: ApiResponse<string>) => r.data),
        catchError((error: HttpErrorResponse) => {
          TrackingService.track('createChartViewSnapshotError', error);
          console.log(`Error creating snapshot with: ${JSON.stringify(data)}`, error);
          throw error;
        }));
  }

  requestSnapshot(id: string): Observable<ReportChartsSnapshot> {
    TrackingService.track('getChartViewSnapshot', {id});
    this.loadingCharts.next(true);
    return this.httpClient.get<ApiResponse<ReportChartsSnapshot>>(`${environment.apiBasePath}/charts/snapshot/${id}`)
      .pipe(
        map((r: ApiResponse<ReportChartsSnapshot>) => {
          this.loadingCharts.next(false);
          this.reportCharts.next(r.data.charts);
          return r.data;
        }),
        catchError((error: HttpErrorResponse) => {
          TrackingService.track('getChartViewSnapshotError', error);
          this.loadingCharts.next(false);
          console.log(`Error get snapshot with: ${id}`, error);
          throw error;
        }));
  }

  private handlePollingError(stopPolling, error: Error) {
    stopPolling.emit();
    this.notification.sendError('Die Daten konnten nicht abgerufen werden!');
    TrackingService.track('getChartsRequestFailed', error.message);
    this.loadingCharts.next(false);
  }

  private handleChartResponse(stopPolling, response: ApiResponse<ReportCharts>) {
    stopPolling.emit();
    this.loadingCharts.next(false);
    this.reportCharts.next(response.data);
  }

  private optionWithParams(start: Date, end: Date) {
    const options = {};

    if (start && end) {
      options['params'] = new HttpParams({
        fromObject: {
          start: start.toISOString(),
          end: end.toISOString()
        },
        encoder: new HttpUrlEncodingCodec()
      });
    }
    return options;
  }

}
