import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { BannerService } from '../../banner/banner.service';
import { PlanType } from '../../user/user-administration.service';
import { noReportsLeftNotification } from '../plan/plan.notification';
import { PlanService } from '../plan/plan.service';
import {
  EvaluationSegmentGroup,
  ReportDisplayType,
  Segment,
  SegmentEvaluation,
  SegmentsTopic
} from '../reports.interface';
import { EvaluationService } from './evaluation.service';

@Component({
  selector: 'app-report-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: [
    '../../form/form.component.scss',
    './evaluation.component.scss'
  ]
})
export class ReportsEvaluationComponent implements OnInit {

  /** The name of the report. */
  name = new FormControl('', Validators.required);

  /** available topics for selection. */
  segmentsTopics: SegmentsTopic[] = [];

  sidebarTree;

  readonly minSelectedSegments = 1;

  topicFormsByOldEnglishName = {};

  reportForEdit: SegmentEvaluation;

  evaluationGroups: EvaluationSegmentGroup[] = [{groupOperator: 'AND', segmentOperator: 'OR', segments: []}];

  reportAvailable = true;

  contentLoaded = false;

  searchTerm: string;

  isQSUser: boolean;

  @ViewChild('filterEditor') filterEditor: ElementRef;

  filterPosition = 0;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private evaluationService: EvaluationService,
              private banner: BannerService,
              private plan: PlanService,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.isQSUser = this.authService.isInQSGroup();
    this.evaluationService.getSegmentsFromApi().subscribe((topics) => {
      this.segmentsTopics = topics;

      this.checkPlanIfCreate();

      this.applyIfUpdate();

      this.contentLoaded = true;
    });
  }

  toggleSegment(eId) {
    const foundSegment = this.segmentsTopics
      .map(topic => topic.segments)
      .flat()
      .find(segment => segment.eId === eId);

    if (foundSegment) {
      foundSegment.selected = !foundSegment.selected;
    }
  }

  enterPredicate() {
    return false;
  }

  applyIfUpdate() {
    const id = this.route.snapshot.paramMap.get('id');

    if (id === null) {
      return;
    }

    this.evaluationService
      .getSegmentEvaluation(id)
      .subscribe((reportForEdit) => {

        this.reportForEdit = reportForEdit;

        this.evaluationGroups = reportForEdit.evaluationGroups || [];

        // apply name value
        this.name.patchValue(this.reportForEdit.label);
        const allSegments = this.getAllSegmentsEIdsFromGroupsFlat();

        this.segmentsTopics.forEach((topic) => {
          topic.segments.forEach((segment) => {
            segment.selected = allSegments.indexOf(segment.eId) !== -1;
          });
        });
      });
  }

  close() {
    this.router.navigateByUrl('reports?type=' + ReportDisplayType.Evaluation);
  }

  create() {
    /* needed to update error handling in child component form input */
    this.name.markAsTouched({onlySelf: true});

    if (this.isValid()) {
      const targetData: SegmentEvaluation = {
        label: String(this.name.value),
        evaluationGroups: this.evaluationGroups
      };
      this.evaluationService
        .createSegmentEvaluation(targetData)
        .subscribe(() => this.close());
    } else {
      this.notifyError();
    }
  }

  update() {
    if (this.reportForEdit === undefined || this.reportForEdit === null) {
      return;
    }
    if (!this.isValid()) {
      this.notifyError();
      return;
    }

    this.reportForEdit.label = String(this.name.value);
    this.reportForEdit.evaluationGroups = this.evaluationGroups;
    // HTTPClient returns a cold observable which is only activated when using subscribe, even if it's technically not necessary
    this.evaluationService.updateSegmentEvaluation(this.reportForEdit).subscribe();
  }

  isValid(): boolean {
    const nameValid = this.name.valid;
    const segmentsValid = this.getAllSegmentsEIdsFromGroupsFlat().length >= this.minSelectedSegments;
    const hasEmptyGroup = this.evaluationGroups.some((group) => group.segments.length === 0);
    return nameValid && segmentsValid && !hasEmptyGroup;
  }

  showButton(): boolean {
    return this.reportForEdit === undefined || this.reportForEdit === null;
  }

  checkPlanIfCreate() {
    /* istanbul ignore else */
    if (!this.reportForEdit) {
      this.plan
        .getPlanForUser()
        .subscribe((plan) => {
          if (plan.availableReports.availableEvaluationReports === 0) {
            this.reportAvailable = false;
            /* istanbul ignore next */
            this.banner.notify(noReportsLeftNotification(
              () => {
                this.router.navigateByUrl('reports/cart/items?reportType=' + ReportDisplayType.Evaluation);
              },
              () => {
                this.router.navigateByUrl('reports/cart/items?planType=' + PlanType.Business);
              })
            );
          } else {
            this.reportAvailable = true;
          }
        });
    }
  }

  addToLastGroup(segment: Segment): void {
    if (segment.selected) {
      return;
    }
    if (this.evaluationGroups.length === 0) {
      return;
    }
    const lastGroupIndex = this.evaluationGroups.length - 1;
    this.evaluationGroups[lastGroupIndex].segments.push(segment);
    segment.selected = true;
  }

  notifyError() {
    /* istanbul ignore else */
    if (!this.name.valid) {
      this.evaluationService.handleInvalidSubmission('Bitte einen Namen eingeben');
    } else {
      this.evaluationService.handleInvalidSubmission(`Auswahl Segmente mindestens: ${this.minSelectedSegments}! Keine leeren Gruppen!`);
    }
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll(): void {
    const boundingClientRect = this.filterEditor?.nativeElement.getBoundingClientRect();
    if (boundingClientRect) {
      this.filterPosition = boundingClientRect.top + boundingClientRect.height;
    }
  }

  private getAllSegmentsEIdsFromGroupsFlat(): string[] {
    return this.evaluationGroups.map((group) => group.segments)
      .flat()
      .map((segment) => segment.eId);
  }
}
