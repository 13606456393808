import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TrackingService } from '../../tracking/tracking.service';
import { UrlIntegration } from './url-select.interface';
import { UrlSelectService } from './url-select.service';
import { FormControl } from '@angular/forms';

export interface UrlPreview {
  input: string;
  generated: string;
}

@Component({
  selector: 'app-url-select',
  templateUrl: './url-select.component.html',
  styleUrls: ['./url-select.component.scss']
})
// @AutoUnsubscribe
export class UrlSelectComponent implements OnInit {
  @Input() immutable = false;

  urlControl = new FormControl('');

  urlList: Observable<UrlIntegration[]>;

  /* Displays the entered url and its www (no www) version */
  urlsPreview: UrlPreview;

  appendAdditionalUrl = true;

  subscription = new Subscription();

  constructor(private urlSelectService: UrlSelectService) {
  }

  ngOnInit() {
    this.urlList = this.urlSelectService.getUrlList();

    const subscribe = this.urlControl.valueChanges
      .subscribe((urlValue: string) => {
        this.urlInputChange(urlValue);
      });

    this.subscription.add(subscribe);
  }

  addUrl() {
    const input = this.urlsPreview?.input;
    if (input === 'https://') {
      return;
    }
    this.append(input);
    if (this.appendAdditionalUrl) {
      this.append(this.urlsPreview?.generated);
    }
    // first patch value
    this.urlControl.patchValue('');
    // then clear preview
    this.urlsPreview = undefined;
  }

  append(value: string) {
    if (value) {
      TrackingService.track('addUrl', {url: value});
      const url = this.getNormalizedUrl(value);
      this.urlSelectService.addUrl(url);
    }
  }

  getNormalizedUrl(url: string): string {
    url = url.replace(/^https?:\/\//, '');
    url = `https://${url}`;
    try {
      const urlObj = new URL(url);
      return urlObj.origin + urlObj.pathname;
    } catch (e) {
      /* istanbul ignore next */
      return url;
    }
  }

  getAddedUrlCount() {
    return this.urlSelectService.getAddedUrlCount();
  }

  getFailedUrls() {
    return this.urlSelectService.getFailedUrls();
  }

  removeUrl(index: number) {
    this.urlSelectService.removeUrlFromList(index);
  }

  urlInputChange(urlValue: string): void {
    const withoutProtocol = urlValue.replace(/^https?:\/\//, '');
    const normalizedUrl = this.getNormalizedUrl(urlValue);
    this.urlsPreview = {input: normalizedUrl, generated: ''};
    if (withoutProtocol.startsWith('www.')) {
      this.urlsPreview.generated = this.getNormalizedUrl(withoutProtocol.replace('www.', ''));
    } else {
      this.urlsPreview.generated = this.getNormalizedUrl('www.' + withoutProtocol);
    }
  }

  hasGeneratedWww() {
    return !!this.urlsPreview?.generated.startsWith('https://www.');
  }
}
