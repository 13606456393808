<div class="pl-3 pr-3">

  <table [dataSource]="dataSource" class="ai-reports-table ai-table-hover" mat-table matSort>

    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td *matCellDef="let element" [queryParams]="{type: 'evaluation'}"
          [routerLink]="[chartsUrl, element.id]" class="ai-pointer" mat-cell
      > {{element.id}} </td>
    </ng-container>

    <!-- Name Column-->
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td *matCellDef="let element" [queryParams]="{type: 'evaluation'}"
          [routerLink]="[chartsUrl, element.id]" class="ai-pointer" mat-cell
      > {{element.label}} </td>
    </ng-container>

    <!-- Created Column-->
    <ng-container matColumnDef="created">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Erstellt</th>
      <td *matCellDef="let element" [queryParams]="{type: 'evaluation'}"
          [routerLink]="[chartsUrl, element.id]" class="ai-pointer" mat-cell
      > {{element.created | datePipe}} </td>
    </ng-container>

    <!-- Characteristics Column-->
    <ng-container matColumnDef="characteristics">
      <th *matHeaderCellDef mat-header-cell>Merkmale</th>
      <td *matCellDef="let element" [queryParams]="{type: 'evaluation'}"
          [routerLink]="[chartsUrl, element.id]" class="ai-pointer" mat-cell>
        <div class="ai-dynamic-ellipsis">
          <div>
            <span *ngFor="let icon of getEvaluationIcons(element)"
                  class="mr-3 icon"
                  [class.ai-text-error]="icon.not"
                  [matTooltip]="icon.text">
              <mat-icon *ngIf="icon.icon && !icon.matIcon" [svgIcon]="'emq:' + icon.icon"></mat-icon>
              <mat-icon *ngIf="icon.matIcon && !icon.icon">{{icon.matIcon}}</mat-icon>
              <span *ngIf="icon.text && !( icon.icon || icon.matIcon)">{{icon.text}}</span>
            </span>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- Actions Column-->
    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell>Funktionen</th>
      <td *matCellDef="let element" mat-cell>
        <button
          matTooltip="Report editieren"
          class="ai-action-button"
          mat-icon-button
          *ngIf="displayEditButton()"
          name="edit-report"
          matTooltipPosition="left"
          routerLink="/reports/evaluation/edit/{{element.id}}"
          (click)="trackClick(element)">
          <mat-icon>edit</mat-icon>
        </button>

        <button
          (click)="download(element)"
          [disabled]="downloading" class="ai-action-button"
          mat-icon-button
          matTooltip="Download Report"
          matTooltipPosition="left"
          name="download-button">
          <mat-icon>cloud_download</mat-icon>
        </button>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr (click)="trackClickView(row)" *matRowDef="let row; columns: displayedColumns" mat-row></tr>
  </table>
  <mat-paginator [class.ai-display-none]="dataSource?.data.length <= pageSizeAndDisplayPaginator"
                 [pageSize]="pageSizeAndDisplayPaginator"
                 [pageSizeOptions]="[pageSizeAndDisplayPaginator, 100, 200]"
                 showFirstLastButtons></mat-paginator>

</div>
