/**
 * Generates a script tag.
 */
import { environment } from '../../../environments/environment';

export class InsightsScript {

  constructor(private id: string) {
  }

  asString(): string {
    const env = environment.production ? '' : '/dev';
    return `<script src="https://ais.insights.emetriq.de${env}?id=${this.id}"></script>`;
  }

}
