import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PlanType } from '../../user/user-administration.service';
import { ReportDisplayType, ReportType } from '../reports.interface';
import { tap } from 'rxjs/operators';

export enum ItemType {
  Upgrade = 'Upgrade', AdditionalReport = 'Report'
}

export const calculateCurrentDisplayTotalsFromQuantity = (quantity: number, priceConfig: PriceConfig): DisplayTotals => {
  const subtotal = quantity * priceConfig.priceNet;
  const tax = subtotal * priceConfig.vat / 100;
  return {
    subtotal,
    tax,
    total: subtotal + tax
  };
};

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  private static readonly INITIAL_QUANTITY = 1;

  private orderBehaviourSubject: BehaviorSubject<ShoppingCartOrder>;
  private readonly orderObservable: Observable<ShoppingCartOrder>;
  private invoiceAddress: InvoiceAddress;

  constructor(
    private httpClient: HttpClient) {
    this.orderBehaviourSubject = new BehaviorSubject<ShoppingCartOrder>(null);
    this.orderObservable = this.orderBehaviourSubject.asObservable();
  }

  submitOrder(invoiceAddress: InvoiceAddress): Promise<any> {
    const order: ShoppingCartOrder = this.orderBehaviourSubject.getValue();
    const orderRequest: OrderRequest = {
      items: [{priceId: order.priceConfig.id, quantity: order.quantity}],
      invoiceAddress
    };

    return this.httpClient.post(`${environment.apiBasePath}/order`, orderRequest).toPromise();
  }

  updateCurrentOrderQuantity(quantity: number) {
    const order = this.orderBehaviourSubject.getValue();
    order.quantity = quantity;
    order.displayTotals = calculateCurrentDisplayTotalsFromQuantity(quantity, order.priceConfig);
    this.orderBehaviourSubject.next(order);
  }

  addAdditionalReport(reportDisplayType: ReportDisplayType): Promise<any> {
    const reportType: ReportType = reportDisplayType === ReportDisplayType.Evaluation ? ReportType.SegmentEvaluation : ReportType.TrackingEvent;
    return this.getPriceForAdditionalReport(reportType).pipe(
      tap(
        (priceConfig) => {
          this.orderBehaviourSubject.next({
            type: ItemType.AdditionalReport,
            quantity: ShoppingCartService.INITIAL_QUANTITY,
            reportType,
            priceConfig,
            displayTotals: calculateCurrentDisplayTotalsFromQuantity(ShoppingCartService.INITIAL_QUANTITY, priceConfig)
          });
        })
    ).toPromise();
  }

  addPlanUpgrade(planType: PlanType) {
    return this.getPriceForPlanSubscription(planType).pipe(
      tap(
        (priceConfig) => {
          this.orderBehaviourSubject.next({
            type: ItemType.Upgrade,
            quantity: ShoppingCartService.INITIAL_QUANTITY,
            targetPlan: planType,
            priceConfig,
            displayTotals: calculateCurrentDisplayTotalsFromQuantity(ShoppingCartService.INITIAL_QUANTITY, priceConfig)
          });
        })
    ).toPromise();
  }

  updateInvoiceAddress(invoiceAddress: InvoiceAddress) {
    this.invoiceAddress = invoiceAddress;
  }

  getReportDisplayType(): ReportDisplayType {
    const order = this.orderBehaviourSubject.getValue();
    if (this.isReportOrder()) {
      return order.reportType === ReportType.SegmentEvaluation ? ReportDisplayType.Evaluation : ReportDisplayType.Tracking;
    } else {
      return null;
    }
  }

  isUpgradePlanOrder(): boolean {
    return this.orderBehaviourSubject.getValue()?.type === ItemType.Upgrade;
  }

  clearCart() {
    this.orderBehaviourSubject.next(null);
  }

  getOrderObservable(): Observable<ShoppingCartOrder> {
    return this.orderObservable;
  }

  getInvoiceAddress() {
    return this.invoiceAddress;
  }

  private isReportOrder(): boolean {
    return this.orderBehaviourSubject.getValue()?.type === ItemType.AdditionalReport;
  }

  private getPriceForPlanSubscription(planType: PlanType): Observable<PriceConfig> {
    return this.httpClient.get<PriceConfig>(`${environment.apiBasePath}/price/subscription?planType=${planType}`);
  }

  private getPriceForAdditionalReport(reportType: ReportType): Observable<PriceConfig> {
    return this.httpClient.get<PriceConfig>(`${environment.apiBasePath}/price/additionalReport?reportType=${reportType}`);
  }
}

export interface OrderItem {
  priceId: number;
  quantity: number;
}

export interface OrderRequest {
  items: OrderItem[];
  invoiceAddress: InvoiceAddress;
}

export interface ShoppingCartOrder {
  type: ItemType;
  quantity: number;
  targetPlan?: PlanType;
  reportType?: ReportType;
  priceConfig: PriceConfig;
  displayTotals?: DisplayTotals;
}

export interface InvoiceAddress {
  firstName: string;
  lastName: string;
  companyName: string;
  postcode: string;
  streetAndNumber: string;
  city: string;
  country: string;
  vatId: string;
}

export interface DisplayTotals {
  subtotal: number;
  total: number;
  tax: number;
}

export interface PriceConfig {
  id: number;
  priceNet: number;
  vat: number;
  name: string;
  description: string;
  maxQuantity: number;
  retention: Retention;
}

export enum Retention {
  Once = 'Once', Monthly = 'Monthly'
}
