import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-bulletpoint',
  templateUrl: './bulletpoint.component.html',
  styleUrls: ['./bulletpoint.component.scss']
})
export class FormBulletpointComponent implements OnInit {

  // eslint-disable-next-line id-blacklist
  @Input() number: any;
  @Input() headline: string;
  @Input() inactive: boolean;
  @Input() inactiveStr: string;
  @Input() color: string;

  classNames: string[] = [];

  ngOnInit() {
    if (this.color) {
      this.classNames.push(this.color);
    }
  }
}
