<h1 class="ai-h1-light ai-page-header pl-4">Report erstellen</h1>

<mat-tab-group class="ai-tab-group" [disableRipple]="true">
  <mat-tab>
    <ng-template mat-tab-label>
      URL Tracking&nbsp;<span *ngIf="!hasClassicIntegration()">(<strong>empfohlen</strong>)</span>
    </ng-template>

    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <h3 class="ai-h3-dark">
            Integriere das emetriq Profiling-Skript auf deiner Webseite und definieren die zu trackenden URLs.
          </h3>

          <div [ngSwitch]="hasClassicIntegration()" class="ai-text-label">
            <ul *ngSwitchCase="true" class="ai-list">
              <li>Benutze das gleiche Skript um verschiedene Unterseiten und Domains zu tracken.</li>
            </ul>
            <ul *ngSwitchCase="false" class="ai-list">
              <li><strong>Schnelle und einfache</strong> Javascript Integration.</li>
              <li>Keine technischen Fähigkeiten erforderlich.</li>
              <li>Keine <a href="https://iabeurope.eu/tcf-2-0/" target="_blank">IAB TCF</a> Integration nötig.</li>
              <li>Einmal integriert, kannst du das Skript für alle Domains und Seiten benutzen.</li>
            </ul>
          </div>

          <section [ngSwitch]="contactEmetriq()" class="buttons">
            <button id="button-create-url-report"
                    *ngSwitchCase="false"
                    [disabled]="!canCreateTrackingReports()"
                    routerLink="/reports/url-event"
                    color="primary"
                    mat-flat-button>
              Erstellen
            </button>
            <a *ngSwitchCase="true" href="mailto:sales@emetriq.com">Kontakt Emetriq</a>
          </section>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Pixel Tracking">

    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <h3 class="ai-h3-dark">
            Integriere einen unique Pixel auf deiner Webseite, Anzeigen oder Kampagne und sammel Daten.
          </h3>

          <ul class="ai-text-label ai-list">
            <li>Die Pixelintegration erfordert derzeit die <a href="https://iabeurope.eu/tcf-2-0/" target="_blank">IAB
              TCF</a>
              Consent Integration und event-based Consent-String Übergabe.
            </li>
            <li><strong>Benötigt technische Fähigkeiten und <a href="https://iabeurope.eu/tcf-2-0/" target="_blank">IAB
              TCF</a> Wissen. In unser <a
              href="https://doc.emetriq.de/#/profiling/adp/consent?id=consent-url-parameters" target="_blank">Dokumentation</a>
              kannst du weitere Informationen nachlesen.</strong></li>
            <li>Ein Report hat eine unique ID (Image-Pixel oder Javascript basierend).</li>
            <li>Jeder Report benötigt einen neuen Image-Pixel oder eine eine Javascript Integration.</li>
          </ul>

          <section class="buttons">
            <button id="button-create-tracking-pixel-report"
                    [disabled]="!canCreateTrackingReports()"
                    routerLink="/reports/pixel"
                    color="primary"
                    mat-flat-button>
              Erstellen
            </button>
          </section>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
