import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgArcModule } from '../svg-arc/svg-arc.module';
import { SvgChartComponent } from './svg-chart.component';



@NgModule({
  declarations: [SvgChartComponent],
  imports: [
    CommonModule,
    SvgArcModule
  ],
  exports: [SvgChartComponent]
})
export class SvgChartModule { }
