/**
 * A user or its identity.
 */
import { AddressDto, PersonalInfoDto, PlanType } from './user-administration.service';

export interface User {
  sids?: string[];
  privatePool?: boolean;
  address?: AddressDto;
  info?: PersonalInfoDto;
  type?: PlanType;
  contractEndDay?: Date;
  signedJointController?: Date;
  hasClassicIntegration?: boolean;
  aiId?: string;
  userId?: string;
}

export const AI_SID = '87533';
